import styled from "styled-components";

export const Container = styled.div`
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;

    h1{
        font-size: 40px;
    }
`