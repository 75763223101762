import styled from "styled-components";

export const Container = styled.div`
    .card-content{
        display: inline-block;
        width: 325px;
        height: 325px;
        border: 1px solid #80808098;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        -webkit-box-shadow: 0px 2px 10px 0px rgba(153,153,153,1);
        -moz-box-shadow: 0px 2px 10px 0px rgba(153,153,153,1);
        box-shadow: 0px 2px 10px 0px rgba(153,153,153,1);
        border: 2px solid #00000000;

        &:hover{
            transition: all ease 0.2s;
            border: 2px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }
    
    .content-header-card{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content-header-card .content-type-plan h2{
        font-size: 33px;
        color: ${props => props.theme.color.colorTextPrimary};
    }

    .content-header-card .content-type-plan span{
        font-size: 20px;
        color: ${props => props.theme.color.colorTextPrimary};
        font-weight: bold;
    }

    .details-plan{
        margin: 0.5rem 0;
        width: 100%;
        height: 3.5rem;
        overflow-y: auto;
    }

    .details-plan span{
        width: 100%;
        color: ${props => props.theme.color.colorTextPrimary};
        font-size: 12px;
        font-weight: bold;

        .old-price{
            text-decoration: line-through;
        }
    }

    .content-price-plan h1{
        text-align: center;
        font-size: 30px;
        color: ${props => props.theme.color.colorButtonsSecondary};
        border-bottom: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        padding-bottom: 0.5rem;
    }

    .content-button{
        display: flex;
        justify-content: center;

        button{
            width: 100%;
            height: 50px;
            font-size: 22px;
            background: none;
            font-weight: bold;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 2px solid ${props => props.theme.color.colorButtonsSecondary};
            border-radius: 5px;
            cursor: pointer;

            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonsSecondary};
                color: ${props => props.theme.color.backgroundPrimary};
            }
        }
    }
`