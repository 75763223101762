class Mask {
  static setMaskPhoneValue(value: string): string {
    let phone = String(value);
    phone = phone.replace(/\D/g, "");
    phone = phone.substr(0, 11);
    phone = phone.replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3");
    return phone;
  }

  static setMaskCpfAndCnpj(value: string): string {
    value = value.replace(/\D/g, "");
    value = value.substr(0, 14);
    if (value.length < 14) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      value = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  static setOnlyNumber(value: string): string {
    let number = String(value);
    number = number.replace(/\D/g, "");
    return number;
  }

  static setMaskCreditCard(numberCreditCard: string): string {
    if (numberCreditCard.length === 15) {
      numberCreditCard = numberCreditCard.replace(/\D/g, "");

      let numberCreditCardFormated = numberCreditCard.replace(
        /(\d{3})(\d{6})(\d{5})/,
        "$1 $2 $3"
      );
      return numberCreditCardFormated;
    }

    numberCreditCard = numberCreditCard.replace(/\D/g, "");

    let numberCreditCardFormated = numberCreditCard.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      "$1 $2 $3 $4"
    );

    return numberCreditCardFormated;
  }

  static setOnlyLetters(value: string): string {
    if (value.length > 1) {
      let valorFiltrado = value.replace(/[^a-zA-Z0-9]/g, "");
      return valorFiltrado;
    } else {
      let valorFiltrado = value.replace(/[^a-zA-Z]/g, "");
      return valorFiltrado;
    }
  }
}

export default Mask;
