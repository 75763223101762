import styled from "styled-components";

export const Content = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid ${props => props.theme.color.colorButtonsSecondary};
    margin-bottom: 10px;

    div:nth-child(2){
        position: relative;
    }

    div a{
        display: flex;
        align-items: center;
        text-decoration: none;
        background: none;
        color: ${props => props.theme.color.colorButtonsSecondary};
        padding: 8px 16px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        border-radius: 5px;
        cursor: pointer;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonsSecondary};
            color: ${props => props.theme.color.backgroundPrimary};
        }
    }

    div:nth-child(2){
        display: flex;
        gap: 20px;
    }

    div:nth-child(2) img{
        width: 30px;
        position: absolute;
        top: -15px;
        right: -15px;
    }
    
    .content-logo{
        cursor: pointer;
        img{
            width: 100px;
        }
    }

    .content-control-darkmode{
        display: flex;
        align-items: center;
    }

    @media(max-width: 540px){
        .content-logo .img-logo{
            width: 100px;
        }
    }
    
    @media(max-width: 425px){
        padding: 5px 15px;

        .content-logo .img-logo{
            width: 80px;
        }
    }
`