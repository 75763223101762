import { Container } from "./styles";

import { useParams, useNavigate } from "react-router-dom";
import { Button, Container as ContainerMaterial } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { UseFormsMobilityPro } from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import { useEffect, useState } from "react";
import swal from "sweetalert";
import { api } from "../../../../../Api";

import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginDriver from "../../../../../components/AppsRendered/Driver/Login";
import LoginCredencies from "../../../../../components/AppsRendered/Driver/LoginCredencies";
import RegisterDriver from "../../../../../components/AppsRendered/Driver/Register";
import LoginClient from "../../../../../components/AppsRendered/Client/Login";
import RegisterClient from "../../../../../components/AppsRendered/Client/Register";
import { PreviewDatas } from "../../Components/PreviewDatas";

import { dataURLtoFile } from "../../../../../Utils/Helpers/ConvertBase64ToFIle";

export default function ConfirmationDatas() {
  const [visiblePreviewDatas, setVisiblePreviewDatas] =
    useState<boolean>(false);

  const { state, setLoading, setUrlLogoCustomer, setUrlLogoDriver } =
    UseFormsMobilityPro();

  const { setListConfigChecked } = useListConfigAppContext();

  const [stepDriver, setStepDriver] = useState<
    "login" | "loginCredencies" | "register"
  >("login");
  const [stepRenderedDriver, setStepRenderedDriver] = useState<
    React.ReactNode | undefined
  >(undefined);

  const [stepClient, setStepClient] = useState<"login" | "register">("login");
  const [stepRenderedCustomer, setStepRenderedCustomer] = useState<
    React.ReactNode | undefined
  >(undefined);

  const { id } = useParams();
  const navigate = useNavigate();

  let cities_id: number[] = [];
  let cities_estate_id: number[] = [];

  const createCustomer = async () => {
    setLoading(true);

    try {
      state.list_select.map((value) => {
        cities_id.push(Number(value.cities_id));
        cities_estate_id.push(Number(value.cities_estate_id));
        return true;
      });

      const formData = new FormData();

      formData.append("name", state.name);
      formData.append("document", state.document);
      formData.append("email", state.email);
      formData.append("phone", state.phone);
      formData.append("whatsapp", state.whatsapp);
      formData.append("project_name", state.project_name);
      formData.append("client_app", state.customer_app);
      formData.append("client_app_main_color", state.customer_app_main_color);
      formData.append(
        "client_app_secondary_color",
        state.customer_app_secondary_color
      );
      formData.append("client_app_text_color", state.customer_app_text_color);
      formData.append(
        "client_app_logo",
        dataURLtoFile(state.customer_app_logo, `${uuidv4()}.png`)
      );
      formData.append("driver_app", state.driver_app);
      formData.append("driver_app_main_color", state.driver_app_main_color);
      formData.append(
        "driver_app_secondary_color",
        state.driver_app_secondary_color
      );
      formData.append("driver_app_text_color", state.driver_app_text_color);
      formData.append(
        "driver_app_logo",
        dataURLtoFile(state.driver_app_logo, `${uuidv4()}.png`)
      );

      const responseCreateCustomer = await api.post(
        `Mobility/createCustomer/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const responseCustomerCities = await api.post(
        `Customer/customerCities?formKey=${id}`,
        {
          cities_estate_id: cities_estate_id,
          cities_id: cities_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        responseCreateCustomer.data.status &&
        responseCustomerCities.data.status
      ) {
        setUrlLogoCustomer("");
        setUrlLogoDriver("");

        localStorage.removeItem("logoIsGenerated");
        localStorage.removeItem("forms_datas_mobilitypro");
        localStorage.removeItem("url_logo_forms_customer_mobilitypro");
        localStorage.removeItem("url_logo_forms_driver_mobilitypro");
        localStorage.removeItem("uuid_url");

        setListConfigChecked([]);
        navigate(`../configuracoes-finalizada/${id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFixDatas = () => {
    navigate(`/mobilitypro/informacoes/${id}`);
  };

  const handleSendDatas = async () => {
    swal({
      title: "Deseja mesmo confirmar os dados?",
      icon: "warning",
      buttons: ["Não", "Sim"],
    }).then((accepted) => {
      if (accepted) {
        createCustomer();
      }
    });
  };

  useEffect(() => {
    if (stepDriver === "login") {
      setStepRenderedDriver(
        <LoginDriver
          setStepApplication={setStepDriver}
          textColor={state.driver_app_text_color}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          pathLogoSelected={state.driver_app_logo}
        />
      );
    } else if (stepDriver === "loginCredencies") {
      setStepRenderedDriver(
        <LoginCredencies
          setStepApplication={setStepDriver}
          textColor={state.driver_app_text_color}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          pathLogoSelected={state.driver_app_logo}
        />
      );
    } else if (stepDriver === "register") {
      setStepRenderedDriver(
        <RegisterDriver
          setStepApplication={setStepDriver}
          textColor={state.driver_app_text_color}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          pathLogoSelected={state.driver_app_logo}
        />
      );
    }
  }, [
    stepDriver,
    state.driver_app_text_color,
    state.driver_app_secondary_color,
    state.driver_app_main_color,
    state.driver_app_logo,
  ]);

  useEffect(() => {
    if (stepClient === "login") {
      setStepRenderedCustomer(
        <LoginClient
          setStepApplication={setStepClient}
          textColor={state.customer_app_text_color}
          primaryColor={state.customer_app_main_color}
          secondaryColor={state.customer_app_secondary_color}
          pathLogoSelected={state.customer_app_logo}
        />
      );
    } else if (stepClient === "register") {
      setStepRenderedCustomer(
        <RegisterClient
          setStepApplication={setStepClient}
          textColor={state.customer_app_text_color}
          primaryColor={state.customer_app_main_color}
          secondaryColor={state.customer_app_secondary_color}
          pathLogoSelected={state.customer_app_logo}
        />
      );
    }
  }, [
    stepClient,
    state.customer_app_text_color,
    state.customer_app_secondary_color,
    state.customer_app_main_color,
    state.customer_app_logo,
  ]);

  useEffect(() => {
    if (!state.email || !state.whatsapp || !state.phone) {
      navigate(`/mobilitypro/informacoes/${id}`);
    }
  }, [state.email, state.whatsapp, state.phone, id, navigate]);

  return (
    <ContainerMaterial fixed>
      <Container>
        <h1>CONFIRME SEUS DADOS</h1>
        <div className="content-datas">
          <div className="content-simulator-devices">
            <div>
              <h3>MOTORISTA</h3>
              <SimulatorApp>{stepRenderedDriver}</SimulatorApp>
            </div>
            <div>
              <h3>CLIENTE</h3>
              <SimulatorApp>{stepRenderedCustomer}</SimulatorApp>
            </div>
          </div>
          <div className="content-config-general-app">
            <h2 className="title-config-general">
              Configurações do aplicativo
            </h2>
            <div className="content-items">
              <p>
                <span>Nome do projeto:</span> {state.name}
              </p>
              <p>
                <span>Documento:</span> {state.document}
              </p>
              <p>
                <span>Telefone:</span> {state.phone}
              </p>
              <p>
                <span>WhatsApp:</span> {state.whatsapp}
              </p>
              <p>
                <span>Email suporte:</span> {state.email}
              </p>
              <div className="content-cities-apps">
                <h3>Cidades</h3>
                {state.list_select.map((value) => {
                  return (
                    <div>
                      <p>{value.nameCity}</p>
                    </div>
                  );
                })}
              </div>
              <div className="content-informations-apps">
                <div>
                  <h3>Motorista</h3>
                  <p>
                    <span>Nome:</span> {state.driver_app}
                  </p>
                  <p>
                    <span>Cor primária:</span>{" "}
                    <input
                      type="color"
                      value={state.driver_app_main_color}
                      disabled
                    />{" "}
                  </p>
                  <p>
                    <span>Cor secundária:</span>{" "}
                    <input
                      type="color"
                      value={state.driver_app_secondary_color}
                      disabled
                    />{" "}
                  </p>
                  <p>
                    <span>Cor texto:</span>{" "}
                    <input
                      type="color"
                      value={state.driver_app_text_color}
                      disabled
                    />{" "}
                  </p>
                </div>
                <div>
                  <h3>Cliente</h3>
                  <p>
                    <span>Nome:</span> {state.customer_app}
                  </p>
                  <p>
                    <span>Cor primária:</span>{" "}
                    <input
                      type="color"
                      value={state.customer_app_main_color}
                      disabled
                    />{" "}
                  </p>
                  <p>
                    <span>Cor secundária:</span>{" "}
                    <input
                      type="color"
                      value={state.customer_app_secondary_color}
                      disabled
                    />{" "}
                  </p>
                  <p>
                    <span>Cor texto:</span>{" "}
                    <input
                      type="color"
                      value={state.customer_app_text_color}
                      disabled
                    />{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-btns-confirm-and-fix">
          <Button variant="contained" color="error" onClick={handleFixDatas}>
            CORRIGIR
          </Button>

          <Button variant="contained" color="success" onClick={handleSendDatas}>
            CONFIRMAR
          </Button>
        </div>

        <PreviewDatas
          visible={visiblePreviewDatas}
          setVisible={setVisiblePreviewDatas}
        />
      </Container>
    </ContainerMaterial>
  );
}
