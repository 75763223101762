import { TextField } from "@mui/material";

import { Container } from "./styles";

interface StepBusinessProps{
    nameBusiness: string;
    nameSlogan: string;
    setNameBusiness: (data: string) => void;
    setNameSlogan: (data: string) => void;
}

export default function StepBusiness({ nameBusiness, nameSlogan, setNameBusiness, setNameSlogan }:StepBusinessProps){
    return(
        <Container>
            <h1 id="modal-header-generate-logo">
                NOME DA SUA EMPRESA
            </h1>
            <div style={{ margin: "1rem 0" }} className='content-modal'>                    
                <TextField id="name-business" label="Nome da empresa" variant="outlined" value={nameBusiness} onChange={(event) => setNameBusiness(event.target.value)} />
                <TextField id="name-slogan" label="Slogan" variant="outlined" value={nameSlogan} onChange={(event) => setNameSlogan(event.target.value)} />
            </div>
        </Container>
    )
}