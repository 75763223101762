import { Backdrop, CircularProgress } from "@mui/material";

import { Container } from "./styles";

interface BackdropCircularLoadingProps{
    isOpen: boolean,
}

export default function BackdropCircularLoading({ isOpen }: BackdropCircularLoadingProps){

    return(
        <Container>
            <Backdrop
                open={isOpen}
                sx={{color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Container>
    )
}