import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as SelectMaterial } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { Container } from "./styles";

import { StatesProps, CitiesProps } from "../../FormsStep/FormSelectCity";

interface SelectProps {
  value: string;
  label: string;
  id: string;
  datas: (StatesProps | CitiesProps)[];
  type: "cities" | "states";
  onChange: (event: SelectChangeEvent) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Select = ({
  datas,
  id,
  label,
  onChange,
  type,
  value,
}: SelectProps) => {
  return (
    <Container>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <SelectMaterial
          labelId={id}
          id={`demo-${id}}`}
          value={value}
          label={label}
          onChange={onChange}
          MenuProps={MenuProps}
        >
          {datas.map((data) => (
            <MenuItem key={data.id} value={data.id}>
              {type === "states"
                ? (data as StatesProps).name
                : (data as CitiesProps).name}
            </MenuItem>
          ))}
        </SelectMaterial>
      </FormControl>
    </Container>
  );
};
