import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Helvetica', sans-serif;
    }

    body{
        background-color: ${props => props.theme.color.backgroundApplication};
        color: ${props => props.theme.color.colorTextPrimary};

        a{
            text-decoration: none;
            color: #000000;
        }
    }

    :root{
        --color-primary-blue: #0036BF;
        --color-primary-blue-hover: #032B98;
        --color-green: #0DC78B;
        --color-black-font: #4A4A4A;
        --color-line-card: #0046F9;
        --color-secondary-blue: #0038A4;
        --color-white-font: #F1F1F1F1;
        --color-gray-font: #454545;
        --color-red-font: #9A0000;
        --color-green-font: #00A52E;
        --color-cyan: #C9FFEE;
        --color-green-dark: #00895D;
        --color-red-price: #DF0000;
        --color-dark-mode-button: #0059B2;
    }
`