import { TextField } from "@mui/material";

interface InputCurrencyProps {
    value: string;
    setValue: (data: string) => void;
    label: string;
    id: string;
    disable?: boolean;
    fullWidth?: boolean;
}

export default function InputCurrency({ value, setValue, label, id, disable, fullWidth }: InputCurrencyProps){

    const handleFormatMoney = (value: string) => {
        if (Number.isNaN(value)) return '0,00';

		let v = value.replace(/\D/g, '');
		v = String((parseInt(v) / 100).toFixed(2));
		v = v.replace('.', ',');
		v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
		v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
		return v;
    }

    return (
        <TextField 
            fullWidth={fullWidth}
            disabled={disable}
            sx={{mb: 2}} 
            id={id} 
            label={label} 
            variant="outlined"
            value={value}
            onChange={(event) => {
                const value = event.target.value;
                setValue(handleFormatMoney(value))
            }}
        />
    )
}