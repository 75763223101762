import { Container } from "./styles";

interface StepModelBackgroundProps{
    isTypeBackground: 'normal' | 'transparent' | null;
    setIsTypeBackground: (data: 'normal' | 'transparent' | null) => void;
}

export default function StepModelBackground({ isTypeBackground, setIsTypeBackground }:StepModelBackgroundProps){

    return(
        <Container>
            <h1>TIPO DE FUNDO</h1>
            <div className="content-cards">
                <div
                    onClick={() => setIsTypeBackground('normal')}
                    style={{ border: isTypeBackground === 'normal' ? '1px solid blue' : '' }}
                >
                    <h2 style={{ fontFamily: 'Arial', color: '#000000' }}>Normal</h2>
                </div>
                <div
                    onClick={() => setIsTypeBackground('transparent')}
                    style={{ border: isTypeBackground === 'transparent' ? '1px solid blue' : '' }}
                >
                    <h2 style={{ fontFamily: 'Arial', color: '#000000' }}>Transparente</h2>
                </div>
            </div>
        </Container>
    )
}