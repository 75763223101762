import { Container } from "./styles";

import React, { Dispatch } from "react";

import CarIcons from "../../../CarIcons";

import { IconsFindedProps  } from "../..";

interface StepSelectIconsProps{
    iconsSelected: any;
    setIconsSelected: Dispatch<any>;
    iconsFinded: IconsFindedProps[];
}

export default function StepSelectIcons({ iconsSelected, setIconsSelected, iconsFinded }:StepSelectIconsProps){

    const limitChoosedIcons = 5;

    const handleChooseIcon = (dataIcon: IconsFindedProps) => {
        if (iconsSelected.length < limitChoosedIcons) {
            setIconsSelected((currentItem: IconsFindedProps[]) => [...currentItem, dataIcon]);
        } else {
            alert(`O limite é de ${iconsSelected.length} ícones`);
        }
    }

    const handleRemoveIcon = (dataIcon: IconsFindedProps) => {
        const removeIconSelected = iconsSelected.filter((data: IconsFindedProps) => data.id !== dataIcon.id);
        setIconsSelected(removeIconSelected);
    }

    return(
        <Container>
            <h1>SELECIONE 5 ÍCONES</h1>
            <div className="content-select-icons">
                {iconsFinded && iconsFinded.map((value, index) => {
                    return(
                        <React.Fragment key={index}>
                            <CarIcons
                                isSelected={iconsSelected.includes(value)}
                                onClick={() => handleChooseIcon(value)} 
                                url_icon={value.url_icon} 
                            />
                        </React.Fragment>
                    ) 
                })}
            </div>

            <div className="content-icons-selected">
                {iconsSelected.length > 0 && 
                    iconsSelected.map((value: IconsFindedProps, index: number) => {
                        return(
                            <button key={index} onClick={() => handleRemoveIcon(value)}>
                                <img src={value.url_icon} alt="teste" />
                            </button>
                        )
                    })
                }
            </div>
        </Container>
    )
}