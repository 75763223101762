import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { Container } from "./styles";

import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginClient from "../../../../../components/AppsRendered/Client/Login";
import RegisterClient from "../../../../../components/AppsRendered/Client/Register";

export default function FormColorPassenger(){
    const { state, dispatch } = useSpeedMobForm();

    const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

    const navigate = useNavigate();

    const [colorPrimaryClient, setColorPrimaryClient] =
        useState<string>("#A71814");
    const [colorSecondaryClient, setColorSecondaryClient] =
        useState<string>("#0A2B4E");
    const [colorTextClient, setColorTextClient] = useState<string>("#000000");

    const [stepClient, setStepClient] = useState<
        "login" | "register"
    >("login");
    const [stepRenderedDriver, setStepRenderedDriver] = useState<
        React.ReactNode | undefined
    >(undefined);

    const handleCheckColorsSimilars = (
        colorPrimary: string,
        colorSecondary: string
    ) => {
        const colorPrimaryFormated = colorPrimary.substring(1, 7).toUpperCase();
        const colorSecondaryFormated = colorSecondary.substring(1, 7).toUpperCase();

        // get red/green/blue int values of hex1
        let r1 = parseInt(colorPrimaryFormated.substring(0, 2), 16);
        let g1 = parseInt(colorPrimaryFormated.substring(2, 4), 16);
        let b1 = parseInt(colorPrimaryFormated.substring(4, 6), 16);
        // get red/green/blue int values of hex2
        let r2 = parseInt(colorSecondaryFormated.substring(0, 2), 16);
        let g2 = parseInt(colorSecondaryFormated.substring(2, 4), 16);
        let b2 = parseInt(colorSecondaryFormated.substring(4, 6), 16);
        // calculate differences between reds, greens and blues
        let r = 255 - Math.abs(r1 - r2);
        let g = 255 - Math.abs(g1 - g2);
        let b = 255 - Math.abs(b1 - b2);
        // limit differences between 0 and 1
        r /= 255;
        g /= 255;
        b /= 255;
        // 0 means opposite colors, 1 means same colors
        const result = (r + g + b) / 3;

        return result.toFixed(2);
    };

    const handleBackStep = () => {
        navigate('/speedmob/configuracoes-do-app/cores-aplicativo-motorista');
    }

    const handleNextStep = () => {
        const isColorSimilarPrimaryAndSecondaryClient = handleCheckColorsSimilars(colorPrimaryClient, colorSecondaryClient);
        if (Number(isColorSimilarPrimaryAndSecondaryClient) >= 0.75) {
            swal({
                title: 'Cor primária não pode ser muito semelhante a cor secundária!',
                icon: 'warning',
            });

            return;
        }

        const isColorSimilarPrimaryAndTextDriver = handleCheckColorsSimilars(colorPrimaryClient, colorTextClient);
        if (Number(isColorSimilarPrimaryAndTextDriver) >= 0.75) {
            swal({
                title: 'Cor primária não pode ser muito semelhante a cor de texto!',
                icon: 'warning'
            });

            return;
        }

        if (Number(isColorSimilarPrimaryAndSecondaryClient) >= 0.75 && Number(isColorSimilarPrimaryAndTextDriver) >= 0.75) {
            swal({
                title: 'Cores não podem ser muito semelhants!',
                icon: 'warning'
            });

            return;
        }

        dispatch({
            type: FormActions.setColorPrimaryClient,
            payload: colorPrimaryClient,
        });

        dispatch({
            type: FormActions.setColorSecondaryClient,
            payload: colorSecondaryClient,
        });

        dispatch({
            type: FormActions.setColorTextClient,
            payload: colorTextClient,
        });

        const cloneState = state;
        cloneState.colorPrimaryClient = colorPrimaryClient;
        cloneState.colorSecondaryClient = colorSecondaryClient;
        cloneState.colorTextClient = colorTextClient;

        localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

        navigate("../logo-do-app");
    };

    useEffect(() => {
        if (stepClient === "login") {
            setStepRenderedDriver(
                <LoginClient
                    setStepApplication={setStepClient}
                    textColor={colorTextClient}
                    primaryColor={colorPrimaryClient}
                    secondaryColor={colorSecondaryClient}
                />
            );
        } else if (stepClient === "register") {
            setStepRenderedDriver(
                <RegisterClient
                    setStepApplication={setStepClient}
                    textColor={colorTextClient}
                    primaryColor={colorPrimaryClient}
                    secondaryColor={colorSecondaryClient}
                />
            );
        }
    }, [stepClient, colorTextClient, colorSecondaryClient, colorPrimaryClient]);

    useEffect(() => {
        if (state.nameApp === '') {
            navigate("../../apresentacao");
        }

        if(state.colorPrimaryClient){
            setColorPrimaryClient(state.colorPrimaryClient);
        }

        if(state.colorSecondaryClient){
            setColorSecondaryClient(state.colorSecondaryClient);
        }

        if(state.colorTextClient){
            setColorTextClient(state.colorTextClient);
        }
    }, [state.nameApp, state.colorPrimaryClient, state.colorSecondaryClient, state.colorTextClient, navigate]);
     
    useEffect(() => {
        if(!listConfigChecked.includes('color-app-passenger')){
          setListConfigChecked([...listConfigChecked, 'color-app-passenger']);
        }
    }, [listConfigChecked, setListConfigChecked]);

    return(
        <ThemeConfigApp moduleTheme="speedmob" currentList="color-app-passenger" isCenter="center">
            <Container>
                <div className="content-colors">
                    <h1>Escolha as cores do seu aplicativo para passageiro:</h1>

                    <div className="content-input-colors">
                        <div>
                            <label htmlFor="primary-color-client">
                                Cor primária - motorista
                            </label>
                            <input
                                id="primary-color-client"
                                type="color"
                                defaultValue="#A71814"
                                onChange={(event) => {
                                    setColorPrimaryClient(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="secondary-color-client">
                                Cor secundária - motorista
                            </label>
                            <input
                                id="secondary-color-client"
                                type="color"
                                defaultValue="#0A2B4E"
                                onChange={(event) => {
                                        setColorSecondaryClient(event.target.value);
                                }}
                            />
                        </div>

                        <div>
                            <label htmlFor="color-text-client"> Cor texto - motorista </label>
                            <input
                                id="color-text-client"
                                type="color"
                                defaultValue="#000000"
                                onChange={(event) => {
                                    setColorTextClient(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-button-next">
                        <button className="btn-back" onClick={handleBackStep}></button>
                        <button className="btn-next" onClick={handleNextStep} ></button>
                    </div>
                </div>
                <div>
                    <div className="content-app">
                        <SimulatorApp>{stepRenderedDriver}</SimulatorApp>
                    </div>
                    
                    <div className="content-button-next-mobile">
                        <button className="btn-back" onClick={handleBackStep}></button>
                        <button className="btn-next" onClick={handleNextStep}></button>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    )
}