import { Envelope, LockSimple, Eye, EyeSlash } from "phosphor-react";

import { Container } from "./styles";
import pathImageLogoRandom from "../../../../assets/images/logo-passageiro.png";
import { FormEvent, memo, useState } from "react";

interface LoginClientProps {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  setStepApplication: (data: "login" | "register") => void;
  pathLogoSelected?: string | undefined;
}

function LoginClient({
  primaryColor,
  secondaryColor,
  textColor,
  setStepApplication,
  pathLogoSelected,
}: LoginClientProps) {
  const [visualizePassword, setVisualizePassword] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleRedirectRegister = () => {
    setStepApplication("register");
  };

  return (
    <Container style={{ backgroundColor: secondaryColor, color: textColor }}>
      <form className="content-login" onSubmit={handleSubmit}>
        <div className="content-logo">
          <img
            src={pathLogoSelected ? pathLogoSelected : pathImageLogoRandom}
            alt="logo-application"
          />
        </div>

        <div className="content-inputs">
          <div>
            <Envelope size={24} color={primaryColor} />
            <input
              style={{
                borderBottom: `2px solid ${primaryColor}`,
                color: primaryColor,
              }}
              type="email"
              placeholder="E-mail"
              required
            />
          </div>
          <div>
            <LockSimple size={24} color={primaryColor} />
            <input
              style={{
                borderBottom: `2px solid ${primaryColor}`,
                color: primaryColor,
              }}
              type={visualizePassword ? "text" : "password"}
              placeholder="Senha"
              required
            />
            {visualizePassword && (
              <button type="button" onClick={() => setVisualizePassword(false)}>
                <Eye size={14} color={primaryColor} />
              </button>
            )}
            {!visualizePassword && (
              <button type="button" onClick={() => setVisualizePassword(true)}>
                <EyeSlash size={14} color={primaryColor} />
              </button>
            )}
          </div>
          <div className="content-isdriver-forgetpassword">
            <div className="content-col-1">
              <input
                style={{ width: 10 }}
                id="is_driver"
                name="is_driver"
                type="checkbox"
              />
              <label htmlFor="is_driver" style={{ color: primaryColor }}>
                Eu sou um parceiro
              </label>
            </div>
            <div>
              <span style={{ color: primaryColor }}>Esqueceu a sua senha?</span>
            </div>
          </div>
        </div>

        <div className="content-buttons">
          <button
            type="submit"
            style={{ backgroundColor: primaryColor, color: textColor }}
          >
            ENTRAR
          </button>
          <span className="text-register">
            Ainda não possui uma conta?{" "}
            <button
              onClick={handleRedirectRegister}
              style={{ color: primaryColor }}
            >
              Cadastre-se
            </button>
          </span>
        </div>
      </form>
    </Container>
  );
}

export default memo(LoginClient);
