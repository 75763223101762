import { Container } from "./styles"

interface CarIconsProps{
    url_icon: string;
    onClick: () => void;
    isSelected: boolean;
}

export default function CarIcons({ url_icon, onClick, isSelected }: CarIconsProps){
    return(
        <Container
            disabled={isSelected}
            style={{ border: isSelected ? '1px solid blue' : '' }} 
            onClick={onClick}
        >
            <img src={url_icon} alt='logo-generated' />
        </Container>
    )
}