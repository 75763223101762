import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { debounce } from "lodash";

import { useState, useEffect } from "react";

import { Container } from "./styles";

import {
  FormActions,
  UseFormsMobilityPro,
} from "../../../../../contexts/FormsMobilityPro";

import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginClient from "../../../../../components/AppsRendered/Client/Login";
import RegisterClient from "../../../../../components/AppsRendered/Client/Register";

export default function FormColorPassenger() {
  const { state, dispatch } = UseFormsMobilityPro();

  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const [colorPrimaryClient, setColorPrimaryClient] =
    useState<string>("#A71814");
  const [colorSecondaryClient, setColorSecondaryClient] =
    useState<string>("#0A2B4E");
  const [colorTextClient, setColorTextClient] = useState<string>("#000000");

  const [stepClient, setStepClient] = useState<"login" | "register">("login");
  const [stepRenderedCustomer, setStepRenderedCustomer] = useState<
    React.ReactNode | undefined
  >(undefined);

  const handleChangeColorsInputs = debounce(
    (typeInput: "primary" | "secondary" | "text", color: string) => {
      if (typeInput === "primary") {
        setColorPrimaryClient(color);
      }

      if (typeInput === "secondary") {
        setColorSecondaryClient(color);
      }

      if (typeInput === "text") {
        setColorTextClient(color);
      }
    },
    500
  );

  const handleCheckColorsSimilars = (
    colorPrimary: string,
    colorSecondary: string
  ) => {
    const colorPrimaryFormated = colorPrimary.substring(1, 7).toUpperCase();
    const colorSecondaryFormated = colorSecondary.substring(1, 7).toUpperCase();

    // get red/green/blue int values of hex1
    let r1 = parseInt(colorPrimaryFormated.substring(0, 2), 16);
    let g1 = parseInt(colorPrimaryFormated.substring(2, 4), 16);
    let b1 = parseInt(colorPrimaryFormated.substring(4, 6), 16);
    // get red/green/blue int values of hex2
    let r2 = parseInt(colorSecondaryFormated.substring(0, 2), 16);
    let g2 = parseInt(colorSecondaryFormated.substring(2, 4), 16);
    let b2 = parseInt(colorSecondaryFormated.substring(4, 6), 16);
    // calculate differences between reds, greens and blues
    let r = 255 - Math.abs(r1 - r2);
    let g = 255 - Math.abs(g1 - g2);
    let b = 255 - Math.abs(b1 - b2);
    // limit differences between 0 and 1
    r /= 255;
    g /= 255;
    b /= 255;
    // 0 means opposite colors, 1 means same colors
    const result = (r + g + b) / 3;

    return result.toFixed(2);
  };

  const handleBackStep = () => {
    navigate(`/mobilitypro/cores-aplicativo-motorista/${id}`);
  };

  const handleNextStep = () => {
    if (state.customer_app === "") {
      swal({
        title: "Escolha o nome do seu aplicativo",
        icon: "warning",
      });

      return;
    }

    dispatch({
      type: FormActions.setCustomerAppMainColor,
      payload: colorPrimaryClient,
    });

    dispatch({
      type: FormActions.setCustomerAppSecondaryColor,
      payload: colorSecondaryClient,
    });

    dispatch({
      type: FormActions.setCustomerAppTextColor,
      payload: colorTextClient,
    });

    const isColorSimilarPrimaryAndSecondaryClient = handleCheckColorsSimilars(
      colorPrimaryClient,
      colorSecondaryClient
    );
    if (Number(isColorSimilarPrimaryAndSecondaryClient) >= 0.75) {
      swal({
        title: "Cor primária não pode ser muito semelhante a cor secundária!",
        icon: "warning",
      });

      return;
    }

    const isColorSimilarPrimaryAndTextDriver = handleCheckColorsSimilars(
      colorPrimaryClient,
      colorTextClient
    );
    if (Number(isColorSimilarPrimaryAndTextDriver) >= 0.75) {
      swal({
        title: "Cor primária não pode ser muito semelhante a cor de texto!",
        icon: "warning",
      });

      return;
    }

    if (
      Number(isColorSimilarPrimaryAndSecondaryClient) >= 0.75 &&
      Number(isColorSimilarPrimaryAndTextDriver) >= 0.75
    ) {
      swal({
        title: "Cores não podem ser muito semelhants!",
        icon: "warning",
      });

      return;
    }

    const cloneState = state;
    cloneState.customer_app_main_color = colorPrimaryClient;
    cloneState.customer_app_secondary_color = colorSecondaryClient;
    cloneState.customer_app_text_color = colorTextClient;

    localStorage.setItem("forms_datas_mobilitypro", JSON.stringify(cloneState));

    navigate(`../logo/${id}`);
  };

  useEffect(() => {
    if (stepClient === "login") {
      setStepRenderedCustomer(
        <LoginClient
          setStepApplication={setStepClient}
          textColor={colorTextClient}
          primaryColor={colorPrimaryClient}
          secondaryColor={colorSecondaryClient}
        />
      );
    } else if (stepClient === "register") {
      setStepRenderedCustomer(
        <RegisterClient
          setStepApplication={setStepClient}
          textColor={colorTextClient}
          primaryColor={colorPrimaryClient}
          secondaryColor={colorSecondaryClient}
        />
      );
    }
  }, [stepClient, colorTextClient, colorSecondaryClient, colorPrimaryClient]);

  useEffect(() => {
    if (state.customer_app_main_color) {
      setColorPrimaryClient(state.customer_app_main_color);
    }

    if (state.customer_app_secondary_color) {
      setColorSecondaryClient(state.customer_app_secondary_color);
    }

    if (state.customer_app_text_color) {
      setColorTextClient(state.customer_app_text_color);
    }
  }, [
    state.name,
    state.customer_app_main_color,
    state.customer_app_secondary_color,
    state.customer_app_text_color,
    state.customer_app,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !state.driver_app &&
      !state.driver_app_main_color &&
      !state.driver_app_secondary_color &&
      !state.driver_app_text_color
    ) {
      setListConfigChecked([]);
      navigate(`../../mobilitypro/informacoes/${id}`);
      return;
    }

    dispatch({
      type: FormActions.setCustomerApp,
      payload: `${
        JSON.parse(localStorage.getItem("forms_datas_mobilitypro") as string)
          .name
      } - Passageiro`,
    });

    if (!listConfigChecked.includes("color-app-passenger")) {
      setListConfigChecked([...listConfigChecked, "color-app-passenger"]);
    }
  }, [
    listConfigChecked,
    setListConfigChecked,
    state.driver_app,
    state.driver_app_main_color,
    state.driver_app_secondary_color,
    state.driver_app_text_color,
    state.customer_app,
    state.name,
    navigate,
    dispatch,
    id,
  ]);

  return (
    <ThemeConfigApp
      moduleTheme="mobilitypro"
      currentList="color-app-passenger"
      isCenter="center"
    >
      <Container>
        <div className="content-colors">
          <h1>Escolha as cores do seu aplicativo para passageiro:</h1>

          <div className="content-input-colors">
            <div>
              <label htmlFor="primary-color-client">Cor primária</label>
              <input
                id="primary-color-client"
                type="color"
                value={colorPrimaryClient}
                onChange={(event) => {
                  handleChangeColorsInputs("primary", event.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="secondary-color-client">Cor secundária</label>
              <input
                id="secondary-color-client"
                type="color"
                value={colorSecondaryClient}
                onChange={(event) => {
                  handleChangeColorsInputs("secondary", event.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="color-text-client"> Cor texto </label>
              <input
                id="color-text-client"
                type="color"
                value={colorTextClient}
                onChange={(event) => {
                  handleChangeColorsInputs("text", event.target.value);
                }}
              />
            </div>
          </div>
          <div>
            <TextField
              sx={{ marginBottom: 3, marginTop: 3 }}
              label="Nome de aplicativo"
              fullWidth
              value={state.customer_app}
              onChange={(event) => {
                dispatch({
                  type: FormActions.setCustomerApp,
                  payload: event.target.value,
                });
              }}
            />
          </div>
          <div className="content-button-next">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
          </div>
        </div>
        <div>
          <div className="content-app">
            <SimulatorApp>{stepRenderedCustomer}</SimulatorApp>
          </div>

          <div className="content-button-next-mobile">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
          </div>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
