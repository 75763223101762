import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMaterial } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { Container } from './styles';

import { StatesProps, CitiesProps } from '../../FormsStep/FormSelectCity';

import { useSpeedMobForm, FormActions } from '../../../../../contexts/FormsSpeedmob';

interface SelectProps {
    value: string;
    label: string;
    id: string;
    datas: StatesProps[] | CitiesProps[];
    types: 'cities' | 'estates';
}

export default function Select({ value, label, id, types, datas }: SelectProps) {

    const { dispatch, state } = useSpeedMobForm();

    const handleChange = (event: SelectChangeEvent) => {
        if(types === 'cities'){
            const id_city = event.target.value;
            
            const cityFinded = datas.filter((data: CitiesProps) => data.id === Number(id_city))

            dispatch({
                type: FormActions.setCityId,
                payload: id_city
            });

            dispatch({
                type: FormActions.setCityName,
                payload: cityFinded[0].name,
            });

            const cloneState = state;
            cloneState.cityId = id_city;
            cloneState.cityName = cityFinded[0].name;

            localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));
        }else{
            const id_state = event.target.value;
            dispatch({
                type: FormActions.setStateId,
                payload: id_state
            })

            const cloneState = state;
            cloneState.stateId = id_state;

            localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

            if(state.cityName){
                dispatch({
                    type: FormActions.setCityName,
                    payload: ''
                });

                dispatch({
                    type: FormActions.setCityId,
                    payload: ''
                });
            }
        }
    };

    return (
        <Container>
            <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel id={id}>{label}</InputLabel>
                <SelectMaterial
                    labelId={id}
                    id={`demo-${id}}`}
                    value={value}
                    label={label}
                    onChange={handleChange}
                >
                    {types === 'estates' && (
                        datas.map((value) => {
                            return <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                        })
                    )}

                    {types === 'cities' && (
                        datas.map((value:CitiesProps) => {
                            return <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                        })
                    )}
                </SelectMaterial>
            </FormControl>
        </Container>
    );
}
