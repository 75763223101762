import { useState, useEffect } from 'react';
import { Section1, Section2, Squad1, Squad2, Squad3, Squad4 } from './styles';

import { Backdrop } from '@mui/material';

interface PreloadProps{
    visible: boolean;
}

const Preload = ({ visible }: PreloadProps) => {
  const [change, setChange] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (change === 4) {
        return setChange(0);
      }
      return setChange(change + 1);
    }, 1000);
  }, [change]);

  return (
    <Backdrop
        open={visible}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 100000 }}
    >
      <Section1>
        <Squad1 change={change} />
        <Squad2 change={change} />
      </Section1>
      <Section2>
        <Squad3 change={change} />
        <Squad4 change={change}/>
      </Section2>
    </Backdrop>
  )
}

export default Preload;