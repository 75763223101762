import { Routes, Route } from "react-router-dom";

import { Container } from "./styles";

import InvalidUuid from "../Pages/InvalidUuid";
import PageNotFound from "../Pages/PageNotFound";

//SPEEDMOB
import FormInitialSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormInitial";
import FormSelectCitySpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormSelectCity";
import FormPlanCitySpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormPlanCity";
import FormSelectPlanSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormSelectPlan";
import FormApresentationConfigAppSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormApresentationConfigApp";
import FormNameAppSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormNameApp";
import FormColorDriverSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormColorDriver";
import FormColorPassengerSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormColorPassenger";
import FormLogoSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormLogo";
import FormTaxasSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormTaxas";
import FormRulesSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FormRules";
import FinalizeAppSpeedmob from "../Pages/Modules/Speedmob/FormsStep/FinalizeApp";

//MOBILITYPRO
import FormInformationsMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormInformations";
import FormColorDriverMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormColorDriver";
import FormColorPassengerMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormColorPassenger";
import FormLogoMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormLogo";
import FormSelectCityMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormSelectCity";
import FormContactMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormContact";
import ConfirmationDatas from "../Pages/Modules/MobilityPro/FormsStep/ConfirmationDatas";
import FinalizeMobilityPro from "../Pages/Modules/MobilityPro/FormsStep/FormFinalize";
import FormCompleted from "../Pages/Modules/MobilityPro/FormsStep/FormCompleted";

interface RoutesApplicationProps {
  setUuidApplication: (data: string | null) => void;
}

export default function RoutesApplication({
  setUuidApplication,
}: RoutesApplicationProps) {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<PageNotFound />} />
        <Route path="/speedmob">
          <Route path="apresentacao" element={<FormInitialSpeedmob />} />
          <Route path="selecao-cidade" element={<FormSelectCitySpeedmob />} />
          <Route path="plano-por-cidade" element={<FormPlanCitySpeedmob />} />
          <Route
            path="selecao-de-planos"
            element={<FormSelectPlanSpeedmob />}
          />
          <Route
            path="passo-a-passo-app-config"
            element={<FormApresentationConfigAppSpeedmob />}
          />
          <Route path="configuracoes-do-app">
            <Route path="nome-do-app" element={<FormNameAppSpeedmob />} />
            <Route
              path="cores-aplicativo-motorista"
              element={<FormColorDriverSpeedmob />}
            />
            <Route
              path="cores-aplicativo-passageiro"
              element={<FormColorPassengerSpeedmob />}
            />
            <Route path="logo-do-app" element={<FormLogoSpeedmob />} />
            <Route path="taxas-do-app" element={<FormTaxasSpeedmob />} />
            <Route path="regras-do-app" element={<FormRulesSpeedmob />} />
            <Route
              path="configuracao-finalizada"
              element={<FinalizeAppSpeedmob />}
            />
          </Route>
        </Route>
        <Route path="/mobilitypro">
          <Route
            path="informacoes/:id"
            element={
              <FormInformationsMobilityPro
                setUuidApplication={setUuidApplication}
              />
            }
          />
          <Route
            path="cores-aplicativo-motorista/:id"
            element={<FormColorDriverMobilityPro />}
          />
          <Route
            path="cores-aplicativo-passageiro/:id"
            element={<FormColorPassengerMobilityPro />}
          />
          <Route path="logo/:id" element={<FormLogoMobilityPro />} />
          <Route path="cidades/:id" element={<FormSelectCityMobilityPro />} />
          <Route path="contato/:id" element={<FormContactMobilityPro />} />
          <Route
            path="confirmacao_de_dados/:id"
            element={<ConfirmationDatas />}
          />
          <Route
            path="configuracoes-finalizada/:id"
            element={<FinalizeMobilityPro />}
          />
          <Route path="uuid-inválido" element={<InvalidUuid />} />
          <Route path="formulario-preenchido" element={<FormCompleted />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Container>
  );
}
