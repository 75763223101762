import styled from "styled-components";

export const Container = styled.div`
  hr {
    background-color: #000;
    width: 100%;
    height: 1px;
  }

  .content-form-name-app {
    width: 600px;
    max-width: 600px;
    flex: 0.7;
    background-color: ${(props) => props.theme.color.backgroundPrimary};
    padding: 2rem;
    margin: 2rem 0;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  }

  .content-form-config-app > h1 {
    color: ${(props) => props.theme.color.colorTextPrimary};
    font-size: 30px;
    margin-bottom: 2rem;
    text-align: center;
  }

  .content-select-colors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    label {
      font-size: 18px;
      color: ${(props) => props.theme.color.colorTextPrimary};
    }

    input {
      color: ${(props) => props.theme.color.colorTextPrimary};
    }
  }

  .content-form-name-app {
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.color.backgroundOpposite};
    }

    .css-1g7dve4-MuiFormLabel-root-MuiInputLabel-root {
      color: ${(props) => props.theme.color.backgroundOpposite};
    }

    .css-12ja8fw-MuiFormLabel-root-MuiInputLabel-root {
      color: ${(props) => props.theme.color.backgroundOpposite};
    }

    .css-1a1qzbn-MuiInputBase-root-MuiOutlinedInput-root {
      color: ${(props) => props.theme.color.backgroundOpposite};
    }

    .css-igs3ac {
      border-color: ${(props) => props.theme.color.backgroundOpposite};
    }
  }

  .text-example-monitor {
    font-size: 14px;
    color: ${(props) => props.theme.color.colorTextSecondary};
  }

  .content-button-next {
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    flex-wrap: wrap;
    gap: 20px;

    .btn-next {
      ::after {
        content: ">";
      }

      &:hover {
        transition: all ease 0.3s;
        ::after {
          content: "AVANÇAR";
        }
      }
    }

    .btn-back {
      ::after {
        content: "<";
      }

      &:hover {
        transition: all ease 0.3s;
        ::after {
          content: "VOLTAR";
        }
      }
    }

    button {
      background: linear-gradient(
        90deg,
        ${(props) => props.theme.color.colorTextPrimary} 0%,
        ${(props) => props.theme.color.colorButtonSecondaryHover} 0%,
        ${(props) => props.theme.color.colorButtonsSecondary} 25%
      );
      padding: 0.3rem 5rem;
      color: ${(props) => props.theme.color.backgroundPrimary};
      cursor: pointer;
      border: none;
      border-radius: 5px;
      font-size: 20px;

      &:hover {
        transition: all ease 0.3s;
        background-color: ${(props) =>
          props.theme.color.colorButtonSecondaryHover};
      }
    }
  }

  @media (max-width: 768px) {
    .content-form-name-app {
      width: 500px;
    }
  }

  @media (max-width: 500px) {
    .content-button-next {
      flex-direction: column-reverse;
    }

    .content-form-name-app {
      width: 100%;
    }

    .text-example-monitor {
      font-size: 12px;
    }

    .content-form-name-app .content-form-config-app {
      > h1 {
        font-size: 26px;
      }
    }
  }
`;
