import { CheckCircle, Info } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";

import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import IconHelp from "../../../../../components/IconHelp";

import { Container, Select } from "./styles";

export type ItemsListMobilityPro =
  | "informations"
  | "color-app-driver"
  | "color-app-passenger"
  | "logo"
  | "cities"
  | "contact"
  | "final";

interface ListConfigAppProps {
  currentList?: ItemsListMobilityPro;
}

export default function ListConfigApp({ currentList }: ListConfigAppProps) {
  const { listConfigChecked } = useListConfigAppContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const ItemsIsChecked = (item: ItemsListMobilityPro) => {
    return listConfigChecked.includes(item);
  };

  return (
    <Container>
      <div className="content-list">
        <Select
          onClick={() =>
            ItemsIsChecked("informations") &&
            navigate(`/mobilitypro/informacoes/${id}`)
          }
          isChecked={ItemsIsChecked("informations")}
          isSelected={currentList === "informations"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Sua Informações</h3>
          </div>

          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("informations") ? "fill" : "regular"}
              />
            }
            content="Informações importantes."
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("color-app-driver") &&
            navigate(`/mobilitypro/cores-aplicativo-motorista/${id}`)
          }
          isChecked={ItemsIsChecked("color-app-driver")}
          isSelected={currentList === "color-app-driver"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Aplicativo motorista</h3>
          </div>

          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("color-app-driver") ? "fill" : "regular"}
              />
            }
            content="Configure a cores do aplicativo de motorista."
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("color-app-passenger") &&
            navigate(`/mobilitypro/cores-aplicativo-passageiro/${id}`)
          }
          isChecked={ItemsIsChecked("color-app-passenger")}
          isSelected={currentList === "color-app-passenger"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Aplicativo passageiro</h3>
          </div>

          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={
                  ItemsIsChecked("color-app-passenger") ? "fill" : "regular"
                }
              />
            }
            content="Configure a cores do aplicativo de passageiro."
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("logo") && navigate(`/mobilitypro/logo/${id}`)
          }
          isChecked={listConfigChecked.includes("logo")}
          isSelected={currentList === "logo"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Logomarca</h3>
          </div>

          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("logo") ? "fill" : "regular"}
              />
            }
            content="Escolha a logomarca do seu aplicativo."
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("cities") && navigate(`/mobilitypro/cidades/${id}`)
          }
          isChecked={ItemsIsChecked("cities")}
          isSelected={currentList === "cities"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Cidade do seu aplicativo</h3>
          </div>

          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("cities") ? "fill" : "regular"}
              />
            }
            content="Escolha as cidades em que seu aplicativo atuará."
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("contact") && navigate(`/mobilitypro/contato/${id}`)
          }
          isChecked={ItemsIsChecked("contact")}
          isSelected={currentList === "contact"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Contato de suporte para passageiros e motoristas</h3>
          </div>
          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("contact") ? "fill" : "regular"}
              />
            }
            content="Insira seus dados de suporte para o cliente entrar em contato"
          />
        </Select>
        <Select
          onClick={() =>
            ItemsIsChecked("final") &&
            navigate(`/mobilitypro/configuracoes-finalizada/${id}`)
          }
          isChecked={ItemsIsChecked("final")}
          isSelected={currentList === "final"}
        >
          <div>
            <CheckCircle size={35} />
            <h3>Configuração finalizada</h3>
          </div>
          <IconHelp
            typeHelp="tooltip"
            icon={
              <Info
                size={32}
                weight={ItemsIsChecked("final") ? "fill" : "regular"}
              />
            }
            content="Finalize sua configurações."
          />
        </Select>
      </div>
    </Container>
  );
}
