import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { X } from "phosphor-react";

import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Container } from "./styles";
import {
  FormActions,
  UseFormsMobilityPro,
} from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";
import ThemeConfigApp from "../../../../../components/ThemeConfigApp";

import { Select } from "../../Components/Select/index";

import { api } from "../../../../../Api";
import { Button } from "@mui/material";

export interface StatesProps {
  id: number;
  name: string;
  sigla: string;
}

export interface CitiesProps {
  id: number;
  name: string;
}

interface ListCitiesProps {
  cities_id: string;
  cities_estate_id: string;
  listCities: CitiesProps[];
  nameCity: string;
}

export default function FormSelectCity() {
  const { state, dispatch, setLoading } = UseFormsMobilityPro();
  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const [states, setStates] = useState<StatesProps[]>([]);
  const [qntCitiesAvailable, setQntCitiesAvailable] = useState<string | null>(
    null
  );

  const navigate = useNavigate();
  const { id } = useParams();

  const getListCities = async (idEstate: string, index: number) => {
    setLoading(true);
    try {
      const responseCities = await api.get(
        `/Mobility/City/listCities?estateId=${idEstate}`
      );
      const updateCities: ListCitiesProps[] = [...state.list_select];
      updateCities[index]["listCities"] = responseCities.data.data;
      dispatch({
        type: FormActions.setListSelect,
        payload: updateCities,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleInputChange = async (
    event: SelectChangeEvent<string>,
    index: number,
    field: "cities_id" | "cities_estate_id"
  ) => {
    const updateCities: ListCitiesProps[] = [...state.list_select];
    updateCities[index][field] = event.target.value;
    const nameCitySelected = updateCities[index].listCities.filter(
      (data) => data.id === Number(event.target.value)
    );
    updateCities[index]["nameCity"] =
      Object.keys(nameCitySelected).length > 0 ? nameCitySelected[0].name : "";

    dispatch({
      type: FormActions.setListSelect,
      payload: updateCities,
    });

    await getListCities(updateCities[index].cities_estate_id, index);
  };

  const handleAddEstateAndCity = () => {
    if (state.list_select.length < Number(qntCitiesAvailable)) {
      const newListSelects = [
        ...state.list_select,
        {
          cities_estate_id: "",
          cities_id: "",
          listCities: [],
          nameCity: "",
        },
      ];

      dispatch({
        type: FormActions.setListSelect,
        payload: newListSelects,
      });
    } else {
      swal({
        title: "Quantidade cidades excedidas",
        icon: "warning",
      });
    }
  };

  const handleRemoveSpecific = (index: number) => {
    let newList = state.list_select;

    newList.splice(index, 1);

    dispatch({
      type: FormActions.setListSelect,
      payload: newList,
    });
  };

  const handleBackStep = () => {
    navigate(`/mobilitypro/logo/${id}`);
  };

  const handleNextStep = () => {
    /* eslint-disable */
    let shouldContinue = true;

    state.list_select.map((value) => {
      if (value.cities_estate_id === "") {
        swal({
          title: "Escolha um estado para prosseguir com o próximo passo!",
          icon: "info",
        });
        shouldContinue = false;
        return;
      }

      if (value.cities_id === "") {
        swal({
          title: "Escolha uma cidade para prosseguir com o próximo passo!",
          icon: "info",
        });
        shouldContinue = false;
        return;
      }
    });

    if (!shouldContinue) {
      return;
    }

    const cloneState = state;
    cloneState.list_select = state.list_select;

    localStorage.setItem("forms_datas_mobilitypro", JSON.stringify(cloneState));

    navigate(`../contato/${id}`);
  };

  useEffect(() => {
    const getQntCitiesAvailable = async () => {
      setLoading(true);
      try {
        const responseQntCities = await api.get(
          `Mobility/verifyCities?formKey=${id}`
        );
        setQntCitiesAvailable(responseQntCities.data.quantidade);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getQntCitiesAvailable();
  }, [id, setLoading]);

  useEffect(() => {
    const getListEstates = async () => {
      setLoading(true);
      try {
        const responseEstates = await api.get(`/Mobility/City/ListStates`);
        setStates(responseEstates.data.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getListEstates();
  }, [setLoading]);

  useEffect(() => {
    if (!state.customer_app_logo) {
      setListConfigChecked([]);
      navigate(`/mobilitypro/informacoes/${id}`);
      return;
    }

    if (!listConfigChecked.includes("cities")) {
      setListConfigChecked([...listConfigChecked, "cities"]);
    }
  }, [
    state.customer_app_logo,
    setListConfigChecked,
    listConfigChecked,
    navigate,
    id,
  ]);

  // useEffect(() => {
  //   const teste = localStorage.getItem("forms_datas_mobilitypro");

  //   console.log(JSON.parse(teste));
  // }, []);

  // useEffect(() => {
  //   if(state.list_select[0].cities_estate_id.length > 0){
  //     setListSelects(state.list_select);
  //     return;
  //   }
  //   if (listSelects.length > 0) {
  //     dispatch({
  //       type: FormActions.setListSelect,
  //       payload: listSelects,
  //     });
  //   }
  // }, [listSelects, dispatch]);

  // console.log(state);

  return (
    <ThemeConfigApp
      isCenter="center"
      moduleTheme="mobilitypro"
      currentList="cities"
    >
      <Container>
        <div className="content-select-city">
          <h1>Cidade do seu app</h1>
          <p className="message-welcome">
            Agora com multa atenção você escolherá a(s) {qntCitiesAvailable}{" "}
            cidades que seu app atuará, caso pretenda adicionar mais cidades,
            escolha a quantidade contratada e depois fale com seu consultor
            comercial para implementar as demais cidades, importante lembrar que
            as cidades não podem ser substituídas posteriormente.
          </p>

          <div className="btn-add-remove-city">
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              onClick={handleAddEstateAndCity}
            >
              Adicionar Cidade
            </Button>
          </div>

          <div className="content-selects">
            <div>
              {state.list_select.map((value, index) => {
                return (
                  <div key={index} className="content-input-citie-and-estate">
                    <div className="inputs">
                      <Select
                        value={value.cities_estate_id}
                        id="select-state"
                        label="Escolha um estado que tem interesse"
                        type="states"
                        datas={states}
                        onChange={(event: SelectChangeEvent<string>) =>
                          handleInputChange(event, index, "cities_estate_id")
                        }
                      />

                      <Select
                        value={value.cities_id}
                        id="select-city"
                        label="Escolha uma cidade que tem interesse"
                        type="cities"
                        datas={value.listCities ? value.listCities : []}
                        onChange={(event: SelectChangeEvent<string>) =>
                          handleInputChange(event, index, "cities_id")
                        }
                      />
                    </div>

                    {index > 0 ? (
                      <div className="btn-remove-inputs">
                        <button onClick={() => handleRemoveSpecific(index)}>
                          <X color="#DC143C" weight="bold" size={20} />
                        </button>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="content-button-next">
            <button className="btn-next" onClick={handleNextStep}></button>
            <button className="btn-back" onClick={handleBackStep}></button>
          </div>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
