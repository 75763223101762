import Button from "@mui/material/Button";
import { Menu as MenuMaterialUi } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  DotsThreeVertical,
  DownloadSimple,
  ArrowFatLineRight,
} from "phosphor-react";
import swal from "sweetalert";

import { useState } from "react";
import {
  useSpeedMobForm,
  FormActions as FormActionsSpeedmob,
} from "../../contexts/FormsSpeedmob";
import {
  UseFormsMobilityPro,
  FormActions as FormActionsMobilityPro,
} from "../../contexts/FormsMobilityPro";
import { Container } from "./styles";
import { dataURLtoFile } from "../../Utils/Helpers/ConvertBase64ToFIle";
import { ResizeLogo } from "../../Utils/Hooks/ResizeLogo";
import { blobToFile } from "../../Utils/Helpers/ConvertBlobToFIle";

interface MenuProps {
  onClick: (data: any) => void;
  viewModeSimulator: "client" | "driver";
  base64Image: string | undefined;
  typeModule: "mobilitypro" | "speedmob";
  nameLogo: string;
}

export default function Menu({
  onClick,
  viewModeSimulator,
  base64Image,
  typeModule,
  nameLogo,
}: MenuProps) {
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(openMenu);

  const { dispatch: dispatchSpeedMob } = useSpeedMobForm();
  const {
    dispatch: dispatchMobilityPro,
    setUrlLogoCustomer,
    setUrlLogoDriver,
    state: stateMobilityPro,
  } = UseFormsMobilityPro();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleChooseLogo = async () => {
    swal({
      title: "Atenção!",
      text: "Você pretende usar esta logo para os app de motorista e cliente?",
      icon: "info",
      buttons: ["Não", "Sim"],
    }).then((isAccept) => {
      if (isAccept) {
        const responseFile = dataURLtoFile(base64Image, `${nameLogo}-logo.png`);

        ResizeLogo(responseFile, 512, 512, "PNG", "blob")
          .then((result) => {
            if (typeModule === "mobilitypro") {
              setUrlLogoCustomer(base64Image as any);
              setUrlLogoDriver(base64Image as any);

              ResizeLogo(
                blobToFile(result as Blob, `${nameLogo}.png`),
                512,
                512,
                "PNG",
                "base64"
              )
                .then((response) => {
                  dispatchMobilityPro({
                    type: FormActionsMobilityPro.setCustomerAppLogo,
                    payload: response,
                  });
                  dispatchMobilityPro({
                    type: FormActionsMobilityPro.setDriverAppLogo,
                    payload: response,
                  });

                  const cloneState = stateMobilityPro;
                  cloneState.customer_app_logo = response;
                  cloneState.driver_app_logo = response;
                  localStorage.setItem(
                    "forms_datas_mobilitypro",
                    JSON.stringify(cloneState)
                  );

                  localStorage.setItem(
                    "url_logo_forms_customer_mobilitypro",
                    JSON.stringify(response as any)
                  );
                  localStorage.setItem(
                    "url_logo_forms_driver_mobilitypro",
                    JSON.stringify(response as any)
                  );
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (typeModule === "speedmob") {
              dispatchSpeedMob({
                type: FormActionsSpeedmob.setUrlImageLogoClient,
                payload: base64Image,
              });

              dispatchSpeedMob({
                type: FormActionsSpeedmob.setUrlImageLogoDriver,
                payload: base64Image,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const responseFile = dataURLtoFile(base64Image, `${nameLogo}-logo.png`);
        ResizeLogo(responseFile, 512, 512, "PNG", "blob")
          .then((result) => {
            if (viewModeSimulator === "client") {
              if (typeModule === "speedmob") {
                dispatchSpeedMob({
                  type: FormActionsSpeedmob.setUrlImageLogoClient,
                  payload: base64Image,
                });
              }

              if (typeModule === "mobilitypro") {
                ResizeLogo(
                  blobToFile(result as Blob, `${nameLogo}.png`),
                  512,
                  512,
                  "PNG",
                  "base64"
                )
                  .then((response) => {
                    setUrlLogoCustomer(response as any);
                    dispatchMobilityPro({
                      type: FormActionsMobilityPro.setCustomerAppLogo,
                      payload: response,
                    });

                    const cloneState = stateMobilityPro;
                    cloneState.customer_app_logo = response;
                    localStorage.setItem(
                      "forms_datas_mobilitypro",
                      JSON.stringify(cloneState)
                    );
                    localStorage.setItem(
                      "url_logo_forms_customer_mobilitypro",
                      JSON.stringify(response as any)
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              if (typeModule === "speedmob") {
                dispatchSpeedMob({
                  type: FormActionsSpeedmob.setUrlImageLogoDriver,
                  payload: base64Image,
                });
              }

              if (typeModule === "mobilitypro") {
                ResizeLogo(
                  blobToFile(result as Blob, `${nameLogo}.png`),
                  512,
                  512,
                  "PNG",
                  "base64"
                )
                  .then((response) => {
                    setUrlLogoDriver(response as any);
                    dispatchMobilityPro({
                      type: FormActionsMobilityPro.setDriverAppLogo,
                      payload: response,
                    });

                    const cloneState = stateMobilityPro;
                    cloneState.driver_app_logo = response;
                    localStorage.setItem(
                      "forms_datas_mobilitypro",
                      JSON.stringify(cloneState)
                    );
                    localStorage.setItem(
                      "url_logo_forms_driver_mobilitypro",
                      JSON.stringify(response as any)
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    setOpenMenu(null);
  };

  return (
    <Container>
      <div>
        <Button
          color="secondary"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <DotsThreeVertical size={32} />
        </Button>
        <MenuMaterialUi
          id="basic-menu"
          anchorEl={openMenu}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem title="Fazer download da logo" onClick={handleClose}>
            <DownloadSimple size={32} />
            <a href="https://google.com" onClick={onClick}>
              <span>Download Logo</span>
            </a>
          </MenuItem>
          <MenuItem title="Selecionar Logo" onClick={handleChooseLogo}>
            <ArrowFatLineRight size={32} />
            <span>Escolher Imagem</span>
          </MenuItem>
        </MenuMaterialUi>
      </div>
    </Container>
  );
}
