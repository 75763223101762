import { Container } from "./styles";

import Lottie from "lottie-react";

export default function PageNotFound() {
  return (
    <Container>
      <div className="content-emoji">
        <Lottie
          animationData={require("../../assets/animations/animation_error_404.json")}
          loop={true}
          style={{
            width: 500,
            height: 500,
          }}
        />
      </div>
    </Container>
  );
}
