import axios from "axios";
import swal from "sweetalert";
import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Container } from "./styles";
import { useEffect, useState } from "react";
import {
  FormActions,
  useSpeedMobForm,
} from "../../../../../contexts/FormsSpeedmob";
import BackdropCircularLoading from "../../../../../components/BackdropCircularLoading";
import Mask from "../../../../../Utils/masks";
import { ValidatorCnpj } from "../../../../../Utils/Hooks/ValidatorCnpj";
import { ValidatorCpf } from "../../../../../Utils/Hooks/ValidatorCpf";
interface FormUserProps {
  setShowDatasPersonal: (data: boolean) => void;
}

interface IFormsInputs {
  cpfCnpjClient: string;
  nicknameClient: string;
  phoneClient: string;
  fullNameClient: string;
  emailClient: string;
  cepClient: string;
  numberAddressClient: number;
  districtClient: string;
  stateClient: string;
  addressClient: string;
  complementClient: string;
  cityClient: string;
  genderClient: string;
}

const schema = yup.object({
  cpfCnpjClient: yup
    .string()
    .min(14, "O mínimo é 14 caracteres")
    .max(18, "O máximo é 18 caracteres")
    .required("Este campo é obrigatório"),
  nicknameClient: yup
    .string()
    .min(3, "Minímo três caracteres")
    .required("Este campo é obrigatório"),
  phoneClient: yup
    .string()
    .max(15, "O máximo é 15 caracteres")
    .required("Este campo é obrigatório"),
  fullNameClient: yup.string().required("Este campo é obrigatório"),
  emailClient: yup
    .string()
    .email("Este campo é do tipo email")
    .required("Este campo é obrigatório"),
  cepClient: yup
    .string()
    .min(8, "O mínimo é de 8 caracteres")
    .max(8, "O máximo é de 8 caracteres")
    .required("Este campo é obrigatório"),
  numberAddressClient: yup.number().required("Este campo é obrigatório"),
  districtClient: yup.string().required("Este campo é obrigatório"),
  stateClient: yup.string().required("Este campo é obrigatório"),
  addressClient: yup.string().required("Este campo é obrigatório"),
  complementClient: yup.string(),
  cityClient: yup.string().required("Este campo é obrigatório"),
  genderClient: yup.string().required("Este campo é obrigatório"),
});

export const FormUser = ({ setShowDatasPersonal }: FormUserProps) => {
  //const [listCountries, setListCountries] = useState<any>();
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IFormsInputs>({
    resolver: yupResolver(schema),
  });

  const { state, dispatch } = useSpeedMobForm();

  const handleSearchDataAddress = (event: any) => {
    const valueInput = event.target.value;
    const valueInputFormated = valueInput.replace(/(\d{5})(\d{3})/, "$1-$2");

    if (valueInput.length >= 8) {
      setLoading(true);
      axios
        .get(`https://viacep.com.br/ws/${valueInputFormated}/json/`)
        .then((response) => {
          setLoading(false);
          if (response) {
            setValue("addressClient", response.data.logradouro);
            setValue("districtClient", response.data.bairro);
            setValue("stateClient", response.data.uf);
            setValue("cityClient", response.data.localidade);
          }
        })
        .catch((error) => {
          setLoading(false);
          swal({
            title: "Erro na requisição!",
            icon: "error",
          });
          console.log(error);
        });
    }
  };

  const onSubmit = (data: IFormsInputs) => {
    console.log(errors);
    if (data.cpfCnpjClient.length > 14) {
      if (!ValidatorCnpj(data.cpfCnpjClient)) {
        swal({
          title: "CNPJ INVÁLIDO!",
          icon: "warning",
        });
        return;
      }
    } else {
      if (!ValidatorCpf(data.cpfCnpjClient)) {
        swal({
          title: "CPF INVÁLIDO!",
          icon: "warning",
        });
        return;
      }
    }

    dispatch({
      type: FormActions.setCpfCnpjClient,
      payload: data.cpfCnpjClient,
    });

    dispatch({
      type: FormActions.setNicknameClient,
      payload: data.nicknameClient,
    });

    dispatch({
      type: FormActions.setPhoneClient,
      payload: data.phoneClient,
    });

    dispatch({
      type: FormActions.setFullNameClient,
      payload: data.fullNameClient,
    });

    dispatch({
      type: FormActions.setEmailClient,
      payload: data.emailClient,
    });

    dispatch({
      type: FormActions.setGenderClient,
      payload: data.genderClient,
    });

    dispatch({
      type: FormActions.setCepClient,
      payload: data.cepClient,
    });

    dispatch({
      type: FormActions.setNumberAddressClient,
      payload: Number(data.numberAddressClient),
    });

    dispatch({
      type: FormActions.setDistrictClient,
      payload: data.districtClient,
    });

    dispatch({
      type: FormActions.setStateClient,
      payload: data.stateClient,
    });

    dispatch({
      type: FormActions.setAddressClient,
      payload: data.addressClient,
    });

    dispatch({
      type: FormActions.setComplementClient,
      payload: data.complementClient,
    });

    dispatch({
      type: FormActions.setCityClient,
      payload: data.cityClient,
    });

    const cloneState = state;
    cloneState.cpfCnpjClient = data.cpfCnpjClient;
    cloneState.nicknameClient = data.nicknameClient;
    cloneState.phoneClient = data.phoneClient;
    cloneState.fullNameClient = data.fullNameClient;
    cloneState.emailClient = data.emailClient;
    cloneState.genderClient = data.genderClient;
    cloneState.cepClient = data.cepClient;
    cloneState.numberAddressClient = Number(data.numberAddressClient);
    cloneState.districtClient = data.districtClient;
    cloneState.stateClient = data.stateClient;
    cloneState.addressClient = data.addressClient;
    cloneState.complementClient = data.complementClient;
    cloneState.cityClient = data.cityClient;
    localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

    setShowDatasPersonal(false);
  };

  // const filterRepeat = (list: any, setPlaces: any) => {
  //   const newList = list
  //     .filter((item: any) => {
  //       return item.nome.abreviado !== null;
  //     })
  //     .map((item: any) => {
  //       return {
  //         ...item,
  //         name: item.nome.abreviado,
  //       };
  //     })
  //     .filter((item: any, index: any, self: any) => {
  //       return (
  //         self.findIndex((t: any) => t.name === item.nome.abreviado) === index
  //       );
  //     });

  //   const listFilterForOrder = newList.sort((a: any, b: any) => {
  //     if (a.name < b.name) {
  //       return -1;
  //     }
  //     if (a.name > b.name) {
  //       return 1;
  //     }
  //   });
  //   const listFilterOrdenedAccents = listFilterForOrder.sort(
  //     (a: any, b: any) => {
  //       return a.name.localeCompare(b.name);
  //     }
  //   );

  //   setPlaces(listFilterOrdenedAccents);
  //   return newList;
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get("https://servicodados.ibge.gov.br/api/v1/paises")
  //     .then((response) => {
  //       setLoading(false);
  //       if (response) {
  //         filterRepeat(response.data, setListCountries);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       swal({
  //         title: "Erro na requisição!",
  //         icon: "error",
  //       });
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    if (state.cpfCnpjClient) {
      setValue("cpfCnpjClient", state.cpfCnpjClient);
    }

    if (state.nicknameClient) {
      setValue("nicknameClient", state.nicknameClient);
    }

    if (state.phoneClient) {
      setValue("phoneClient", state.phoneClient);
    }

    if (state.fullNameClient) {
      setValue("fullNameClient", state.fullNameClient);
    }

    if (state.emailClient) {
      setValue("emailClient", state.emailClient);
    }

    if (state.cepClient) {
      setValue("cepClient", state.cepClient);
    }

    if (state.numberAddressClient) {
      setValue("numberAddressClient", state.numberAddressClient);
    }

    if (state.districtClient) {
      setValue("districtClient", state.districtClient);
    }

    if (state.stateClient) {
      setValue("stateClient", state.stateClient);
    }

    if (state.addressClient) {
      setValue("addressClient", state.addressClient);
    }

    if (state.complementClient) {
      setValue("complementClient", state.complementClient);
    }

    if (state.cityId) {
      setValue("cityClient", state.cityId);
    }

    if (state.genderClient) {
      setValue("genderClient", state.genderClient);
    }
  }, [
    state.cpfCnpjClient,
    state.nicknameClient,
    state.phoneClient,
    state.fullNameClient,
    state.emailClient,
    state.cepClient,
    state.numberAddressClient,
    state.districtClient,
    state.stateClient,
    state.addressClient,
    state.complementClient,
    state.cityId,
    state.genderClient,
    setValue,
  ]);

  return (
    <>
      <BackdropCircularLoading isOpen={loading} />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="content-datas-client">
            <h2>Dados Pessoais</h2>
            <div className="content-inputs">
              <div>
                <Controller
                  render={({
                    field: { onBlur, name, ref, value = "" },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="CPF/CNPJ"
                      onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = Mask.setMaskCpfAndCnpj(value);
                        setValue(
                          "cpfCnpjClient",
                          Mask.setMaskCpfAndCnpj(value)
                        );
                      }}
                      value={value}
                      onBlur={onBlur}
                      name={name}
                      ref={ref}
                    />
                  )}
                  name="cpfCnpjClient"
                  control={control}
                />

                <Controller
                  control={control}
                  name="nicknameClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Apelido"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="phoneClient"
                  render={({
                    field: { onBlur, name, ref, value = "" },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Telefone para contato"
                      onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = Mask.setMaskPhoneValue(value);
                        setValue("phoneClient", Mask.setMaskPhoneValue(value));
                      }}
                      value={value}
                      onBlur={onBlur}
                      name={name}
                      ref={ref}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="fullNameClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Nome Completo"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="emailClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Email"
                      onChange={() => onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="genderClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth error={error ? true : false}>
                      <InputLabel id="gender-client">Gênero</InputLabel>
                      <Select
                        labelId="gender-client"
                        id="gender-client"
                        value={value}
                        label="Gênero"
                        onBlur={onBlur}
                        name={name}
                        onChange={() => onChange}
                        ref={ref}
                      >
                        <MenuItem value="masculino">Masculino</MenuItem>
                        <MenuItem value="feminino">Feminino</MenuItem>
                        <MenuItem value="outro">Outro</MenuItem>
                      </Select>
                      {error && (
                        <FormHelperText> {error.message} </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="content-datas-address">
            <h2>Endereço</h2>
            <div className="content-inputs">
              <div>
                <div>
                  <Controller
                    control={control}
                    name="cepClient"
                    render={({
                      field: { value = "", name, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        error={error ? true : false}
                        helperText={error && error.message}
                        label="CEP"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = Mask.setOnlyNumber(value);
                          setValue("cepClient", Mask.setOnlyNumber(value));
                        }}
                        onBlur={handleSearchDataAddress}
                        value={value}
                        name={name}
                        ref={ref}
                        inputProps={{ maxLength: 8 }}
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name="numberAddressClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Número"
                      type="number"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="districtClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Bairro"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="stateClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Estado"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="addressClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Rua/Logradouro"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="complementClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Complemento"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="cityClient"
                  render={({
                    field: { onChange, onBlur, value = "", name, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      error={error ? true : false}
                      helperText={error && error.message}
                      label="Cidade"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="content-button-next">
            <button className="next-button" type="submit"></button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default FormUser;
