const formatedCPF = (cpf: string) => {
    const cpfWithoutPoints = cpf.replaceAll('.', '');
    const cpfFormated = cpfWithoutPoints.replace('-', '');
    
    return cpfFormated;
}

export const ValidatorCpf = (cpf: string) => {
    //As variáveis sum e rest são declaradas e inicializadas. sum é inicializada com 0.
    let sum;
    let rest;
    sum = 0;

    //A linha const valueCpf = formatedCPF(cpf); chama uma função formatedCPF com o argumento cpf e armazena o resultado na variável valueCpf.
    const valueCpf = formatedCPF(cpf);

    //A linha if (valueCpf === "00000000000") return false; verifica se o CPF foi formatado como "00000000000". Se for o caso, a função retorna false, indicando que o CPF é inválido. Esse é um CPF com todos os dígitos iguais, o que é considerado inválido.
    if (valueCpf === "00000000000") return false;

    //Em seguida, há um loop for que itera de 1 a 9. Ele calcula a soma ponderada dos dígitos do 
    //CPF multiplicados pelos pesos correspondentes e armazena o resultado em sum. A fórmula utilizada
    //é: sum = sum + parseInt(valueCpf.substring(i-1, i)) * (11 - i). Aqui, parseInt é usado para
    //converter cada dígito do CPF de string para número inteiro.
    for (let i=1; i<=9; i++) sum = sum + parseInt(valueCpf.substring(i-1, i)) * (11 - i);

    //Após o loop, é calculado o resto da divisão da soma sum multiplicada por 10 por 11: rest = (sum * 10) % 11.
    rest = (sum * 10) % 11;
    
    if ((rest === 10) || (rest === 11))  rest = 0; //verifica se o resto calculado é igual a 10 ou 11. Se for, o valor de rest é definido como 0. Essa é uma convenção utilizada para representar os casos em que o dígito verificador seria 10 ou 11.
    if (rest !== parseInt(valueCpf.substring(9, 10)) ) return false; //verifica se o valor do dígito verificador na posição 9 do CPF é diferente do valor calculado em rest. Se forem diferentes, a função retorna false, indicando que o CPF é inválido.

    sum = 0;
    
    //É realizado outro loop for de 1 a 10 para calcular a soma ponderada dos dígitos do CPF, incluindo o segundo dígito verificador, multiplicados pelos pesos correspondentes. O resultado é armazenado em sum.
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(valueCpf.substring(i-1, i)) * (12 - i);
    
    //Novamente, é calculado o resto da divisão da soma sum multiplicada por 10 por 11: rest = (sum * 10) % 11.
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11))  rest = 0; //verifica se o resto calculado é igual a 10 ou 11 e define rest como 0, se for o caso.
    if (rest !== parseInt(valueCpf.substring(10, 11) ) ) return false; //verifica se o valor do segundo dígito verificador na posição 10 do CPF é diferente do valor calculado em rest. Se forem diferentes, a função retorna false, indicando que o CPF é inválido.
    return true;
}