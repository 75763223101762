import { CaretRight, ArrowLeft } from "phosphor-react";

import { Container } from "./styles";
import pathLogoRandom from "../../../../assets/images/logo-random.png";

interface RegisterDriverProps {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  setStepApplication: (data: "login" | "loginCredencies" | "register") => void;
  pathLogoSelected?: string | undefined;
}

export default function RegisterDriver({
  textColor,
  setStepApplication,
  pathLogoSelected,
  primaryColor,
  secondaryColor,
}: RegisterDriverProps) {
  const handleBackLogin = () => {
    setStepApplication("login");
  };

  return (
    <Container style={{ color: textColor }}>
      <div className="content-register">
        <div className="header" style={{ backgroundColor: secondaryColor }}>
          <div className="btn-back">
            <button onClick={handleBackLogin}>
              <ArrowLeft color={textColor} weight="bold" size={30} />
            </button>
          </div>

          <div className="logo">
            <img
              src={pathLogoSelected ? pathLogoSelected : pathLogoRandom}
              alt="logo"
            />
          </div>
        </div>

        <div
          style={{ backgroundColor: primaryColor }}
          className="select-category"
        >
          <span style={{ color: textColor }}>
            Escolha sua categoria de atuação
          </span>
        </div>

        <div className="content-categories">
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-taxi"
            />
            <h1>TAXISTA</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-car-private"
            />
            <h1>MOTORISTA PARTICULAR</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-car-woman"
            />
            <h1>MOTORISTA MULHER</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-car-utility"
            />
            <h1>MOTORISTA UTILITÁRIO</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-motorbike"
            />
            <h1>MOTOTAXISTA</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
          <div style={{ backgroundColor: secondaryColor }}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzADpVJNEKbbdafmw7EIzp5VAZQmNnrXQYQ&usqp=CAU"
              alt="icon-car-deliveryman"
            />
            <h1>ENTREGADOR</h1>
            <CaretRight color={textColor} weight="bold" size={30} />
          </div>
        </div>
      </div>
    </Container>
  );
}
