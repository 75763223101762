import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  border-radius: 5px;
  padding: 10px;

  > .title-modal {
    font-size: 24px;
    text-align: center;
  }

  .btn-close-modal {
    position: absolute;
    right: 0;
    margin-right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
  }

  .content-items {
    margin: 2rem 0.5rem;

    p {
      font-size: 16px;
      border-bottom: 1px solid #00000070;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      > span {
        font-weight: bold;
      }

      > input {
        width: 25px;
        height: 28px;
        border-radius: 360px;
        cursor: pointer;
        border: none;
        background: none;

        ::-webkit-color-swatch {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border: 1px solid #000000;
        }
      }
    }

    .content-logo {
      display: flex;
      justify-content: center;
      gap: 2rem;

      img {
        width: 280px;
        height: 280px;
        border: 2px solid #000000;
        border-radius: 5px;
      }

      > div {
        h3 {
          text-align: center;
          font-size: 24;
        }
      }
    }
  }

  .content-informations-apps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-top: 2rem;

    > div {
      width: 100%;
      h1 {
        font-size: 22px;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }

  @media (max-width: 790px) {
    width: 85%;

    .content-logo {
      flex-direction: column;
      align-items: center;
    }

    .content-informations-apps {
      flex-direction: column;
    }
  }

  @media (max-width: 560px) {
    width: 100%;

    > h1 {
      font-size: 23px;
    }

    .content-items {
      margin: 2rem 0.5rem;

      > p {
        font-size: 14px;
      }
    }
  }
`;
