import { BrowserRouter } from "react-router-dom";
import { DefaultTheme, ThemeProvider } from "styled-components";
import {
  createTheme,
  ThemeProvider as ThemeProviderMaterial,
} from "@mui/material";
import { ptBR } from "@mui/x-date-pickers/locales";

import light from "./theme/light";
import dark from "./theme/dark";

import RoutesApplication from "./Routes/RoutesApplication";
import { GlobalStyle } from "./Global/GlobalStyle";
import HeaderSpeedmob from "./Pages/Modules/Speedmob/Components/Header";
import HeaderMobilityPro from "./Pages/Modules/MobilityPro/Components/Header";
import { useEffect, useState } from "react";

import {
  State,
  UseFormsMobilityPro,
  FormActions,
} from "./contexts/FormsMobilityPro";
import Preload from "./components/Preload";

export default function App() {
  const [toggleButton, setToggleButton] = useState(false);
  const [typeApplication, setTypeApplication] = useState<
    "speedmob" | "mobilitypro"
  >("speedmob");
  const [uuidApplication, setUuidApplication] = useState<string | null>(null);

  const { loading, dispatch, setUrlLogoCustomer, setUrlLogoDriver } =
    UseFormsMobilityPro();

  const themeMaterial = createTheme(
    {
      palette: {
        primary: {
          main:
            toggleButton === false
              ? light.color.colorButtonsSecondary
              : dark.color.colorButtonsSecondary,
        },
      },
    },
    ptBR
  );

  useEffect(() => {
    const SwitchTypeApplication = () => {
      const urlApplication = window.location.href;

      if (urlApplication.includes("speedmob")) {
        setTypeApplication("speedmob");
      }

      if (urlApplication.includes("mobilitypro")) {
        setTypeApplication("mobilitypro");
      }
    };

    SwitchTypeApplication();
  }, []);

  useEffect(() => {
    if (typeApplication === "mobilitypro") {
      document.title = "MobilityPro - Crie seu aplicativo";
    } else if (typeApplication === "speedmob") {
      document.title = "Speedmob - Crie seu aplicativo";
    }
  }, [typeApplication]);

  useEffect(() => {
    if (typeApplication === "mobilitypro") {
      const datasSaved = localStorage.getItem("forms_datas_mobilitypro");
      const url_logo_customer = localStorage.getItem(
        "url_logo_forms_customer_mobilitypro"
      );
      const url_logo_driver = localStorage.getItem(
        "url_logo_forms_driver_mobilitypro"
      );

      setUrlLogoCustomer(JSON.parse(url_logo_customer as any));
      setUrlLogoDriver(JSON.parse(url_logo_driver as any));

      if (datasSaved) {
        const datasSavedConverted = JSON.parse(datasSaved) as State;
        dispatch({
          type: FormActions.setResetValue,
          payload: datasSavedConverted,
        });
      }
    } else if (typeApplication === "speedmob") {
      const datasSaved = localStorage.getItem("forms_datas_speedmob");

      if (datasSaved) {
        const datasSavedConverted = JSON.parse(datasSaved) as State;
        dispatch({
          type: FormActions.setResetValue,
          payload: datasSavedConverted,
        });
      }
    }
  }, [dispatch, setUrlLogoCustomer, setUrlLogoDriver, typeApplication]);

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode");
    if (isDarkMode === "true") {
      setToggleButton(true);
    } else {
      setToggleButton(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Preload visible={loading} />
      <ThemeProvider
        theme={
          toggleButton === false
            ? (light as DefaultTheme)
            : (dark as DefaultTheme)
        }
      >
        <ThemeProviderMaterial theme={themeMaterial}>
          <GlobalStyle />
          {typeApplication === "speedmob" && (
            <HeaderSpeedmob
              setToggleDarkMode={setToggleButton}
              toggleDarkMode={toggleButton}
            />
          )}

          {typeApplication === "mobilitypro" && (
            <HeaderMobilityPro
              id={uuidApplication}
              setToggleDarkMode={setToggleButton}
              toggleDarkMode={toggleButton}
            />
          )}
          <RoutesApplication setUuidApplication={setUuidApplication} />
        </ThemeProviderMaterial>
      </ThemeProvider>
    </BrowserRouter>
  );
}
