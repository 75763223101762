import { useNavigate } from "react-router-dom";
import { AiFillCreditCard } from 'react-icons/ai';

import { useEffect } from "react";
import { Container } from "./styles";
import { useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";

export default function FormApresentationConfigApp() {

    const { state } = useSpeedMobForm();

    const navigate = useNavigate();

    const handleNextStep = () => {
        navigate('../configuracoes-do-app/nome-do-app');
    }

    useEffect(() => {
        const isCreditCard = state.addresCharge === null && state.numberCard === null && state.typeCard === undefined && state.dueDateCard === null;
        
        if(isCreditCard){
            navigate('../apresentacao')
        }
        
    }, [state.addresCharge, state.numberCard, state.typeCard, state.dueDateCard, navigate]);

    return (
        <Container>
            <div className="content">
                <div>
                    <div className="icon-card">
                        <AiFillCreditCard size={100} />
                    </div>
                    <div className="title-page">
                        <h1>Quase lá!</h1>
                        <h1>Seu pagamento está sendo analisado.</h1>
                    </div>
                    <div className="content-texts">
                        <p>
                            Olá! Recebemos seu pedido e seu pagamento está sendo analisado. Enviaremos um e-mail
                            confirmando ou não seu pagamento em até 1 hora.
                        </p>

                        <p>
                            Enquanto aguarda a confirmação do seu pagamento você pode está configurando o seu aplicativo.
                            Você precisa de alguns dados em mãos para configurar o seu aplicativo.
                        </p>
                    </div>

                    <ul className="content-list">
                        <li>Nome do aplicativo</li>
                        <li>Cores do aplicativo (motorista e passageiro)</li>
                        <li>Logo do aplicativo</li>
                        <li>Tarifas do aplicativo</li>
                        <li>Regras do aplicativo</li>
                    </ul>
                    <div className="content-button-next">
                        <button className="btn-next" onClick={handleNextStep}>
                            INICIAR CONFIGURAÇÃO DO APLICATIVO
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}