import styled from "styled-components";

interface ValueDiscountTypeProps{
    backgroundColor: string;
    color: string;
}

export const Container = styled.div<ValueDiscountTypeProps>`
    .content-discount-value{
        font-weight: bold;
        border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        color: ${props => props.color === 'normal' ? props.theme.color.backgroundPrimary : props.theme.color.colorButtonsSecondary};
        background: ${props => props.color === 'normal' ? props.theme.color.colorButtonsSecondary : 'none'};
        padding: 6px 12px;
        border-radius: 15px;
    }

`