import styled from "styled-components";

interface SelectProps{
    isSelected: boolean;
    isChecked: boolean;
}

export const Container = styled.div`
    width: 100%;
`

export const Select = styled.div<SelectProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.isSelected ? `linear-gradient(90deg, ${props.theme.color.colorTextPrimary} 0%, ${props.theme.color.colorButtonSecondaryHover} 0%, ${props.theme.color.colorButtonsSecondary} 25%)` : props.theme.color.colorTextOpposite};
    color: ${props => props.isSelected ? props => props.theme.color.backgroundPrimary : props.theme.color.colorButtonsSecondary};
    border: 1px solid #80808077;
    padding: 0.8rem;
    cursor: pointer;
    opacity: ${props => props.isSelected || props.isChecked ? 1 : 0.5};

    > div{
        display: flex;
        align-items: center;
    }

    h3{
        font-weight: bold;
        font-size: 18px;
        margin-left: 1rem;
    }
`