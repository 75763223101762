import styled from "styled-components";

export const Container = styled.div`
    hr{
        background-color: #000;
        width: 100%;
        height: 1px;
    }

    .content-form-name-app{
        margin: 2rem 0;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 70px;
    }

    .content-form-config-app > h1{
        color: ${props => props.theme.color.colorTextPrimary};
        font-size: 33px;
        margin-bottom: 2rem;
        text-align: center;
    }

    .content-select-colors{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 10px;

        label {
            font-size: 18px;
            color: ${props => props.theme.color.colorTextPrimary};
        }   

        input   {
            color: ${props => props.theme.color.colorTextPrimary};
        }
    }

    .content-form-name-app .content-application .content-group-button{
        display: flex;
        justify-content: center;
    }

    .content-form-name-app .content-application .content-group-button{

        .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
            color: ${props => props.theme.color.colorButtonsSecondary} !important;
        }

        .css-d9c359-MuiButtonBase-root-MuiToggleButton-root{
            color: ${props => props.theme.color.colorTextPrimary};
        }

        .css-1gjgmky-MuiToggleButtonGroup-root{
            border: 1px solid ${props => props.theme.color.backgroundOpposite} !important;
        }
    }

    .content-button-next{
        display: flex;
        justify-content: center;
        margin: 4rem 0;
        flex-wrap: wrap;
        gap: 20px;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    @media(max-width: 500px){
        .content-button-next{
            flex-direction: column-reverse;
            
        }
    }

    @media(max-width: 425px){
        .content-form-name-app{
            padding: 0.5rem;
            gap: 20px;
        }

        .content-application iframe{
            border: 1px solid red;
            width: 320px;
            height: 400px;
        }
    }
`