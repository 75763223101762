import styled from "styled-components";

export const Container = styled.div`
    .content-modal{
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    
    @media(max-width: 525px){
        .content-modal{
            display: flex;
            flex-direction: column;
        }
    }
`