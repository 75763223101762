const light = {
    title: 'light',

    color: {
        backgroundPrimary: '#FFFFFFFF',
        backgroundOpposite: '#202124',
        colorTextPrimary: '#000000',
        colorTextOpposite: '#E2E2E2',
        colorTitle: '#0038A4',
        colorTextSecondary: '#454545',
        colorButtonsPrimary: '#414BA9',
        colorButtonsSecondary: '#FF8004',
        colorButtonSecondaryHover: '#C46302',
        backgroundApplication: '#F4F4F6'
    }
}

export default light;