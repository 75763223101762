import styled from "styled-components";

export const Container = styled.div<{isCenter: 'center' | 'normal'}>`
    width: 100%;
    display: flex;
    align-items: ${props => props.isCenter === 'center' ? 'center' : 'initial'};
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    .content-list{
        width: 500px;
    }

    animation-name: smooth;
    animation-duration: 1s;
    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @media(max-width: 500px){
        padding: 1rem;
        
        .content-list{
            width: 100%;
        }
    }
`