import { ReactNode } from "react"
import { Container } from "./styles"

interface IconListProps{
    children: ReactNode;
    width: number;
    height: number;
}

export default function IconList({ children, height, width }: IconListProps){
    return(
        <Container
            width={width}
            height={height}
        >
            {children}
        </Container>
    )
}