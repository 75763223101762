import styled from "styled-components";

export const Container = styled.div`
    .content-finalize-app{
        width: 500px;
    }

    .content-finalize-app .content-title{
        color: ${props => props.theme.color.colorButtonsSecondary};
        font-size: 18px;
        margin-bottom: 1rem;
    }

    .content-description p{
        font-size: 20px;
        margin-bottom: 1rem;
    }

    .content-finalize-app .content-footer{
        display: flex;
        align-items: center;
        gap: 2rem;

        > div button{
            font-size: 20px;
            font-weight: bold;
            width: 275px;
            height: 40px;
            background-color: ${props => props.theme.color.colorButtonsSecondary};
            color: ${props => props.theme.color.backgroundPrimary};
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:hover{
                transition: all ease 0.2s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }
    }

    .content-finalize-app .content-footer .midias-socials{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    @media(max-width: 600px){
        .content-finalize-app{
            width: 100%;
            padding: 1rem;
            text-align: center;
        }

        .content-finalize-app .content-title{
            font-size: 16px;
        }

        .content-description p{
            font-size: 16px;
        }

        .content-finalize-app .content-footer{
            flex-direction: column;
            margin: 2rem 0;
            gap: 1rem;
            
            > div button{
                font-size: 16px;
                width: 240px;
                height: 40px;
            }
        }
    }

    @media(max-width: 425px){
        .content-finalize-app .content-title{
            font-size: 14px;
        }

        .content-description p{
            font-size: 14px;
        }

        .content-finalize-app .content-footer{
            flex-direction: column;
            margin: 2rem 0;
            gap: 1rem;
            
            > div button{
                font-size: 14px;
                width: 240px;
                height: 40px;
            }
        }
    }
`