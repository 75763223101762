import styled from "styled-components";

export const Container = styled.div`
  width: 215px;
  height: 385px;
  position: absolute;
  top: 14%;
  left: 31px;

  .content-register .header {
    background-color: blue;
    width: 100%;
    height: 50px;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .content-register .btn-back button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .content-register .title-page {
    flex: 1;
    text-align: center;
  }

  .content-register form {
    overflow-y: auto;
    padding: 0.5rem;
    height: 335px;

    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px !important;
    }

    ::-webkit-scrollbar-track {
      background: #eeeeee;
    }

    ::-webkit-scrollbar-thumb {
      background: #bdbdbd;
    }
  }

  .content-register form div {
    margin: 0.5rem 0;
  }

  .content-register form label {
    font-size: 12px;
  }

  .content-register form input {
    padding: 0.5rem;
    background-color: #e2e2e2;
    border: none;
    border-radius: 5px;
    outline: none;
  }

  .content-register form .field-full label {
    display: block;
    text-align: left;
    margin-bottom: 10px;
  }

  .content-register form .field-full input {
    width: 100%;
  }

  .content-register form .two-fields {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
  }

  .content-register form .two-fields input {
    width: 110px;
  }

  .content-register form select {
    width: 100%;
    padding: 0.4rem 0.6rem;
    background-color: #e2e2e2;
    border-radius: 5px;
    outline: none;
  }

  .content-register form .field-full p {
    font-size: 9px;
    line-height: 10px;
  }

  .content-register form .btn-submit {
    text-align: center;
    margin: 1rem 0;
  }

  .content-register form .btn-submit button {
    width: 100%;
    padding: 0.8rem 2rem;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background-color: blue;
    border-radius: 5px;
    color: #ffffff;
  }
`;
