import { ReactNode, createContext, useState, useContext } from "react";

interface ListConfigAppContextProviderProps{
    children: ReactNode;
}

interface ListConfigAppContextProps{
    listConfigChecked: string[];
    setListConfigChecked: (data: string[]) => void;
}

export const ListConfigAppContext = createContext<ListConfigAppContextProps | undefined>(undefined);

export const ListConfigAppContextProvider = ({ children }: ListConfigAppContextProviderProps) => {

    const [listConfigChecked, setListConfigChecked] = useState<string[]>([]);

    return(
        <ListConfigAppContext.Provider value={{listConfigChecked, setListConfigChecked}}>
            {children}
        </ListConfigAppContext.Provider>
    )
};

export const useListConfigAppContext = () => {
    const context = useContext(ListConfigAppContext);

    if(context === undefined){
        throw new Error('useListConfigAppContext precisa ser passado dentro do provider');
    }

    return context;
}