import { Switch } from "@mui/material";
import { SunDim, Moon, WhatsappLogo } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import { Content } from "./styles";
import pathLogo from '../../../../../assets/images/logo_default.png';
import { ChangeEvent } from "react";

interface HeaderProps {
    setToggleDarkMode: (data: boolean) => void;
    toggleDarkMode: boolean;
}

export default function Header({ toggleDarkMode, setToggleDarkMode }: HeaderProps) {

    const navigate = useNavigate();

    const handleChangeDarkMode = (event: ChangeEvent<HTMLInputElement>) => {
        setToggleDarkMode(event.target.checked);
        localStorage.setItem('darkMode', JSON.stringify(event.target.checked));
    }

    const handleRedirectHome = () => {
        navigate('speedmob/apresentacao');
    }

    return (
        <Content>
            <div className="content-logo" onClick={handleRedirectHome}>
                <img className="img-logo" src={pathLogo} alt="logo" />
            </div>
            <div>
                <div className="content-control-darkmode">
                    <Switch
                        checked={toggleDarkMode}
                        onChange={handleChangeDarkMode}
                    />
                    {toggleDarkMode ? <Moon size={30} color="#0D14CA" weight="bold" /> : <SunDim size={30} color="#888d05" weight="bold" />}
                </div>
                
                <div className="content-button-call">
                    <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/${558598193813}`}>
                        <WhatsappLogo size={20} style={{ marginRight: 4 }} />
                        CONSULTOR
                    </a>
                </div>
            </div>
        </Content>
    )
}