import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FormsSpeedContextProvider } from "./contexts/FormsSpeedmob";
import { FormsMobilityProContextProvider } from "./contexts/FormsMobilityPro";
import { ListConfigAppContextProvider } from "./contexts/ListConfigApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <FormsSpeedContextProvider>
      <FormsMobilityProContextProvider>
        <ListConfigAppContextProvider>
          <App />
        </ListConfigAppContextProvider>
      </FormsMobilityProContextProvider>
    </FormsSpeedContextProvider>
  </React.StrictMode>
);

reportWebVitals();
