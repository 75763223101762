import { Container } from "./styles";

import Lottie from "lottie-react";

export default function InvalidUuid() {
  return (
    <Container>
      <Lottie
        animationData={require("../../assets/animations/robot_invalid_uuid.json")}
        loop={true}
        style={{
          width: 500,
          height: 500,
        }}
      />
      <h1>ID do formulário é inválido</h1>
    </Container>
  );
}
