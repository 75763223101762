import { Button, Modal as ModalMaterialUI } from '@mui/material';
import swal from 'sweetalert';

import React, { ReactNode, useEffect, useState } from 'react';
import { Container } from './styles';

import StepBusiness from './Steps/StepBusiness';
import StepSelectIcons from './Steps/StepSelectIcons';
import StepSelectFonts from './Steps/StepSelectFonts';
import StepModelBackground from './Steps/StepModelBackground';

import Canvas from '../Canvas';

interface ModalProps {
    openModal: boolean;
    setOpenModal: (data: boolean) => void;
    viewModeSimulator: 'driver' | 'client';
    typeModule: 'mobilitypro' | 'speedmob';
}

interface ListIconsProps {
    colors_icon: string[];
    id: number;
    type_icon: '2d' | '3d',
    url_icon: string;
}

export type TypeTextLogoProps = 'stroke' | 'fill';

interface ListTypeFontsProps {
    id: number;
    link: string;
    name_font: string;
    type_text: TypeTextLogoProps;
    color_text: string;
}

export interface IconsFindedProps {
    id: number;
    url_icon: string;
}

export type ModelsDesignProps = 'design1' | 'design2' | 'design3' | 'design4' | 'design5' | 'design6' | 'design7';
export type TypeFontSloganProps = 'Neucha' | 'Kalam' | 'Julee' | 'Lobster' | 'Oswald' | 'Itim';

interface ListLogosGenerateProps {
    id: number;
    icon: IconsFindedProps;
    text: ListTypeFontsProps;
    fontSlogan: TypeFontSloganProps;
    model: ModelsDesignProps;
    colorDesignLogo: string;
}

const fontStyles = [
    {
        id: 1,
        link: "https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiZQ.woff2",
        name_font: "Oswald"
    },
    {
        id: 2,
        link: "https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMthROCc.woff2",
        name_font: "Kalam"
    },
    {
        id: 3,
        link: "https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bfvQlt.woff2",
        name_font: "Neucha"
    },{
        id: 4,
        link: "https://fonts.gstatic.com/s/julee/v22/TuGfUVB3RpZPQ5ZMq9k.woff2",
        name_font: "Julee"
    },
    {
        id: 5,
        link: "https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoKmMw.woff2",
        name_font: "Lobster"
    },
    { 
        id: 6,
        link: "https://fonts.gstatic.com/s/itim/v10/0nknC9ziJOYe8ANAkA.woff2",
        name_font: "Itim"
    }
];

const listTypeDesign = ["design1", "design2", "design3", "design4", "design5", "design6", "design7"];

const listFlatIcons = [
    {
        id: 1,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2736/2736918.png"
    },
    {
        id: 2,
        url_icon: "https://cdn-icons-png.flaticon.com/256/89/89102.png"
    },
    {
        id: 3,
        url_icon: "https://cdn-icons-png.flaticon.com/256/67/67994.png"
    },
    {
        id: 4,
        url_icon: "https://cdn-icons-png.flaticon.com/256/3054/3054929.png"
    },
    {
        id: 5,
        url_icon: "https://cdn-icons-png.flaticon.com/256/9716/9716698.png"
    },
    {
        id: 6,
        url_icon: "https://cdn-icons-png.flaticon.com/256/48/48688.png"
    },
    {
        id: 7,
        url_icon: "https://cdn-icons-png.flaticon.com/256/550/550907.png"
    },
    {
        id: 8,
        url_icon: "https://cdn-icons-png.flaticon.com/256/9716/9716696.png"
    },
    {
        id: 9,
        url_icon: "https://cdn-icons-png.flaticon.com/256/1048/1048315.png"
    },
    {
        id: 10,
        url_icon: "https://cdn-icons-png.flaticon.com/256/55/55283.png"
    },
    {
        id: 11,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2555/2555013.png"
    },
    {
        id: 12,
        url_icon: "https://cdn-icons-png.flaticon.com/256/1048/1048313.png"
    },
    {
        id: 13,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2736/2736953.png"
    },
    {
        id: 14,
        url_icon: "https://cdn-icons-png.flaticon.com/256/9714/9714799.png"
    },
    {
        id: 15,
        url_icon: "https://cdn-icons-png.flaticon.com/256/3190/3190249.png"
    },
    {
        id: 16,
        url_icon: "https://cdn-icons-png.flaticon.com/256/9716/9716206.png"
    },
    {
        id: 17,
        url_icon: "https://cdn-icons-png.flaticon.com/256/1023/1023757.png"
    },
    {
        id: 18,
        url_icon: "https://cdn-icons-png.flaticon.com/256/1023/1023655.png"
    },
    {
        id: 19,
        url_icon: "https://cdn-icons-png.flaticon.com/256/1068/1068620.png"
    },
    {
        id: 20,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2168/2168526.png"
    },
    {
        id: 21,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2077/2077164.png"
    },
    {
        id: 22,
        url_icon: "https://cdn-icons-png.flaticon.com/256/75/75782.png"
    },
    {
        id: 23,
        url_icon: "https://cdn-icons-png.flaticon.com/256/4744/4744199.png"
    },
    {
        id: 24,
        url_icon: "https://cdn-icons-png.flaticon.com/256/2125/2125190.png"
    }
];

const fontStyleSlogan = ["Neucha", "Kalam", "Julee", "Lobster", "Oswald", "Itim"];
const typesText = ["stroke", "fill"];
const listColorss = ["#A13941FF", "#31AFB4", "#8D1036", "#1498D5", "#F47C50", "#6D93B7", "#000000"];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffffff',
    border: '1px solid #000000',
    borderRadius: '5px',
    padding: '20px',
};

export default function Modal({ openModal, setOpenModal, viewModeSimulator, typeModule }: ModalProps) {
    const handleClose = () => setOpenModal(false);

    const [nameBusiness, setNameBusiness] = useState<string>('');
    const [nameSlogan, setNameSlogan] = useState<string>('');

    const [fontsChoosed, setFontsChoosed] = useState<string[]>([]);
    const [iconsSelected, setIconsSelected] = useState<any>([]);
    const [listLogosGenerated, setListLogosGenerated] = useState<ListLogosGenerateProps[]>([]);

    const [stepRendered, setStepRendered] = useState<ReactNode | null>(null);
    const [step, setStep] = useState(1);

    const [isTransparentLogo, setIsTransparentLogo] = useState<'transparent' | 'normal' | null>(null);

    const handleReset = () => {
        setStep(1);
        setIconsSelected([]);
        setFontsChoosed([]);
        setListLogosGenerated([]);
        setStepRendered(null);
        setIsTransparentLogo(null);
        setNameBusiness('');
        setNameSlogan('');
    }

    const handleRandomIndex = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const handleChooseElement = (list: ListIconsProps[] | string[]) => {
        const randomIndex = handleRandomIndex(0, list.length - 1);
        return list[randomIndex];
    }

    const handleGenerateLogo = async () => {
        let listGenerated = [];
        const listColors = ['#0F77A3', '#000000', '#DC143C', '#A6C4B1'];

        try {
            /*
            const responseListTypeFonts = await axios.get('http://localhost:3003/fontStyles');
            const responseListDesign = await axios.get('http://localhost:3003/listTypeDesign');
            const responseListFontSlogan = await axios.get('http://localhost:3003/fontStyleSlogan');
            const responseListTypeText = await axios.get('http://localhost:3003/typesText');
            const responseListColors = await axios.get('http://localhost:3003/listColors');
            */
            for (let indice = 1; indice < 51; indice++) {
                const logoChoosed = handleChooseElement(iconsSelected) as IconsFindedProps;
                const colorTextChoosed = handleChooseElement(listColorss) as string;
                const fontChoosed = handleChooseElement(fontStyles as any) as any;
                const designChoosed = handleChooseElement(listTypeDesign) as ModelsDesignProps;
                const fontSloganChoosed = handleChooseElement(fontStyleSlogan) as TypeFontSloganProps;
                const typeTextLogo = handleChooseElement(typesText) as TypeTextLogoProps;
                const logoColorChoosed = handleChooseElement(listColors) as string;
    
                fontChoosed['type_text'] = typeTextLogo;
                fontChoosed['color_text'] = colorTextChoosed;
    
                const modelJsonGenerate = {
                    id: indice,
                    icon: logoChoosed,
                    text: fontChoosed,
                    fontSlogan: fontSloganChoosed,
                    model: designChoosed,
                    colorDesignLogo: logoColorChoosed
                }
    
                listGenerated.push(modelJsonGenerate);
            }
    
            setListLogosGenerated(listGenerated);
    
        } catch (error) {
            console.log(error);
        }
    }
    

    /*
    const handleIcons = async () => {
        try {
            const responseIcons = await axios({
                method: 'get',
                url: 'http://localhost:3003/listFlatIcons'
            })
            setIconsFinded(responseIcons.data);
        } catch (error) {
            console.log(error);
        }
    }
    */

    const handleNextStep = () => {

        if (step === 1) {
            if (!nameBusiness) {
                swal({
                    title: 'Por favor digite o nome de sua empresa!',
                    icon: 'info'
                });
                return;
            }

            if (!nameSlogan) {
                swal({
                    title: 'Por favor digite o seu slogan!',
                    icon: 'info'
                });
                return;
            }

            if (nameBusiness.length >= 18) {
                swal({
                    title: 'Este nome é muito grande. Tente algo mais compacto!',
                    icon: 'info'
                });
                return;
            }

            if (nameSlogan.length >= 33) {
                swal({
                    title: 'O seu slogan é muito grande. Escolha outro por favor!',
                    icon: 'info'
                });
                return;
            }
        }

        if (step === 2) {
            if (iconsSelected.length < 5) {
                swal({
                    title: 'Selecione os cinco ícones',
                    icon: 'info'
                });
                return;
            }
        }

        if (step === 3) {
            if (fontsChoosed.length < 3) {
                swal({
                    title: 'Escolha as três fontes',
                    icon: 'info'
                });
                return;
            }
        }

        setStep(currentStep => currentStep + 1);
    }

    const handleBackStep = () => {
        setStep(currenStep => currenStep - 1);
    }

    useEffect(() => {
        if (step === 1) {
            setStepRendered(
                <StepBusiness
                    nameBusiness={nameBusiness}
                    nameSlogan={nameSlogan}
                    setNameBusiness={setNameBusiness}
                    setNameSlogan={setNameSlogan}
                />
            );
        } else if (step === 2) {
            setStepRendered(
                <StepSelectIcons
                    iconsFinded={listFlatIcons}
                    iconsSelected={iconsSelected}
                    setIconsSelected={setIconsSelected}
                />
            );
        } else if (step === 3) {
            setStepRendered(
                <StepSelectFonts
                    listFontsChoosed={fontsChoosed}
                    setListFontsChoosed={setFontsChoosed}
                />
            )
        } else if (step === 4) {
            setStepRendered(
                <StepModelBackground
                    setIsTypeBackground={setIsTransparentLogo}
                    isTypeBackground={isTransparentLogo}
                />
            )
        }
    }, [step, fontsChoosed, iconsSelected, nameSlogan, nameBusiness, isTransparentLogo]);

    /*
    useEffect(() => {
        handleIcons();
    }, []);
    */

    const listFilteredTypeFont = listLogosGenerated?.filter((data) => fontsChoosed.includes(data.text.name_font));

    return (
        <div>
            <ModalMaterialUI
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-header-generate-logo"
            >
                <Container style={style as any}>
                    {listFilteredTypeFont.length === 0 && stepRendered}

                    <div className='content-button-steps'>
                        {step !== 1 && listFilteredTypeFont.length === 0 &&
                            <Button onClick={handleBackStep} variant='contained'>Voltar</Button>
                        }
                        {step === 4 && listFilteredTypeFont.length === 0 &&
                            <Button disabled={isTransparentLogo === null} onClick={handleGenerateLogo} variant='contained'>Gerar</Button>
                        }
                        {step !== 4 && listFilteredTypeFont.length === 0 &&
                            <Button onClick={handleNextStep} variant='contained'>Próximo</Button>
                        }

                        {listFilteredTypeFont.length > 0 && <Button onClick={handleReset} variant='contained'>Resetar</Button>}
                    </div>

                    {listFilteredTypeFont.length > 0 &&
                        <div className='content-logo-generated'>
                            {listFilteredTypeFont.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Canvas
                                            typeModule={typeModule}
                                            viewModeSimulator={viewModeSimulator}
                                            backgroundTransparent={isTransparentLogo}
                                            colorDesignLogo={value.colorDesignLogo}
                                            typeFont={value.text && value.text.type_text}
                                            colorLogoText={value.text.color_text}
                                            typeFontSlogan={value.fontSlogan}
                                            nameLogo={nameBusiness}
                                            nameSlogan={nameSlogan}
                                            typeLogo={value.model}
                                            linkFontName={value.text.link}
                                            nameFontLink={value.text.name_font}
                                            urlImage={value.icon.url_icon}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    }
                </Container>
            </ModalMaterialUI>
        </div>
    )
}