import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import { PreviewDatas } from "../../Components/PreviewDatas";
import {
  UseFormsMobilityPro,
  FormActions,
} from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import { Container } from "./styles";
import { useEffect, useState } from "react";

import Mask from "../../../../../Utils/masks";

interface IFormInputs {
  phone: string;
  whatsapp: string;
  email: string;
}

const schema = yup.object({
  phone: yup.string().required("Este campo é obrigatório"),
  whatsapp: yup.string().required("Este campo é obrigatório"),
  email: yup
    .string()
    .email("Este campo é do tipo email")
    .required("Este campo é obrigatório"),
});

export default function FormContact() {
  const { control, handleSubmit, setValue } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const [visiblePreviewDatas, setVisiblePreviewDatas] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();
  const { state, dispatch } = UseFormsMobilityPro();

  const handleBackStep = () => {
    navigate(`/mobilitypro/cidades/${id}`);
  };

  const handleNextStep = (datas: IFormInputs) => {
    dispatch({
      type: FormActions.setPhone,
      payload: datas.phone,
    });

    dispatch({
      type: FormActions.setWhatsApp,
      payload: datas.whatsapp,
    });

    dispatch({
      type: FormActions.setEmail,
      payload: datas.email,
    });

    const cloneState = state;
    cloneState.phone = datas.phone;
    cloneState.whatsapp = datas.whatsapp;
    cloneState.email = datas.email;

    localStorage.setItem("forms_datas_mobilitypro", JSON.stringify(cloneState));

    //setVisiblePreviewDatas(true);

    navigate(`../confirmacao_de_dados/${id}`);
  };

  useEffect(() => {
    if (!state.list_select[0].cities_id) {
      setListConfigChecked([]);
      navigate(`/mobilitypro/informacoes/${id}`);
      return;
    }

    if (!listConfigChecked.includes("contact")) {
      setListConfigChecked([...listConfigChecked, "contact"]);
    }
  }, [
    listConfigChecked,
    setListConfigChecked,
    navigate,
    id,
    state.list_select,
  ]);

  useEffect(() => {
    if (state.phone) {
      setValue("phone", state.phone);
    }

    if (state.whatsapp) {
      setValue("whatsapp", state.whatsapp);
    }

    if (state.email) {
      setValue("email", state.email);
    }
  }, [state.phone, state.whatsapp, state.email, setValue]);

  return (
    <ThemeConfigApp
      isCenter="center"
      moduleTheme="mobilitypro"
      currentList="contact"
    >
      <Container>
        <form className="content" onSubmit={handleSubmit(handleNextStep)}>
          <h1>Contato de suporte para passageiros e motoristas</h1>

          <div className="content-inputs">
            <Controller
              control={control}
              name="phone"
              render={({
                field: { onBlur, value = "", name, ref },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  error={error ? true : false}
                  helperText={error && error.message}
                  label="Telefone"
                  onBlur={onBlur}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = Mask.setOnlyNumber(value);
                    setValue("phone", Mask.setMaskPhoneValue(value));
                  }}
                  value={value}
                  inputRef={ref}
                  name={name}
                />
              )}
            />

            <Controller
              control={control}
              name="whatsapp"
              render={({
                field: { onBlur, value = "", name, ref },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  error={error ? true : false}
                  helperText={error && error.message}
                  label="Whatsapp"
                  onBlur={onBlur}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = Mask.setOnlyNumber(value);
                    setValue("whatsapp", Mask.setMaskPhoneValue(value));
                  }}
                  value={value}
                  inputRef={ref}
                  name={name}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, onBlur, value = "", name, ref },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  error={error ? true : false}
                  helperText={error && error.message}
                  label="Email"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  name={name}
                />
              )}
            />
          </div>

          <div className="content-button-next">
            <button className="btn-next" type="submit"></button>
            <button className="btn-back" onClick={handleBackStep}></button>
          </div>
        </form>
      </Container>
      <PreviewDatas
        setVisible={setVisiblePreviewDatas}
        visible={visiblePreviewDatas}
      />
    </ThemeConfigApp>
  );
}
