import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { api } from "../../../../../Api";
import {
  UseFormsMobilityPro,
  FormActions,
} from "../../../../../contexts/FormsMobilityPro";
import { useEffect, useState } from "react";
import { Container } from "./styles";
import ThemeConfigApp from "../../../../../components/ThemeConfigApp";

import Mask from "../../../../../Utils/masks";

import swal from "sweetalert";

import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

interface IFormInputs {
  name: string;
  document: string;
}

const schema = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  document: yup.string().required("Este campo é obrigatório"),
});

interface FormInformationsProps {
  setUuidApplication: (data: string | null) => void;
}

export default function FormInformations({
  setUuidApplication,
}: FormInformationsProps) {
  const { control, handleSubmit, setValue } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const { state, dispatch, setLoading } = UseFormsMobilityPro();

  const [urlExample, setUrlExample] = useState<string>("");

  const navigate = useNavigate();
  const { id } = useParams();

  const getFirstWord = (word: string) => {
    return word.split(" ")[0];
  };

  const checkExistsProjectName = async (projectName: string) => {
    setLoading(true);
    try {
      const response = await api.get(
        `Mobility/verifyProjectName?projectName=${projectName}`
      );
      if (response.data.message === "Nome de projeto disponível") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNameApp = async (nameApp: string) => {
    try {
      const nameFormated = getFirstWord(nameApp).toLocaleLowerCase();
      let valueIndex = 1;
      for (valueIndex; valueIndex < 50; valueIndex++) {
        let newName = `${nameFormated}${valueIndex}`;
        let response = await checkExistsProjectName(newName);

        if (response) {
          setUrlExample(newName);
          setValue(
            "name",
            `${nameApp.split(" ")[0]}${valueIndex} ${
              nameApp.split(" ")[1] === undefined ? "" : nameApp.split(" ")[1]
            }`
          );
          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextStep = async (data: IFormInputs) => {
    const nameAppFormated = getFirstWord(data.name).toLocaleLowerCase();

    const responseCheckProject = await checkExistsProjectName(nameAppFormated);

    if (!responseCheckProject) {
      swal({
        title: "Nome de aplicativo já existente!",
        text: "Você deseja uma sugestão de nome?",
        icon: "warning",
        buttons: ["Não", "Sim"],
      }).then((accepted) => {
        if (accepted) {
          handleGenerateNameApp(data.name);
        }
      });
      return;
    }

    if (data) {
      dispatch({
        type: FormActions.setName,
        payload: data.name,
      });
      dispatch({
        type: FormActions.setProjectName,
        payload: data.name.toLocaleLowerCase().replaceAll(" ", ""),
      });

      dispatch({
        type: FormActions.setDocument,
        payload: data.document,
      });

      const cloneState = state;
      cloneState.name = data.name;
      cloneState.project_name = data.name
        .toLocaleLowerCase()
        .replaceAll(" ", "");
      cloneState.document = data.document;

      localStorage.setItem(
        "forms_datas_mobilitypro",
        JSON.stringify(cloneState)
      );

      navigate(`../cores-aplicativo-motorista/${id}`);
    }
  };

  useEffect(() => {
    if (!listConfigChecked.includes("informations")) {
      setListConfigChecked([...listConfigChecked, "informations"]);
    }
  }, [setListConfigChecked, listConfigChecked]);

  useEffect(() => {
    if (state.name) {
      setValue("name", state.name);
      setUrlExample(getFirstWord(state.name).toLocaleLowerCase());
    }

    if (state.document) {
      setValue("document", state.document);
    }
  }, [state, setValue]);

  useEffect(() => {
    const verifyFormCompleted = async () => {
      try {
        const responseFormCompleted = await api.get(
          `Mobility/consultForm?formKey=${id}`
        );
        if (responseFormCompleted.data.data !== null) {
          navigate("../formulario-preenchido");
        }
      } catch (error) {
        console.log(error);
      }
    };

    verifyFormCompleted();
  }, [id, navigate]);

  useEffect(() => {
    const responseCache = localStorage.getItem("forms_datas_mobilitypro");

    if (responseCache === null) {
      setValue("name", "");
    }
  }, [setValue]);

  useEffect(() => {
    const verifyIsExistUuid = async () => {
      try {
        const responseUuid = await api.get(
          `Mobility/verifyFormKey?formKey=${String(id)}`
        );
        if (!responseUuid.data.data) {
          navigate("../uuid-inválido");
          return;
        }
      } catch (error) {
        console.log(error);
        navigate("../uuid-inválido");
      }
    };
    verifyIsExistUuid();
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      setUuidApplication(id);
    }
  }, [setUuidApplication, id]);

  return (
    <ThemeConfigApp
      moduleTheme="mobilitypro"
      isCenter="center"
      currentList="informations"
    >
      <Container>
        <div className="content-form-name-app">
          <div className="content-form-config-app">
            <h1>Preencha suas informações</h1>
            <form
              className="content-select-colors"
              onSubmit={handleSubmit(handleNextStep)}
            >
              <Controller
                control={control}
                name="name"
                render={({
                  field: { onBlur, value = "", name, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    error={error ? true : false}
                    helperText={error && error.message}
                    label="Nome do aplicativo"
                    inputProps={{ maxLength: 30 }}
                    onBlur={onBlur}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = Mask.setOnlyLetters(value);
                      setUrlExample(
                        getFirstWord(
                          Mask.setOnlyLetters(value)
                        ).toLocaleLowerCase()
                      );
                      setValue("name", Mask.setOnlyLetters(value));
                    }}
                    value={value}
                    inputRef={ref}
                    name={name}
                  />
                )}
              />

              <span className="text-example-monitor">{`https://monitor-${
                urlExample.length > 0 ? urlExample : "{nome_url}"
              }.prod.704apps.com.br/`}</span>

              <Controller
                control={control}
                name="document"
                render={({
                  field: { value = "", name, onChange, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    error={error ? true : false}
                    helperText={error && error.message}
                    label="Documento"
                    onChange={(event) => {
                      setValue(
                        "document",
                        Mask.setMaskCpfAndCnpj(event.target.value)
                      );
                    }}
                    value={value}
                    inputRef={ref}
                    name={name}
                  />
                )}
              />

              <div className="content-button-next">
                <button className="btn-next" type="submit"></button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
