import { Switch } from "@mui/material";
import { SunDim, Moon } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import { Content } from "./styles";
import pathLogo from "../../../../../assets/images/logo-squares-704.png";
import { ChangeEvent } from "react";

interface HeaderProps {
  setToggleDarkMode: (data: boolean) => void;
  toggleDarkMode: boolean;
  id: string | null;
}

export default function Header({
  toggleDarkMode,
  setToggleDarkMode,
  id,
}: HeaderProps) {
  const navigate = useNavigate();

  const handleChangeDarkMode = (event: ChangeEvent<HTMLInputElement>) => {
    setToggleDarkMode(event.target.checked);
    localStorage.setItem("darkMode", JSON.stringify(event.target.checked));
  };

  const handleRedirectHome = () => {
    navigate(`mobilitypro/informacoes/${id}`);
  };

  return (
    <Content>
      <div className="content-logo" onClick={handleRedirectHome}>
        <img className="img-logo" src={pathLogo} alt="logo" />
      </div>
      <div>
        <div className="content-control-darkmode">
          <Switch checked={toggleDarkMode} onChange={handleChangeDarkMode} />
          {toggleDarkMode ? (
            <Moon size={30} color="#FF8004" weight="bold" />
          ) : (
            <SunDim size={30} color="#FF8004" weight="bold" />
          )}
        </div>
      </div>
    </Content>
  );
}
