import { Container } from "./styles"
import pathMoldurePhone from '../../assets/images/moldura.png';

interface SimulatorAppProps{
    children: React.ReactNode;
}

export default function SimulatorApp({ children }: SimulatorAppProps){
    return(
        <Container> 
            <img src={pathMoldurePhone} alt="moldure-phone" />
            { children }
        </Container>
    )
}