import { ChangeEvent, useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Question } from "phosphor-react";
import {
  FormControl,
  TextField,
  RadioGroup,
  Radio,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";
import InputCurrency from "../../../../../components/InputCurrency";
import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import IconHelp from "../../../../../components/IconHelp";

import { Container } from "./styles";

export default function FormRules() {
  const [chargeValueExtraFixe, setChargeValueExtraFixe] = useState<string>("");
  const [chargeValueExtraPerTrip, setChargeValueExtraPerTrip] =
    useState<string>("");
  const [driverDemandValueFixe, setDriverDemandValueFixe] =
    useState<string>("");
  const [driverDemandPerTrip, setDriverDemandPerTrip] = useState<string>("");
  const [cancellationFeePerCancellation, setCancellationFeePerCancellation] =
    useState<string>("");
  const [cancellationFeePerMinutes, setCancellationFeePerMinutes] =
    useState<string>("");
  const [accountReceiveValues, setAccountReceiveValues] = useState<string>("");
  const [formPaymentDriver, setFormPaymentDriver] = useState<string>("");
  const [stateCheckboxsReceivedValues, setStateCheckboxsReceivedValues] =
    useState<any>({
      credit_card: null,
      money: null,
      pix: null,
    });
  const [stateCheckboxsAllow, setStateCheckboxsAllow] = useState<any>({
    activeDynamic: false,
    allowCallWait: false,
    allowStop: false,
  });

  const [checkIsValueCancellationFee, setCheckIsValueCancellationFee] =
    useState<boolean>(false);
  const [checkDriverChargePerTrip, setCheckDriverChargePerTrip] =
    useState<boolean>(false);
  const [checkExtraChargeBetween, setCheckExtraChargeBetween] =
    useState<boolean>(false);
  const [
    checkRadioInputPerTripValueFixed,
    setCheckRadioInputPerTripValueFixed,
  ] = useState<boolean>(false);
  const [
    checkRadioInputPerTripValuePercentage,
    setCheckRadioInputPerTripValuePercentage,
  ] = useState<boolean>(false);
  const [
    checkRadioInputChargeBetweenValueFixed,
    setCheckRadioInputChargeBetweenValueFixed,
  ] = useState<boolean>(false);
  const [
    checkRadioInputChargeBetweenValuePercentage,
    setCheckRadioInputChargeBetweenValuePercentage,
  ] = useState<boolean>(false);

  const [valueRadioPerTrips, setValueRadioPerTrips] = useState<boolean>(false);
  const [isValueFixedPerTripSelect, setIsValueFixedPerTripSelect] = useState<boolean>(true);

  const [valueRadioExtraBetween, setValueRadioExtraBetween] = useState<boolean>(false);
  const [isValueFixedExtraBetweenSelect, setIsValueFixedExtraBetweenSelect] = useState<boolean>(true);

  const { state, dispatch } = useSpeedMobForm();
  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const navigate = useNavigate();

  const handleOptionCancellationFee = () => {
    if (checkIsValueCancellationFee) {
      setCheckIsValueCancellationFee(false);
    } else {
      setCheckIsValueCancellationFee(true);
    }
  };

  const handleOptionDriverChargePerTrip = () => {
    if (checkDriverChargePerTrip) {
      setCheckDriverChargePerTrip(false);
      setValueRadioPerTrips(false);
    } else {
      setCheckDriverChargePerTrip(true);
      setValueRadioPerTrips(true);
    }
  };

  const handleOptionExtraChargeBetween = () => {
    if (checkExtraChargeBetween) {
      setCheckExtraChargeBetween(false);
      setValueRadioExtraBetween(false);
    } else {
      setCheckExtraChargeBetween(true);
      setValueRadioExtraBetween(true);
    }
  };

  const handleOptionRadioGroupInputsPerTrip = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const valueRadio = event.target.value;
    if (valueRadio === "1") {
      setIsValueFixedPerTripSelect(false);
    } else {
      setIsValueFixedPerTripSelect(true);
    }
  };

  const handleOptionRadioGroupInputsExtraChargeBetween = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const valueRadio = event.target.value;
    if (valueRadio === "1") {
      setIsValueFixedExtraBetweenSelect(false);
    } else {
      setIsValueFixedExtraBetweenSelect(true);
    }
  };

  const handleChangeOptionCheckboxReceivedValues = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setStateCheckboxsReceivedValues({
      ...stateCheckboxsReceivedValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeOptionCheckboxAllowCheckbox = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setStateCheckboxsAllow({
      ...stateCheckboxsAllow,
      [event.target.name]: event.target.checked,
    });
  };

  const handleBackStep = () => {
    navigate("/speedmob/configuracoes-do-app/taxas-do-app");
  };

  const handleNextStep = () => {
    swal({
      title: "Tem certeza que deseja prosseguir com esses dados?",
      icon: "info",
      buttons: ["Não", "Sim"],
    }).then((isAccept) => {
      if (isAccept) {
        dispatch({
          type: FormActions.setCancellationFeeValue,
          payload: cancellationFeePerCancellation,
        });

        dispatch({
          type: FormActions.setCancellationFeeMinutes,
          payload: cancellationFeePerMinutes,
        });

        dispatch({
          type: FormActions.setDriverPerTripValueFixe,
          payload: driverDemandValueFixe,
        });

        dispatch({
          type: FormActions.setDriverPerTripPerWeekend,
          payload: driverDemandPerTrip,
        });

        dispatch({
          type: FormActions.setTypePaymentDriver,
          payload: formPaymentDriver,
        });

        dispatch({
          type: FormActions.setPermissionAllowCallWaiting,
          payload: stateCheckboxsAllow.allowCallWait,
        });

        dispatch({
          type: FormActions.setAllowStops,
          payload: stateCheckboxsAllow.allowStop,
        });

        dispatch({
          type: FormActions.setActiveDynamics,
          payload: stateCheckboxsAllow.activeDynamic,
        });

        dispatch({
          type: FormActions.setChargeExtraValueFixe,
          payload: chargeValueExtraFixe,
        });

        dispatch({
          type: FormActions.setChargeExtraValuePerTrip,
          payload: chargeValueExtraPerTrip,
        });

        dispatch({
          type: FormActions.setReceivedValuesPerMoney,
          payload: stateCheckboxsReceivedValues.money,
        });

        dispatch({
          type: FormActions.setReceivedValuesPerPix,
          payload: stateCheckboxsReceivedValues.pix,
        });

        dispatch({
          type: FormActions.setReceivedValuesCreditCard,
          payload: stateCheckboxsReceivedValues.credit_card,
        });

        dispatch({
          type: FormActions.setAccountReceivedValues,
          payload: accountReceiveValues,
        });

        
        const cloneState = state;

        cloneState.cancellationFeeValue = cancellationFeePerCancellation;
        cloneState.cancellationFeeMinutes = cancellationFeePerMinutes;
        cloneState.driverPerTripValueFixe= driverDemandValueFixe;
        cloneState.driverPerTriPerWeekend = driverDemandPerTrip;
        cloneState.typePaymentDriver =formPaymentDriver;
        cloneState.permissionAllowCallWaiting = stateCheckboxsAllow.allowCallWait;
        cloneState.allowStops = stateCheckboxsAllow.allowStop;
        cloneState.activeDynamics = stateCheckboxsAllow.activeDynamic; 
        cloneState.chargeExtraValueFixe = chargeValueExtraFixe;
        cloneState.chargeExtraValuePerTrip = chargeValueExtraPerTrip;
        cloneState.receivedValuesPerMoney = stateCheckboxsReceivedValues.money;
        cloneState.receivedValuesPerPix = stateCheckboxsReceivedValues.pix;
        cloneState.receivedValuesCreditCard = stateCheckboxsReceivedValues.credit_card;
        cloneState.accountReceivedValues = accountReceiveValues;

        localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

        navigate("../configuracao-finalizada");
      }
    });
  };

  useEffect(() => {
    const isTaxas =
      state.valueStartSimulator === null &&
      state.valueKmSimulator === null &&
      state.valueMinuteSimulator === null &&
      state.addressOriginSimulator === null &&
      state.addressDestinySimulator === null;

    if (isTaxas) {
      navigate("/speedmob/apresentacao");
    }
    
  }, [state.valueStartSimulator, state.valueKmSimulator, state.valueMinuteSimulator, state.addressOriginSimulator, state.addressDestinySimulator, navigate]);
 
  useEffect(() => {
    if(!listConfigChecked.includes('rules')){
      setListConfigChecked([...listConfigChecked, 'rules']);
    }
  }, [listConfigChecked, setListConfigChecked]);

  useEffect(() => {
    if(valueRadioPerTrips){
      if(isValueFixedPerTripSelect){
        setCheckRadioInputPerTripValueFixed(true);
        setCheckRadioInputPerTripValuePercentage(false);
      }else{
        setCheckRadioInputPerTripValueFixed(false);
        setCheckRadioInputPerTripValuePercentage(true);
      }
    }else{
      setCheckRadioInputPerTripValuePercentage(false);
      setCheckRadioInputPerTripValueFixed(false);
    }     
  }, [checkDriverChargePerTrip, valueRadioPerTrips, isValueFixedPerTripSelect]);

  useEffect(() => {
    if(valueRadioExtraBetween){
      if(isValueFixedExtraBetweenSelect){
        setCheckRadioInputChargeBetweenValueFixed(true);
        setCheckRadioInputChargeBetweenValuePercentage(false);
      }else{
        setCheckRadioInputChargeBetweenValueFixed(false);
        setCheckRadioInputChargeBetweenValuePercentage(true);
      }
    }else{
      setCheckRadioInputChargeBetweenValueFixed(false);
      setCheckRadioInputChargeBetweenValuePercentage(false);
    }
  }, [checkExtraChargeBetween, valueRadioExtraBetween,isValueFixedExtraBetweenSelect]);

  return (
    <ThemeConfigApp moduleTheme="speedmob" isCenter="normal" currentList="rules">
      <Container>
        <h1>Defina as regras do seu aplicativo:</h1>
        <div className="content-settings-rules">
          <div className="content-column1">
            <div>
              <div className="content-text-header">
                <FormControlLabel
                  value={checkIsValueCancellationFee}
                  control={<Checkbox />}
                  label="Vai cobrar taxa de cancelamento?"
                  onChange={handleOptionCancellationFee}
                />
                <IconHelp
                  icon={<Question />}
                  typeHelp="popover"
                  urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                />
              </div>
              <div>
                <InputCurrency
                  fullWidth={true}
                  disable={!checkIsValueCancellationFee}
                  value={cancellationFeePerCancellation}
                  setValue={setCancellationFeePerCancellation}
                  id="value-cancellation-fee"
                  label="Taxa de cancelamento"
                />
                <TextField
                  value={cancellationFeePerMinutes}
                  onChange={(event) =>
                    setCancellationFeePerMinutes(event.target.value)
                  }
                  fullWidth
                  disabled={!checkIsValueCancellationFee}
                  id="later-minutes"
                  label="Após minutos"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="content-inputs-radio">
              <div className="content-text-header">
                <FormControlLabel
                  value={checkDriverChargePerTrip}
                  control={<Checkbox />}
                  label="Cobrar do motorista por viagem?"
                  onChange={handleOptionDriverChargePerTrip}
                />
                <IconHelp
                  icon={<Question />}
                  typeHelp="popover"
                  urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                />
              </div>
              <div>
                <RadioGroup
                  name="isCheckInputPerTrip"
                  defaultValue="0"
                  onChange={handleOptionRadioGroupInputsPerTrip}
                >
                  <div className="content-input">
                    <FormControlLabel
                      name="isCheckInputPerTrip"
                      value="0"
                      disabled={!checkDriverChargePerTrip}
                      control={<Radio />}
                      label=""
                    />
                    <InputCurrency
                      fullWidth={true}
                      disable={!checkRadioInputPerTripValueFixed}
                      value={driverDemandValueFixe}
                      setValue={setDriverDemandValueFixe}
                      id="value-fixe"
                      label="Valor fixo"
                    />
                  </div>

                  <div className="content-input">
                    <FormControlLabel
                      name="isCheckInputPerTrip"
                      value="1"
                      disabled={!checkDriverChargePerTrip}
                      control={<Radio />}
                      label=""
                    />
                    <TextField
                      value={driverDemandPerTrip}
                      onChange={(event) =>
                        setDriverDemandPerTrip(event.target.value)
                      }
                      fullWidth={true}
                      disabled={!checkRadioInputPerTripValuePercentage}
                      id="for-wekeend"
                      label="% Por viagem"
                      variant="outlined"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div className="content-inputs-radio">
              <div className="content-text-header">
                <FormControlLabel
                  value={checkExtraChargeBetween}
                  control={<Checkbox />}
                  label="Cobrar extra entre 22h e 6h"
                  onChange={handleOptionExtraChargeBetween}
                />

                <IconHelp
                  icon={<Question />}
                  typeHelp="popover"
                  urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                />
              </div>
              <div>
                <RadioGroup
                  defaultValue="0"
                  name="isCheckInputChargeExtra"
                  onChange={handleOptionRadioGroupInputsExtraChargeBetween}
                >
                  <div className="content-input">
                    <FormControlLabel
                      name="isCheckInputChargeExtra"
                      value="0"
                      disabled={!checkExtraChargeBetween}
                      control={<Radio />}
                      label=""
                    />
                    <InputCurrency
                      fullWidth={true}
                      disable={!checkRadioInputChargeBetweenValueFixed}
                      value={chargeValueExtraFixe}
                      setValue={setChargeValueExtraFixe}
                      id="value-fixe"
                      label="Valor fixo"
                    />
                  </div>

                  <div className="content-input">
                    <FormControlLabel
                      name="isCheckInputChargeExtra"
                      value="1"
                      disabled={!checkExtraChargeBetween}
                      control={<Radio />}
                      label=""
                    />
                    <TextField
                      value={chargeValueExtraPerTrip}
                      onChange={(event) =>
                        setChargeValueExtraPerTrip(event.target.value)
                      }
                      fullWidth
                      disabled={!checkRadioInputChargeBetweenValuePercentage}
                      id="for-wekeend"
                      label="% Por viagem"
                      variant="outlined"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div>
              <FormGroup>
                <div className="content-text-header">
                  <FormControlLabel
                    name="allowCallWait"
                    value="permitir-chamada-em-espera"
                    control={
                      <Checkbox
                        onChange={handleChangeOptionCheckboxAllowCheckbox}
                      />
                    }
                    label="Permitir chamada em espera?"
                  />

                  <IconHelp
                    icon={<Question />}
                    typeHelp="popover"
                    urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                  />
                </div>
                <div className="content-text-header">
                  <FormControlLabel
                    name="allowStop"
                    value="permitir-paradas"
                    control={
                      <Checkbox
                        onChange={handleChangeOptionCheckboxAllowCheckbox}
                      />
                    }
                    label="Permitir paradas?"
                  />

                  <IconHelp
                    icon={<Question />}
                    typeHelp="popover"
                    urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                  />
                </div>
                <div className="content-text-header">
                  <FormControlLabel
                    name="activeDynamic"
                    value="ativar-dinamicas"
                    control={
                      <Checkbox
                        onChange={handleChangeOptionCheckboxAllowCheckbox}
                      />
                    }
                    label="Ativar dinâmica"
                  />
                  <IconHelp
                    icon={<Question />}
                    typeHelp="popover"
                    urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                  />
                </div>
              </FormGroup>
            </div>
          </div>

          <div className="content-column2">
            <div>
              <FormControl>
                <div className="content-text-header">
                  <FormLabel sx={{ fontWeight: 'bold', fontSize: '20px' }} id="type-work-driver">
                    Motorista trabalhará:
                  </FormLabel>
                  <IconHelp
                    icon={<Question />}
                    typeHelp="popover"
                    urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                  />
                </div>
                <RadioGroup
                  onChange={(event) => setFormPaymentDriver(event.target.value)}
                  value={formPaymentDriver}
                  aria-labelledby="type-work-driver"
                  defaultValue="pre-pago"
                  name="radio-group-type-work-driver"
                >
                  <FormControlLabel
                    value="pre-pago"
                    control={<Radio />}
                    label="Pré-pago"
                  />
                  <FormControlLabel
                    value="pos-pago"
                    control={<Radio />}
                    label="Pós-pago"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div>
              <FormGroup>
                <div className="content-text-header">
                  <FormLabel sx={{ fontWeight: 'bold', fontSize: '20px' }} id="type-received-values">
                    Receber valores por:{" "}
                  </FormLabel>
                  <IconHelp
                    icon={<Question />}
                    typeHelp="popover"
                    urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                  />
                </div>
                <FormControlLabel
                  name="money"
                  value="dinheiro"
                  control={
                    <Checkbox
                      onChange={handleChangeOptionCheckboxReceivedValues}
                    />
                  }
                  label="Dinheiro"
                />
                <FormControlLabel
                  name="pix"
                  value="pix"
                  control={
                    <Checkbox
                      onChange={handleChangeOptionCheckboxReceivedValues}
                    />
                  }
                  label="Pix"
                />
                <FormControlLabel
                  name="credit_card"
                  value="cartão de crédito"
                  control={
                    <Checkbox
                      onChange={handleChangeOptionCheckboxReceivedValues}
                    />
                  }
                  label="Cartão de crédito"
                />
              </FormGroup>
              <TextField
                value={accountReceiveValues}
                onChange={(event) =>
                  setAccountReceiveValues(event.target.value)
                }
                id="account-received-values"
                label="Conta para receber seus valores"
                variant="outlined"
              />
            </div>
          </div>
        </div>
        <div className="content-button-next">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
