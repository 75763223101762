import { CaretLeft } from "phosphor-react";
import { FormEvent } from "react";

import { Container } from "./styles";

interface RegisterClientProps {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  setStepApplication: (data: "login" | "register") => void;
  pathLogoSelected?: string | undefined;
}

export default function RegisterClient({
  primaryColor,
  secondaryColor,
  textColor,
  setStepApplication,
  pathLogoSelected,
}: RegisterClientProps) {
  const handleBackLogin = () => {
    setStepApplication("login");
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <Container>
      <div
        style={{ backgroundColor: secondaryColor }}
        className="content-register"
      >
        <div
          style={{ backgroundColor: primaryColor, color: textColor }}
          className="header"
        >
          <div className="btn-back">
            <button onClick={handleBackLogin}>
              <CaretLeft color={textColor} size={30} weight="bold" />
            </button>
          </div>
          <div className="title-page">
            <span>Cadastro</span>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="field-full">
            <label htmlFor="full-name">Nome completo</label>
            <input id="full-name" type="text" required />
          </div>
          <div className="field-full">
            <label htmlFor="cpf">CPF</label>
            <input id="cpf" type="text" placeholder="CPF" required />
          </div>
          <div className="field-full">
            <label htmlFor="phone">Telefone</label>
            <input id="phone" type="text" placeholder="Telefone" />
          </div>
          <div className="field-full">
            <label htmlFor="email">E-mail</label>
            <input id="email" type="email" placeholder="E-mail" required />
          </div>
          <div className="field-full">
            <label htmlFor="gender">Gênero</label>
            <select defaultValue="masculino" name="gender" id="gender">
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
            </select>
            <p>
              Informações usadas apenas para promoções, o preenchimento não é
              obrigatório
            </p>
          </div>
          <div className="field-full">
            <label htmlFor="city">Cidade</label>
            <select defaultValue="fortaleza" name="city" id="city">
              <option value="fortaleza">Fortaleza</option>
              <option value="sao-goncalo">São Gonçalo</option>
              <option value="caucaia">Caucaia</option>
            </select>
          </div>
          <div className="field-full">
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" placeholder="Senha" />
          </div>
          <div className="field-full">
            <label htmlFor="confirm-password">Confirme a senha</label>
            <input type="password" placeholder="Confirme a senha" />
          </div>

          <div className="btn-submit">
            <button
              style={{ backgroundColor: primaryColor, color: textColor }}
              type="submit"
            >
              Avançar
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
}
