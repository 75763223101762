import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 10px;
    right: -5px;

    a{
        color: #000000;
    }
`