import styled from "styled-components";

export const Container = styled.button`
    text-align: center;
    padding: 1rem;
    width: 150px;
    height: 80px;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    background: none;
    
    &:hover{
        transition: all ease 0.8s;
        border: 1px solid red;
    }
`