import styled from "styled-components";

export const Container = styled.div`
    .content-logo-app{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .content-logo-page{
        width: 500px;
        align-self: flex-start;
        padding: 0 2rem;
    }

    .content-logo-page > h1 {
        color: ${props => props.theme.color.colorTextPrimary};
        margin-bottom: 1rem;
        font-size: 33px;
    }

    .content-select-logo{
        display: flex;
        flex-direction: column;
        gap: 20px;

        > div label{
            font-size: 23px;
            font-weight: bold;
            color: ${props => props.theme.color.colorButtonsSecondary};
        }

        > div > input{
            display: block;
        }

        > div > span {
            display: block;
            color: ${props => props.theme.color.colorTextPrimary};
        }
    }
    
    .select-logo-user{
        width: 100%;

        label{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: ${props => props.theme.color.colorTextOpposite};
            padding: 1rem;
            cursor: pointer ;
            
            span{
                font-size: 20px;
                font-weight: 500;
                color: ${props => props.theme.color.colorTextPrimary};
            }
        }
        
        input{
            display: none !important;
        }
    }

    .content-logo-generated{
        cursor: pointer;
    }

    .content-image img{
        width: 300px;
    }

    .content-group-button{
        display: flex;
        justify-content: center;
    }

    .content-group-button{
        .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
            color: ${props => props.theme.color.colorButtonsSecondary};
        }

        .css-d9c359-MuiButtonBase-root-MuiToggleButton-root{
            color: ${props => props.theme.color.colorTextPrimary};
        }

        .css-1gjgmky-MuiToggleButtonGroup-root{
            border: 1px solid ${props => props.theme.color.backgroundOpposite};
        }

        .css-11uq0kt{
            border: 1px solid ${props => props.theme.color.backgroundOpposite};
            color: ${props => props.theme.color.backgroundOpposite};
        }
    }
    
    .css-157pz5w-MuiButtonBase-root-MuiToggleButton-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }
    .content-button-next{
        display: flex;
        gap: 1rem;
        margin: 2rem 0;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .content-button-next-mobile{
        width: 100%;
        display: none;
        flex-direction: column-reverse;
        gap: 1rem;
        margin: 2rem 0;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            width: 100%;
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }
    
    @media(max-width: 768px){
        .content-logo-app{
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }

        .content-logo > h1 {
            text-align: center;
        }

        .content-button-next{
            display: none;
        }

        .content-button-next-mobile{
            display: flex;
            margin: 1rem 0;
        }
    }
    
    @media(max-width: 600px){
        .content-logo-page{
            width: 100%;
            padding: 0rem;
        }

        .content-logo-page > h1 {
            font-size: 25px;
            text-align: center;
         }

         .content-select-logo{
            > div label{
                font-size: 20px;
            }

            > div > span {
                font-size: 14px;
            }
        }
    }

`