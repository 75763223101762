import styled from "styled-components";

export const Container = styled.div`
    padding: 0rem 4rem;

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .css-fvipm8{
        color: ${props => props.theme.color.colorTextPrimary};
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        color: ${props => props.theme.color.colorTextPrimary};
    }

    label {
        color: ${props => props.theme.color.colorTextPrimary};
    }
    input{
        color: ${props => props.theme.color.colorTextPrimary};
    }

    > h1 {
        text-align: center;
        font-size: 30px;
        margin: 1rem 0;
    }

    h2{
        margin: 1rem 0;
    }

    .content-datas-client .content-inputs{
        display: flex;
        justify-content: space-between;
    }

    .content-datas-client .content-inputs > div{
        flex: 0.5;
    }

    .content-datas-client .content-inputs > div:nth-child(1){
        margin-right: 1rem;
    }

    .content-datas-address .content-inputs{
        display: flex;
        justify-content: space-between;
    }

    .content-datas-address .content-inputs > div{
        flex: 0.5;
    }

    .content-datas-address .content-inputs > div:nth-child(1){
        margin-right: 1rem;
    }

    .content-button-next{
        button {
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 8px 40px;
            color: var(--color-white-font);
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
            opacity: 1 !important;

            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }
    }

    @media(max-width: 900px){
        .content-datas-client .content-inputs{
            flex-direction: column;
            gap: 20px;
        }

        .content-datas-client .content-inputs > div{
            width: 100%;
            flex-direction: column;
            gap: 20px;
        }

        .content-datas-address .content-inputs{
            flex-direction: column;
            gap: 20px;
        }

        .content-datas-address .content-inputs > div{
            width: 100%;
            flex-direction: column;
            gap: 20px;
        }
    }

    @media(max-width: 768px){
        padding: 2rem 2rem;
    }

    @media(max-width: 425px){
        padding: 0 0.5rem;

        h2{
            text-align: center;
        }
    }
`