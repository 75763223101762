import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { ListCitiesProps } from '../../Pages/Modules/Speedmob/FormsStep/FormTaxas';

import { Container } from './styles';

interface Select2TextFieldProps {
    label: string;
    id: string;
    listOptions: any;
    value: string;
    setValue: (data: string) => void;
    setValueSelect: (data: any) => void;
}

interface ListCitiesFormatedProps {
    label: string;
    center: any;
}

export default function Select2TextField({ label, id, listOptions, value, setValue, setValueSelect }: Select2TextFieldProps) {

    const listCities = FormartListToDefaultSelect2(listOptions);

    return (
        <Container>
            <Autocomplete
                onChange={(event, value) => setValueSelect(value?.center)}
                disablePortal
                id={id}
                options={listCities}
                sx={{ width: 230 }}
                isOptionEqualToValue={(option, value) =>
                    option.center === value.center
                }
                renderInput={(params) => <TextField sx={{ mr: 2, mb: 2 }} value={value} onChange={(event) => setValue(event.target.value)} {...params} label={label} />}
            />
        </Container>
    );
}

const FormartListToDefaultSelect2 = (list: ListCitiesProps[]) => {
    const listCities: ListCitiesFormatedProps[] = [];

    list && list.map((value: ListCitiesProps) => {
        const newObject = {
            label: value.place_name,
            center: value.center
        }

        return listCities.push(newObject);
    })

    return listCities;
}