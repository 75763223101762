import { ArrowLeft } from "phosphor-react";

import { Container } from "./styles";
import pathLogoRandom from '../../../../assets/images/logo-random.png';
import { FormEvent } from 'react';


interface LoginCredenciesProps {
    primaryColor: string;
    secondaryColor: string;
    textColor: string;
    setStepApplication: (data: 'login' | 'loginCredencies' | 'register') => void;
    pathLogoSelected?: string | undefined;
}

export default function LoginCredencies({ primaryColor, secondaryColor, textColor, setStepApplication, pathLogoSelected }: LoginCredenciesProps) {

    const handleBackLogin = () => {
        setStepApplication('login');
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
    }

    return (
        <Container style={{ backgroundColor: secondaryColor, color: textColor }}>
            <div className='content-login-credencies'>
                <div style={{ backgroundColor: primaryColor, color: textColor }} className="header">
                    <div className="btn-back">
                        <button onClick={handleBackLogin}>
                            <ArrowLeft color={textColor} size={30}  weight='bold' />
                        </button>
                    </div>
                    <div className="name-page">
                        Login
                    </div>
                    <div className="logo">
                        <img src={pathLogoSelected ? pathLogoSelected : pathLogoRandom} alt="logo-default" />
                    </div>
                </div>

                <form className="content-form" onSubmit={handleSubmit}>
                    <input type="email" placeholder="Email" required />
                    <input type="password" placeholder="Senha" required/>

                    <span style={{ color: primaryColor }}>Esqueci minha senha</span>

                    <div className='content-button'>
                        <button style={{ color: primaryColor, borderColor: primaryColor }} type='submit'>ENTRAR</button>
                    </div>
                </form>
            </div>
        </Container>
    )
}