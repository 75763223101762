import { Container } from "./styles";
import ValueDiscount from "../ValueDiscount"; 

interface listPlansProps {
    namePlan: string;
    discount: number;
    qntCity: number;
    pricePlan: number;
    percentagePix: number;
    pricePortion: number;
    qntDays: number;
    qntPortion: number;
    valueRates: number;
    firstSignatureOldPrice: number;
    firstSignatureNewPrice: number;
}

interface CardPlanProps{
    namePlan: string;
    qntCity: number;
    pricePlan: number;
    discount: number;   
    firstSignatureOldPrice: number;
    firstSignatureNewPrice: number;
    qntPortion: number;
    pricePortion: number;
    valueRates: number;
    percentagePix: number;
    qntDays: number;   
    planSelected?: listPlansProps;
    setPlanSelected?: any;
}

export default function CardPlan({ 
    namePlan, 
    qntCity, 
    pricePlan, 
    discount, 
    firstSignatureOldPrice, 
    firstSignatureNewPrice, 
    qntPortion, 
    pricePortion, 
    valueRates, 
    percentagePix, 
    qntDays,
    setPlanSelected,
    planSelected,
}: CardPlanProps){

    return(
        <Container>
            <div className="card-content">
                <div className="content-header-card">
                    <div className="content-type-plan">
                        <h2>{namePlan}</h2>   
                        <span>{qntCity} Cidade</span>
                    </div>
                    <div>
                        <div className="discount">
                            <ValueDiscount type="normal" value={discount}/>
                        </div>
                    </div>
                </div>
                <div className="details-plan">
                    <span>Primeira assinatura: </span>
                    <span>de <span className="old-price">{firstSignatureOldPrice.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</span> por R$ {firstSignatureNewPrice.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} em até {qntPortion}x</span>
                    <span> de {pricePortion.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}, demais parcelas por</span>
                </div>
                <div className="content-price-plan">
                    <h1>Por {pricePlan.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} /mês</h1>
                </div>
                <div className="details-plan">
                    <span>Taxas: {valueRates}% venda no cartão, {percentagePix}% Pix.</span>
                    <span>motoristas ilimitados, exclusividade de layout  por até {qntDays} dias</span>
                </div>
                <div className="content-button" onClick={() => planSelected ? setPlanSelected(planSelected) : setPlanSelected(undefined)}>
                    <button>{planSelected ? 'QUERO ESTE!' : 'MUDAR PLANO!' }</button>
                </div>
            </div>
        </Container>
    )
}