import { CheckCircle, Info } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import IconHelp from "../../../../../components/IconHelp";

import { Container, Select } from "./styles";

export type ItemsListSpeedmob = 'name-app' | 'color-app-driver' | 'color-app-passenger' | 'logo' | 'taxas' | 'rules' | 'final';

interface ListConfigAppProps{
    currentList?: ItemsListSpeedmob;
}

export default function ListConfigApp({ currentList }: ListConfigAppProps){

    const { listConfigChecked } = useListConfigAppContext();

    const navigate = useNavigate();
    
    const ItemsIsChecked = (item: ItemsListSpeedmob) => {
        return listConfigChecked.includes(item);
    }

    return(
        <Container>
            <div className="content-list">
                <Select
                    onClick={() => ItemsIsChecked('name-app') && navigate('/speedmob/configuracoes-do-app/nome-do-app')}
                    isChecked={ItemsIsChecked('name-app')}
                    isSelected={currentList === 'name-app'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Nome do seu aplicativo</h3>
                    </div>

                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('name-app') ? 'fill' : 'regular'} 
                            />  
                        }
                        content="Digite o nome do seu aplicativo e ele aparecerá desde o painel de controle até o download."
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('color-app-driver') && navigate('/speedmob/configuracoes-do-app/cores-aplicativo-motorista')}
                    isChecked={ItemsIsChecked('color-app-driver')}
                    isSelected={currentList === 'color-app-driver'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Aplicativo motorista</h3>
                    </div>

                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('color-app-driver') ? 'fill' : 'regular'}   
                            />  
                        }
                        content="Configure a cores do aplicativo de motorista."
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('color-app-passenger') && navigate('/speedmob/configuracoes-do-app/cores-aplicativo-passageiro')}
                    isChecked={ItemsIsChecked('color-app-passenger')}
                    isSelected={currentList === 'color-app-passenger'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Aplicativo passageiro</h3>
                    </div>

                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('color-app-passenger') ? 'fill' : 'regular'}
                            />  
                        }
                        content="Configure a cores do aplicativo de passageiro."
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('logo') && navigate('/speedmob/configuracoes-do-app/logo-do-app')}
                    isChecked={listConfigChecked.includes('logo')}
                    isSelected={currentList === 'logo'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Logomarca</h3>
                    </div>

                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('logo') ? 'fill' : 'regular'}
                            />  
                        }
                        content="Escolha a logomarca do seu aplicativo."
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('taxas') && navigate('/speedmob/configuracoes-do-app/taxas-do-app')}
                    isChecked={ItemsIsChecked('taxas')}
                    isSelected={currentList === 'taxas'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Taxas do seu aplicativo</h3>    
                    </div>

                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('taxas') ? 'fill' : 'regular'}
                            />  
                        }
                        content="Defina as taxas do seu aplicativo. É nesta aba que você definirá o quanto caro ou barato a corrida pode ficar. Use com sabedoria!"
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('rules') && navigate('/speedmob/configuracoes-do-app/regras-do-app')}
                    isChecked={ItemsIsChecked('rules')}
                    isSelected={currentList === 'rules'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Regras do seu aplicativo</h3>
                    </div>
                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('rules') ? 'fill' : 'regular'}
                            />  
                        }
                        content="Escolha as regras que seu aplicativo poderá ter"
                    />
                </Select>
                <Select
                    onClick={() => ItemsIsChecked('final') && navigate('/speedmob/configuracoes-do-app/configuracao-finalizada')}
                    isChecked={ItemsIsChecked('final')}
                    isSelected={currentList === 'final'}
                >
                    <div>
                        <CheckCircle size={35} />
                        <h3>Configuração finalizada</h3>
                    </div>
                    <IconHelp
                        typeHelp="tooltip"
                        icon={
                            <Info
                                size={32}
                                weight={ItemsIsChecked('final') ? 'fill' : 'regular'}
                            />  
                        }
                        content="Finalize sua configurações!"
                    />
                </Select>
            </div>
        </Container>
    )
}