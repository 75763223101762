import swal from "sweetalert";

import { Container } from "./styles";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginDriver from "../../../../../components/AppsRendered/Driver/Login";
import LoginCredencies from "../../../../../components/AppsRendered/Driver/LoginCredencies";
import RegisterDriver from "../../../../../components/AppsRendered/Driver/Register";

import { useSpeedMobForm, FormActions } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

export default function FormColorDriver() {

    const { state, dispatch } = useSpeedMobForm();

    const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

    const navigate = useNavigate();

    const [colorPrimaryDriver, setColorPrimaryDriver] =
        useState<string>("#A71814");
    const [colorSecondaryDriver, setColorSecondaryDriver] =
        useState<string>("#0A2B4E");
    const [colorTextDriver, setColorTextDriver] = useState<string>("#000000");

    const [stepDriver, setStepDriver] = useState<
        "login" | "loginCredencies" | "register"
    >("login");
    const [stepRenderedDriver, setStepRenderedDriver] = useState<
        React.ReactNode | undefined
    >(undefined);

    const handleCheckColorsSimilars = (
        colorPrimary: string,
        colorSecondary: string
    ) => {
        const colorPrimaryFormated = colorPrimary.substring(1, 7).toUpperCase();
        const colorSecondaryFormated = colorSecondary.substring(1, 7).toUpperCase();

        // get red/green/blue int values of hex1
        let r1 = parseInt(colorPrimaryFormated.substring(0, 2), 16);
        let g1 = parseInt(colorPrimaryFormated.substring(2, 4), 16);
        let b1 = parseInt(colorPrimaryFormated.substring(4, 6), 16);
        // get red/green/blue int values of hex2
        let r2 = parseInt(colorSecondaryFormated.substring(0, 2), 16);
        let g2 = parseInt(colorSecondaryFormated.substring(2, 4), 16);
        let b2 = parseInt(colorSecondaryFormated.substring(4, 6), 16);
        // calculate differences between reds, greens and blues
        let r = 255 - Math.abs(r1 - r2);
        let g = 255 - Math.abs(g1 - g2);
        let b = 255 - Math.abs(b1 - b2);
        // limit differences between 0 and 1
        r /= 255;
        g /= 255;
        b /= 255;
        // 0 means opposite colors, 1 means same colors
        const result = (r + g + b) / 3;

        return result.toFixed(2);
    };

    const handleBackStep = () => {
        navigate('/speedmob/configuracoes-do-app/nome-do-app');
    }

    const handleNextStep = () => {
        /* DRIVER CHECK COLORS */
        const isColorSimilarPrimaryAndSecondaryDriver = handleCheckColorsSimilars(colorPrimaryDriver, colorSecondaryDriver);
        if (Number(isColorSimilarPrimaryAndSecondaryDriver) >= 0.75) {
            swal({
                title: 'Cor primária não pode ser muito semelhante a cor secundária!',
                icon: 'warning'
            });

            return;
        }

        const isColorSimilarPrimaryAndTextDriver = handleCheckColorsSimilars(colorPrimaryDriver, colorTextDriver);
        if (Number(isColorSimilarPrimaryAndTextDriver) >= 0.75) {
            swal({
                title: 'Cor primária não pode ser muito semelhante a cor de texto!',
                icon: 'warning'
            });

            return;
        }

        if (Number(isColorSimilarPrimaryAndSecondaryDriver) >= 0.75 && Number(isColorSimilarPrimaryAndTextDriver) >= 0.75) {
            swal({
                title: 'Cores não podem ser muito semelhantes!',
                icon: 'warning'
            });

            return;
        }

        dispatch({
            type: FormActions.setColorPrimaryDriver,
            payload: colorPrimaryDriver,
        });

        dispatch({
            type: FormActions.setColorSecondaryDriver,
            payload: colorSecondaryDriver,
        });

        dispatch({
            type: FormActions.setColorTextDriver,
            payload: colorTextDriver,
        });

        const cloneState = state;
        cloneState.colorPrimaryDriver = colorPrimaryDriver;
        cloneState.colorSecondaryDriver = colorSecondaryDriver;
        cloneState.colorTextDriver = colorTextDriver;

        localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

        setListConfigChecked([...listConfigChecked, 'color-app-driver']);
        navigate("../cores-aplicativo-passageiro");
        
    };

    useEffect(() => {
        if (stepDriver === "login") {
            setStepRenderedDriver(
                <LoginDriver
                    setStepApplication={setStepDriver}
                    textColor={colorTextDriver}
                    primaryColor={colorPrimaryDriver}
                    secondaryColor={colorSecondaryDriver}
                />
            );
        } else if (stepDriver === "loginCredencies") {
            setStepRenderedDriver(
                <LoginCredencies
                    setStepApplication={setStepDriver}
                    textColor={colorTextDriver}
                    primaryColor={colorPrimaryDriver}
                    secondaryColor={colorSecondaryDriver}
                />
            );
        } else if (stepDriver === "register") {
            setStepRenderedDriver(
                <RegisterDriver
                    setStepApplication={setStepDriver}
                    textColor={colorTextDriver}
                    primaryColor={colorPrimaryDriver}
                    secondaryColor={colorSecondaryDriver}
                />
            );
        }
    }, [stepDriver, colorTextDriver, colorSecondaryDriver, colorPrimaryDriver]);

    useEffect(() => { 
        if (state.nameApp === '') {
            navigate("../../apresentacao");
        }

        if(state.colorPrimaryDriver){
            setColorPrimaryDriver(state.colorPrimaryDriver);
        }

        if(state.colorSecondaryDriver){
            setColorSecondaryDriver(state.colorSecondaryDriver);
        }

        if(state.colorTextDriver){
            setColorTextDriver(state.colorTextDriver);
        }
    }, [state.nameApp, state.colorPrimaryDriver, state.colorSecondaryDriver, state.colorTextDriver, navigate]);

    useEffect(() => {
        if(!listConfigChecked.includes('color-app-driver')){
          setListConfigChecked([...listConfigChecked, 'color-app-driver']);
        }
    }, [listConfigChecked, setListConfigChecked]);
  
    return (
        <ThemeConfigApp moduleTheme="speedmob" isCenter="center" currentList="color-app-driver">
            <Container>
                <div className="content-colors">
                    <h1>Escolha as cores do seu aplicativo para motorista:</h1>

                    <div className="content-input-colors">
                        <div>
                            <label htmlFor="primary-color-driver">
                                Cor primária
                            </label>
                            <input
                                id="primary-color-driver"
                                type="color"
                                defaultValue="#A71814"
                                onChange={(event) => {
                                    setColorPrimaryDriver(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="secondary-color-driver">
                                Cor secundária
                            </label>
                            <input
                                id="secondary-color-driver"
                                type="color"
                                defaultValue="#0A2B4E"
                                onChange={(event) => {
                                        setColorSecondaryDriver(event.target.value);
                                }}
                            />
                        </div>

                        <div>
                            <label htmlFor="color-text-driver"> Cor texto </label>
                            <input
                                id="color-text-driver"
                                type="color"
                                defaultValue="#000000"
                                onChange={(event) => {
                                    setColorTextDriver(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-button-next">
                        <button className="btn-back" onClick={handleBackStep}></button>
                        <button className="btn-next" onClick={handleNextStep}></button>
                    </div>
                </div>
                <div>
                    <div className="content-app">
                        <SimulatorApp>{stepRenderedDriver}</SimulatorApp>
                    </div>
                    
                    <div className="content-button-next-mobile">
                        <button className="btn-back" onClick={handleBackStep}></button>
                        <button className="btn-next" onClick={handleNextStep}></button>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    );
}