import styled from "styled-components";

export const Container = styled.div`
    .content-cards{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 2rem 0;
        gap: 20px;
    }

    .content-cards > div{
        width: 250px;
        text-align: center;
        padding: 2rem;
        border: 1px solid #000000;
        border-radius: 5px;
        cursor: pointer;

        &:hover{
            transition: all ease 0.8s;
            border: 1px solid red;
        }
    }
`