import styled from "styled-components";

interface ContainerProps{
    showDataPersonal: boolean;
    showCreditCard: boolean;
    termsAccepted: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: 2rem;

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    
    .text-merchant{
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
    }

    .title-plans{
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin: 1rem 0;
    }

    .content-types-plans .content-discount{
        display: flex;
        justify-content: center; 
        flex-wrap: wrap;
        gap: 20px;
        margin: 0.5rem 0;
    }

    .content-types-plans .content-type{
        display: flex;
        justify-content: center; 
        gap: 40px;
    }

    .btn-type-plans{
        color: var(--color-primary-blue );
        padding: 6px 12px;
        border-radius: 15px;
        font-weight: bold;
        cursor: pointer;
    }

    .btn-type-plans:nth-last-child(1){
        background-color: var(--color-primary-blue);
        color: var(--color-white-font);
        padding: 6px 12px;
        border-radius: 15px;
        font-weight: bold;
    }

    .content-cards-plan{
        margin: 3rem 0;
        display: flex;
        justify-content: center;
        gap: 2rem;
    }

    @media(max-width: 1200px){
        .content-cards-plan{
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }
    }

    @media(max-width: 768px){
        .text-merchant{
            font-size: 28px;
        }

        .title-plans{
            font-size: 20px;
        }
    }

    @media(max-width: 520px){
        padding: 1rem;

        .content-cards-plan{
            width: 100%;
            flex-direction: row;
            overflow: auto;
            justify-content: start;
            gap: 1rem;
        }

        .text-merchant{
            font-size: 23px;
        }

        .title-plans{
            font-size: 18px;
        }
    }

    /* DETAILS PAYMENT */

    .content-types-forms{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 3rem;
    }

    .content-types-forms .type-datas-personal{
        display: flex;
        align-items: center;
        gap: 1rem;
        background: ${props => props.showDataPersonal ? `linear-gradient(90deg, ${props.theme.color.colorTextPrimary} 0%, ${props.theme.color.colorButtonSecondaryHover} 0%, ${props.theme.color.colorButtonsSecondary} 25%)` : 'none'};
        color: ${props => props.showDataPersonal ? props.theme.color.backgroundPrimary : props.theme.color.colorButtonsSecondary};
        padding: 0.8rem;
        border-radius: 5px;
        cursor: pointer;

        p{
            color: ${props => props.showDataPersonal ? props.theme.color.backgroundPrimary : props.theme.color.colorTextPrimary};
        }
    }

    .content-types-forms .type-datas-payment{
        display: flex;
        align-items: center;
        padding: 0.8rem;
        border-radius: 5px;
        cursor: pointer;
        gap: 1rem;
        opacity: ${props => !props.showDataPersonal ? 1 : 0.5};
        background: ${props => !props.showDataPersonal ? `linear-gradient(90deg, ${props.theme.color.colorTextPrimary} 0%, ${props.theme.color.colorButtonSecondaryHover} 0%, ${props.theme.color.colorButtonsSecondary} 25%)` : 'none'};
        color: ${props => !props.showDataPersonal ? props.theme.color.backgroundPrimary  : props.theme.color.colorButtonsSecondary};

        p{
            color: ${props => !props.showDataPersonal ? props.theme.color.backgroundPrimary : props.theme.color.colorTextPrimary }
        }
    }

    .content-details-payments{
        display: flex;
        justify-content: space-around;
        flex-direction: row-reverse;
    }

    .content-select-form-payments{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .content-select-form-payments .form-payment-card{
        flex: 0.5;
        padding: 1rem;
        background: ${props => props.showCreditCard ? `linear-gradient(90deg, ${props.theme.color.colorTextPrimary} 0%, ${props.theme.color.colorButtonSecondaryHover} 0%, ${props.theme.color.colorButtonsSecondary} 25%)` : props.theme.color.backgroundPrimary};
        color: ${props => props.showCreditCard ?  props.theme.color.backgroundPrimary : props.theme.color.colorTextPrimary};
        text-align: center;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
        
        border-left: 1px solid ${props => props.theme.color.colorTextPrimary};
        border-top: 1px solid ${props => props.theme.color.colorTextPrimary};
        border-bottom: 1px solid ${props => props.theme.color.colorTextPrimary};

        &:hover{
            transition: 0.2s;
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .content-select-form-payments .form-payment-pix{
        flex: 0.5;
        padding: 1rem;
        background: ${props => !props.showCreditCard ? `linear-gradient(90deg, ${props.theme.color.colorTextPrimary} 0%, ${props.theme.color.colorButtonSecondaryHover} 0%, ${props.theme.color.colorButtonsSecondary} 25%)` : props.theme.color.backgroundPrimary};
        color: ${props => !props.showCreditCard ? props.theme.color.backgroundPrimary : props.theme.color.colorTextPrimary};
        text-align: center;
        border-radius: 0 5px 5px 0;
        cursor: pointer;

        border-right: 1px solid ${props => props.theme.color.colorTextPrimary};
        border-top: 1px solid ${props => props.theme.color.colorTextPrimary};
        border-bottom: 1px solid ${props => props.theme.color.colorTextPrimary};

        &:hover{
            transition: 0.2s;
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .details-payments{
        width: 100%;
        padding: 0 4rem;
    }

    .details-payments > span{
        color: ${props => props.theme.color.colorTextSecondary};
        display: block;
        margin: 0.5rem 0;
        font-size: 16px;
        font-weight: bold;
    }

    .details-payments > span:nth-child(2){
        color: var(--color-primary-blue);
    }

    .content-details-types-payments{
        width: 100%;
        margin: 2rem 0;
    }

    .content-pix{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .content-description-pix{
        > p{
            margin-bottom: 2rem;
        }
    }

    .content-description-pix .content-list-pix > div{
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        p{
            margin-left: 0.5rem;
        }
    }

    .content-details-pix{
        display: flex;
        flex-direction: column;

        img{
            width: 230px;
        }
    }

    .content-form .content-two-inputs{
        display: flex;
        gap: 10px;
    }

    .content-form > div {
        margin: 1rem 0;
        position: relative;
    }

    .content-form .brand-card {
        width: auto;
        position: absolute;
        top: 0px;
        right: 8px;
        img{
            max-width: 75px;
            height: 50px;
        }
    }   

    .content-form > div {
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
            color: ${props => props.theme.color.colorTextPrimary};
        }

        label {
            color: ${props => props.theme.color.colorTextPrimary};
        }
        input{
            color: ${props => props.theme.color.colorTextPrimary};
        }
    }

    .content-code-security{
        display: flex;
        align-items: center;
        gap: 10px;;
        img{
            width: 50px;
        }
    }
    
    .content-button-next{
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;
        gap: 20px;

        .next-button{
            width: 255px;
            height: 40px;
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 8px 40px;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 1px;
            font-size: 20px;
            opacity: ${props => props.termsAccepted ? 1 : 0.5};

            ::after{
                content: '>'
            }
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
                ::after{
                    content: 'AVANÇAR'
                }
            }
        }

        .back-button{
            background: none;
            width: 255px;
            height: 40px;
            padding: 8px 40px;
            font-size: 20px;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};

            ::after{
                content: '<'
            }

            &:hover{
                ::after{
                    content: 'VOLTAR'
                }
            }
        }
    }

    @media(max-width: 1000px){
        .content-details-payments{
            flex-direction: column;
            align-items: center;
        }

        .content-select-form-payments .form-payment-card{
            font-size: 12px;
        }

        .content-select-form-payments .form-payment-pix{
            font-size: 12px;
        }
        
        .content-plan-selected {
            width: 100%;
            border-right: none;
            padding-bottom: 3.5rem;
            padding-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    @media(max-width: 600px){
        .details-payments{
            padding: 0 0rem;
        }

        .content-form .content-two-inputs{
            flex-direction: column;
        }

        .content-plan-selected {
            display: none;
        }

        .content-types-forms .type-datas-personal{
            border-radius: 360px;
            border: 1px solid gray;
        }

        .line::after{
            content: "━━━━━";
            font-weight: bold;
            color: ${props => props.theme.color.colorButtonsSecondary};
        }

        .content-types-forms .type-datas-payment{
            border-radius: 360px;
            border: 1px solid gray;
        }
        
        .content-types-forms .type-datas-personal div:nth-child(2){
            display: none;
        }

        .content-types-forms .type-datas-payment div:nth-child(2){
            display: none;
        }

        .content-details-types-payments{
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin: 2rem 0;
        }

        .content-details-card{
            width: 100%;
        }

        .content-details-pix{
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        /* DETAILS PAYMENTS */
        .content-form > div {
            div{
                width: 100%;
            }
        }

        .content-button-next{
            justify-content: center;
            flex-direction: column-reverse;
            align-items: center;
        }

        .content-pix{
            flex-direction: column;
        }
    }
`