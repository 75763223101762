import { useNavigate } from "react-router-dom";

import { Container } from "./styles"
import { useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useEffect } from "react";

export default function FormPlanCity(){

    const { state } = useSpeedMobForm();

    const navigate = useNavigate();

    const handleBackStep = () => {
        navigate('../selecao-cidade');
    }
    
    const handleNextStep = () => {
        navigate('/speedmob/selecao-de-planos');
    }

    useEffect(() => {        
        if(state.cityName === null){
            navigate('../apresentacao');
        }
    }, [state.cityName, navigate])

    return(
        <Container>
            <div className="content-merchant-city">
                <span className="title-city">Cidade:</span>
                <span className="title-city">FORTALEZA - CE</span>

                <p className="text-page">
                    Uma ótima opção, esta cidade é considerada de tamanho 1A, com uma 
                    estimativa de 22.516 habitantes. Este tipo de cidade é muito boa para divulgação, 
                    com gastos reduzidos e abrangência rápida.
                </p>
                
                <p className="text-page">
                    A Cidade tem uma estimativa de 3.000 clientes e recomendamos que haja até 30 
                    motoristas, para que todos tenham ganhos satisfatórios.
                </p>
            </div>

            <div className="content-merchant-plan">
                <h1>Investimento</h1>
                <span className="title-plan-city">Plano para esta cidade:</span>

                <div className="content-prices-plan">
                    <div className="plans">
                        <span className="title">Ativação(única vez):</span>
                        <span className="old-price">De: R$ 499,99</span>
                        <span className="new-price">Por: R$ 0,00*</span>
                    </div>

                    <div className="plans">
                        <span className="title">Ativação(mensal):</span>
                        <span className="old-price">De: R$ 499,99</span>
                        <span className="new-price">Por: R$ 249,00*</span>
                    </div>
                    <small style={{ textAlign: 'center', display: 'block' }}>Descontos para planos anuais</small>
                </div>

                <div className="content-button-next">
                    <button className="btn-next" onClick={handleNextStep}></button>
                    <button className="btn-back" onClick={handleBackStep}></button>
                </div>
            </div>

        </Container>
    )
}