import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Question } from "phosphor-react";
import swal from "sweetalert";

import { Container } from "./styles";
import InputCurrency from "../../../../../components/InputCurrency";
import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import BackdropCircularLoading from "../../../../../components/BackdropCircularLoading";
import Select2TextField from "../../../../../components/Select2TextField";
import IconHelp from "../../../../../components/IconHelp";

import { useEffect, useState } from "react";
import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

interface DistanceBetweenPointsProps {
    country_crossed: boolean;
    distance: number;
    duration: number;
    duration_typical?: number;
    geometry: {
        coordinates: any;
        type: string;
    };
    legs: any;
    weight: number;
    weight_name: string;
}

interface ContextsCitiesProps {
    id: string;
    text: string;
}

export interface ListCitiesProps {
    address: string;
    center: any;
    context: ContextsCitiesProps[];
    geometry: {
        coordinates: any;
        interpolated: boolean;
        type: string;
    };
    id: string;
    place_name: string;
    place_type: any;
    properties: {
        accuracy: string;
    };
    relevance: number;
    text: string;
    type: string;
}

export default function FormTaxas() {

    const [valueInitial, setValueInitial] = useState<string>('');
    const [valueKm, setValueKm] = useState<string>('');
    const [valueMinute, setValueMinute] = useState<string>('');
    const [addressOrigin, setAddressOrigin] = useState<string>('');
    const [addressDestiny, setAddressDestiny] = useState<string>('');
    const [listCitiesOrigin, setListCitiesOrigin] = useState<ListCitiesProps>();
    const [listCitiesDestiny, setListCitiesDestiny] = useState<ListCitiesProps>();

    const [centerOrigin, setCenterOrigin] = useState<[number, number] | null>(null);
    const [centerDestiny, setCenterDestiny] = useState<[number, number] | null>(null);

    const [typeTraffic, setTypeTraffic] = useState<string>('');

    const [distanceBetweenTwoPoints, setDistanceBetweenTwoPoints] = useState<DistanceBetweenPointsProps>();
    const [valueRate, setValueRate] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const { state, dispatch } = useSpeedMobForm();
    const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

    const token = 'pk.eyJ1IjoidGVzdGViYXlhbm1lZCIsImEiOiJjazd1d2U4OTMxNW9hM2lvNGI3cGFyaGVnIn0.Q4bC04l_8IU6HqONKXbcDA';

    const navigate = useNavigate();

    const handleChangeTraffic = (event: SelectChangeEvent) => {
        setTypeTraffic(event.target.value);
    }

    const handleGetDatasCity = async (nameCity: string) => {
        const responseCities = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(nameCity)}.json?access_token=${token}`);
        return responseCities;
    }

    const handleCalculateDistance = async () => {
        if(Number(valueInitial.replace(',', '.')) > 300 || Number(valueKm.replace(',', '.')) > 300 || Number(valueMinute.replace(',', '.')) > 300){
            swal({
                title: 'Valor de tarifas alto demais!',
                icon: 'warning',
            });

            return;
        }

        if (!valueInitial || !valueKm || !valueMinute) {
            swal({
                title: 'Monte a sua tarifa',
                icon: 'warning',
            });

            return;
        }

        if (!centerOrigin) {
            swal({
                title: 'Escolha a cidade de origem',
                icon: 'warning',
            });

            return;
        }

        if (!centerDestiny) {
            swal({
                title: 'Escolha o cidade de destino',
                icon: 'warning',
            });

            return;
        }

        if (!typeTraffic) {
            swal({
                title: 'Escolha o tipo de tráfego',
                icon: 'warning',
            });

            return;
        }

        setLoading(true);
        try {
            if (centerOrigin && centerDestiny) {
                const response = await axios.get(`https://api.mapbox.com/directions/v5/mapbox/${typeTraffic}/${centerOrigin[0]},${centerOrigin[1]};${centerDestiny[0]},${centerDestiny[1]}?steps=true&geometries=geojson&access_token=${token}`);
                setDistanceBetweenTwoPoints(response.data.routes[0]);
            }

        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleBackStep = () => {
        navigate('/speedmob/configuracoes-do-app/logo-do-app');
    }

    const handleNextStep = () => {
        if (valueInitial !== '' && valueKm !== '' && valueMinute !== '' && addressOrigin !== '' && addressDestiny !== '') {
            dispatch({
                type: FormActions.setValueStart,
                payload: valueInitial
            })

            dispatch({
                type: FormActions.setValueKm,
                payload: valueKm
            })

            dispatch({
                type: FormActions.setValueMinute,
                payload: valueMinute
            })

            dispatch({
                type: FormActions.setAddressOrigin,
                payload: addressOrigin
            })

            dispatch({
                type: FormActions.setAddressDestiny,
                payload: addressDestiny
            })

            const cloneState = state;
            cloneState.valueStartSimulator = valueInitial;
            cloneState.valueKmSimulator = valueKm;
            cloneState.valueMinuteSimulator= valueMinute;
            cloneState.addressOriginSimulator = addressOrigin;
            cloneState.addressDestinySimulator =addressDestiny;

            localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

            navigate('../regras-do-app');
        } else {
            swal({
                title: 'Preencha os campos!',
                icon: 'info'
            })
        }
    }

    useEffect(() => {
        if (!state.urlImageLogoClient || !state.urlImageLogoDriver) {
            navigate('/speedmob/apresentacao');
        }
    }, [state.urlImageLogoClient, state.urlImageLogoDriver, navigate])

    useEffect(() => {
      if(!listConfigChecked.includes('taxas')){
        setListConfigChecked([...listConfigChecked, 'taxas']);
      }
    }, [listConfigChecked, setListConfigChecked]);

    useEffect(() => {
        const datasCitiesOrigin = handleGetDatasCity(addressOrigin);

        datasCitiesOrigin.then((response) => {
            setListCitiesOrigin(response.data.features);
        })
            .catch((error) => {
                console.log(error);
            })
    }, [addressOrigin]);

    useEffect(() => {
        const datasCitiesDestiny = handleGetDatasCity(addressDestiny);

        datasCitiesDestiny.then((response) => {
            setListCitiesDestiny(response.data.features);
        })
            .catch((error) => {
                console.log(error);
            })
    }, [addressDestiny]);

    useEffect(() => {
        if (state.valueStartSimulator) {
            setValueInitial(state.valueStartSimulator);
        }
        if (state.valueKmSimulator) {
            setValueKm(state.valueKmSimulator);
        }
        if (state.valueMinuteSimulator) {
            setValueMinute(state.valueMinuteSimulator);
        }
        if (state.addressOriginSimulator) {
            setAddressOrigin(state.addressOriginSimulator);
        }
        if (state.addressDestinySimulator) {
            setAddressDestiny(state.addressDestinySimulator);
        }
    }, [state.valueStartSimulator, state.valueKmSimulator, state.valueMinuteSimulator, state.addressOriginSimulator, state.addressDestinySimulator]);

    useEffect(() => {
        if (distanceBetweenTwoPoints) {

            const numberKmFormated = Number(valueKm.replace(',', '.'));
            const numberInitialFormated = Number(valueInitial.replace(',', '.'));
            const numberMinutesFormtaded = Number(valueMinute.replace(',', '.'));

            if (distanceBetweenTwoPoints) {
                const distanceKm = distanceBetweenTwoPoints.distance / 1000;
                const timeMinutes = distanceBetweenTwoPoints.duration / 60;

                const calc_per_distance = Number(distanceKm.toFixed(1)) * numberKmFormated;
                const calc_per_minutes = Number(timeMinutes.toFixed(0)) * numberMinutesFormtaded;

                setValueRate(calc_per_distance + numberInitialFormated + calc_per_minutes);
            }
        }

    }, [distanceBetweenTwoPoints, valueInitial, valueKm, valueMinute]);

    return (
        <ThemeConfigApp moduleTheme="speedmob" isCenter="center" currentList="taxas">
            <Container>
                <BackdropCircularLoading
                    isOpen={loading}
                />
                <div className="content-taxas">
                    <div className="content-settings">
                        <div className="header-settings">
                            <h1>Defina as tarifas do seu aplicativo:</h1>
                            <IconHelp
                                icon={<Question />}
                                typeHelp="popover"
                                urlVideo="https://www.youtube.com/embed/8SbUC-UaAxE"
                            />
                        </div>
                        <div className="content-settings-taxas">
                            <div className="content-inputs-tarifa">
                                <InputCurrency
                                    id="value-intial"
                                    label="Valor Inicial"
                                    value={state.valueStartSimulator ? state.valueStartSimulator : valueInitial}
                                    setValue={setValueInitial}
                                />

                                <InputCurrency
                                    id="value-km"
                                    label="Valor Km"
                                    value={state.valueKmSimulator ? state.valueKmSimulator : valueKm}
                                    setValue={setValueKm}
                                />

                                <InputCurrency
                                    id="value-minute"
                                    label="Valor Minuto"
                                    value={valueMinute}
                                    setValue={setValueMinute}
                                />
                            </div>
                            <span>Simulação de uma corrida:</span>
                            <div className="content-inputs-address">
                                <Select2TextField
                                    value={addressOrigin}
                                    setValue={setAddressOrigin}
                                    setValueSelect={setCenterOrigin}
                                    id="address-origin-autocomplete"
                                    label="Endereço de origem"
                                    listOptions={listCitiesOrigin}
                                />

                                <Select2TextField
                                    value={addressDestiny}
                                    setValue={setAddressDestiny}
                                    setValueSelect={setCenterDestiny}
                                    id="address-destiny-autocomplete"
                                    label="Endereço de destino"
                                    listOptions={listCitiesDestiny}
                                />

                                <div>
                                    <FormControl sx={{ width: 235 }}>
                                        <InputLabel id="select-label-type-traffic">Tipo de simulação</InputLabel>
                                        <Select
                                            labelId="select-label-type-traffic"
                                            id="select-label-type-traffic"
                                            value={typeTraffic}
                                            label="type-simulation-traffic"
                                            onChange={handleChangeTraffic}
                                            sx={{mb: 2}} 
                                        >
                                            <MenuItem value='driving-traffic'>Com tráfego</MenuItem>
                                            <MenuItem value='driving'>Sem tráfego</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="content-price-simulate">
                                <div>
                                    <h1>{valueRate.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h1>
                                    <span>Valor cobrado ao cliente</span>
                                </div>

                                <div className="content-button-calculate">
                                    <button onClick={handleCalculateDistance}>Calcular valor</button>
                                </div>
                            </div>

                            <div className="content-button-next">
                                <button className="btn-back" onClick={handleBackStep}></button>
                                <button className="btn-next" onClick={handleNextStep}></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    )
}