import styled from "styled-components";

export const Container = styled.div`
    width: 215px;
    height: 385px;
    position: absolute;
    top: 14%;
    left: 30px;

    .content-login-credencies{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .content-login-credencies .header{
        width: 100%;
        height: 50px;
        padding: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content-login-credencies .header .btn-back button{
        background: none;
        border: none;
        cursor: pointer;
    }

    .content-login-credencies .header .logo img{
        width: 40px;
        height: 40px;
    }

    .content-login-credencies .content-form{
        padding: 0 0.5rem;
    }

    .content-login-credencies .content-form input{
        width: 100%;
        padding: 0.7rem;
        margin: 0.5rem 0;
        border-radius: 5px;
        border: none;
    }

    .content-login-credencies .content-form span{
        width: 100%;
        display: block;
        text-align: center;
        text-decoration: underline;
        font-size: 13px;
        cursor: pointer;
    }

    .content-login-credencies .content-form .content-button{
        text-align: center;
        margin: 3rem 0;
    }

    .content-login-credencies .content-form .content-button button{
        background: none;
        font-size: 25px;
        text-align: center;
        padding: 0.5rem 3rem;
        border: 1px solid;
        border-radius: 8px;
        cursor: pointer;
    }

`