import { Container } from "./styles";
import Lottie from "lottie-react";

export default function FormCompleted() {
  return (
    <Container>
      <div className="content">
        <h1>Este formulário já foi preenchido</h1>
        <Lottie
          animationData={require("../../../../../assets/animations/form_completed.json")}
          loop={true}
          style={{
            width: 500,
            height: 500,
          }}
        />
      </div>
    </Container>
  );
}
