import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 2rem;

  .content-datas {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 3rem;
    margin: 2rem 0;
  }

  .content-simulator-devices {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin: 2rem 0;
  }

  .content-config-general-app {
    width: 500px;
    padding: 2rem;
    background-color: ${(props) => props.theme.color.backgroundPrimary};

    .title-config-general {
      font-size: 20px;
      margin-bottom: 2rem;
    }
  }

  .btn-config-general {
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.color.colorTextPrimary} 0%,
      ${(props) => props.theme.color.colorButtonSecondaryHover} 0%,
      ${(props) => props.theme.color.colorButtonsSecondary} 25%
    );
    padding: 1rem 3rem;
    color: ${(props) => props.theme.color.backgroundPrimary};
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 20px;

    &:hover {
      transition: all ease 0.3s;
      background-color: ${(props) =>
        props.theme.color.colorButtonSecondaryHover};
    }
  }

  .content-btns-confirm-and-fix {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .content-items {
    p {
      font-size: 13px;
      border-bottom: 1px solid #00000070;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      text-align: left;

      > span {
        font-weight: bold;
      }

      > input {
        width: 25px;
        height: 28px;
        border-radius: 360px;
        cursor: pointer;
        border: none;
        background: none;

        ::-webkit-color-swatch {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border: 1px solid #000000;
        }
      }
    }
  }

  .content-informations-apps {
    > div {
      margin-bottom: 2rem;

      h3 {
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 1052px) {
    .content-datas {
      flex-direction: column;
    }

    .content-config-general-app {
      width: 100%;
      margin: 0 2rem;
    }
  }

  @media (max-width: 768px) {
    .content-simulator-devices {
      flex-direction: column;
      gap: 3.5rem;
    }
  }

  @media (max-width: 490px) {
    > h1 {
      font-size: 24px;
    }
  }
`;
