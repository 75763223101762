import styled from "styled-components";

export const Container = styled.div`
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .content-merchant{
        flex: 1;
    }

    .text-merchant{
        font-size: 45px;
        line-height: 3rem;
        font-weight: bold;

        span{
            color: ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .subtext-merchant{
        font-size: 22px;
        margin-top: 1.8rem;
    }

    .content-video{
        display: flex;
        justify-content: center;
        cursor: pointer;

        img{
            width: 650px;
        }
    }

    .content-button-next{
        margin: 25px 0;
    }

    button {
        background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
        padding: 8px 40px;
        color: ${props => props.theme.color.backgroundPrimary};
        cursor: pointer;
        border: none;
        border-radius: 10px;
        font-size: 20px;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonSecondaryHover};
        }
    }

    @media(max-width: 1200px){
        flex-direction: column;

        .content-merchant{
            text-align: center;
        }
    }

    @media(max-width: 768px){
        .text-merchant{
            font-size: 35px;
        }

        .subtext-merchant{
            font-size: 18px;
        }

        .content-video{
            img{
                width: 425px;
            }
        }
    }

    @media(max-width: 425px){
        .text-merchant{
            font-size: 25px;
            line-height: 2rem;
        }

        .subtext-merchant{
            font-size: 14px;
            margin-top: 1rem;
        }

        .content-video{
            img{
                width: 250px;
            }
        }

        button {
            font-size: 12px;
        }
    }
`