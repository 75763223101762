import styled from "styled-components";

export const Container = styled.a`
    width: 35px;
    height: 35px;
    border-radius: 360px;
    padding: 1rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 20px;
    }
    background-color: ${props => props.theme.color.colorButtonsSecondary};
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.75);

    transform: scale(0.9);

    &:hover{
        transition: all ease 0.3s;
        transform: scale(1);
    }

    @media(max-width: 425px){
        width: 50px;
        height: 50px;

        padding: 1rem 0.8rem;

        img{
            width: 20px;
        }
    }
`