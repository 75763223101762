import {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  useState,
} from "react";

interface FormsMobilityProContextProps {
  state: State;
  dispatch: (action: Action) => void;
  urlLogoDriver: string;
  setUrlLogoDriver: (data: string) => void;
  urlLogoCustomer: string;
  setUrlLogoCustomer: (data: string) => void;
  loading: boolean;
  setLoading: (data: boolean) => void;
  datasLocalStorage: void;
  setDatasLocalStorage: (datas: void) => void;
}

interface FormsMobilityProContextProviderProps {
  children: ReactNode;
}

interface Action {
  type: FormActions;
  payload: any;
}

interface CitiesProps {
  id: number;
  name: string;
}

interface ListCitiesProps {
  cities_id: string;
  cities_estate_id: string;
  listCities: CitiesProps[];
  nameCity: string;
}

export enum FormActions {
  setName,
  setDocument,
  setEmail,
  setPhone,
  setWhatsApp,
  setProjectName,
  setListSelect,
  setCitiesId,
  setCitiesName,
  setCitiesEstateId,
  setCustomerApp,
  setCustomerAppMainColor,
  setCustomerAppSecondaryColor,
  setCustomerAppTextColor,
  setCustomerAppLogo,
  setDriverApp,
  setDriverAppMainColor,
  setDriverAppSecondaryColor,
  setDriverAppTextColor,
  setDriverAppLogo,
  setResetValue,
}

export interface State {
  name: string;
  document: string;
  email: string;
  phone: string;
  whatsapp: string;
  project_name: string;
  list_select: ListCitiesProps[];
  customer_app: string;
  customer_app_main_color: string;
  customer_app_secondary_color: string;
  customer_app_text_color: string;
  customer_app_logo: any;
  driver_app: string;
  driver_app_main_color: string;
  driver_app_secondary_color: string;
  driver_app_text_color: string;
  driver_app_logo: any;
}

export const initialData: State = {
  name: "",
  document: "",
  email: "",
  phone: "",
  whatsapp: "",
  project_name: "",
  list_select: [
    {
      cities_id: "",
      cities_estate_id: "",
      listCities: [],
      nameCity: "",
    },
  ],
  customer_app: "",
  customer_app_main_color: "",
  customer_app_secondary_color: "",
  customer_app_text_color: "",
  customer_app_logo: "",
  driver_app: "",
  driver_app_main_color: "",
  driver_app_secondary_color: "",
  driver_app_text_color: "",
  driver_app_logo: "",
};

const FormsMobilityProContext = createContext<
  FormsMobilityProContextProps | undefined
>(undefined);

const formStateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case FormActions.setName:
      return { ...state, name: action.payload };
    case FormActions.setDocument:
      return { ...state, document: action.payload };
    case FormActions.setEmail:
      return { ...state, email: action.payload };
    case FormActions.setPhone:
      return { ...state, phone: action.payload };
    case FormActions.setWhatsApp:
      return { ...state, whatsapp: action.payload };
    case FormActions.setProjectName:
      return { ...state, project_name: action.payload };
    case FormActions.setListSelect:
      return { ...state, list_select: action.payload };
    case FormActions.setCitiesId:
      return { ...state, cities_id: action.payload };
    case FormActions.setCitiesName:
      return { ...state, cities_name: action.payload };
    case FormActions.setCitiesEstateId:
      return { ...state, cities_estate_id: action.payload };
    case FormActions.setCustomerApp:
      return { ...state, customer_app: action.payload };
    case FormActions.setCustomerAppMainColor:
      return { ...state, customer_app_main_color: action.payload };
    case FormActions.setCustomerAppSecondaryColor:
      return { ...state, customer_app_secondary_color: action.payload };
    case FormActions.setCustomerAppTextColor:
      return { ...state, customer_app_text_color: action.payload };
    case FormActions.setCustomerAppLogo:
      return { ...state, customer_app_logo: action.payload };
    case FormActions.setDriverApp:
      return { ...state, driver_app: action.payload };
    case FormActions.setDriverAppMainColor:
      return { ...state, driver_app_main_color: action.payload };
    case FormActions.setDriverAppSecondaryColor:
      return { ...state, driver_app_secondary_color: action.payload };
    case FormActions.setDriverAppTextColor:
      return { ...state, driver_app_text_color: action.payload };
    case FormActions.setDriverAppLogo:
      return { ...state, driver_app_logo: action.payload };
    case FormActions.setResetValue:
      return action.payload;
    default:
      return state;
  }
};

export const FormsMobilityProContextProvider = ({
  children,
}: FormsMobilityProContextProviderProps) => {
  const [state, dispatch] = useReducer(formStateReducer, initialData);
  const [urlLogoDriver, setUrlLogoDriver] = useState<string>("");
  const [urlLogoCustomer, setUrlLogoCustomer] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [datasLocalStorage, setDatasLocalStorage] = useState<any>();

  const value = {
    state,
    dispatch,
    urlLogoCustomer,
    setUrlLogoCustomer,
    urlLogoDriver,
    setUrlLogoDriver,
    loading,
    setLoading,
    datasLocalStorage,
    setDatasLocalStorage,
  };

  return (
    <FormsMobilityProContext.Provider value={value}>
      {children}
    </FormsMobilityProContext.Provider>
  );
};

export const UseFormsMobilityPro = () => {
  const context = useContext(FormsMobilityProContext);

  if (context === undefined) {
    throw new Error(
      "UseFormsMobilityPro precisa ser usado dentro do FormsMobilityProContextProvider"
    );
  }

  return context;
};
