import styled from "styled-components";

export const Container = styled.button`
    padding: 1rem;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    background: none;

    img{
        width: 50px !important;
    }

    &:hover{
        transition: all ease 0.8s;
        border: 1px solid red;
    }
`