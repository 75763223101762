import { Container } from "./styles"

interface ValueDiscountProps{
    value: number;
    type: 'normal' | 'reverse';
    text?: string;
}

export default function ValueDiscount({ value, type, text }: ValueDiscountProps){
    return(
        <Container backgroundColor={type} color={type}>
            <div className="content-discount-value">
                {text && <span>{text}: </span>}
                {value}% desc.
            </div>
        </Container>
    )
}