import styled from "styled-components";

export const Container = styled.div`
    .content-cards{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
    }

    @media(max-width: 768px){
        height: 300px;
        overflow: auto;
    }
`