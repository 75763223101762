import React, { createContext, useReducer, useContext } from "react";

interface FormsSpeedContextProps{
    state: State;
    dispatch: (action: Action) => void;
}

interface FormsSpeedContextProviderProps{
    children: React.ReactNode
}

interface Action{
    type: FormActions;
    payload: any;
}
export enum FormActions{
    setCpfCnpjClient,
    setNicknameClient,
    setPhoneClient,
    setFullNameClient,
    setEmailClient,
    setCepClient,
    setGenderClient,
    setNumberAddressClient,
    setDistrictClient,
    setStateClient,
    setAddressClient,
    setComplementClient,
    setCityClient,
    setStateId,
    setCityId,
    setCityName,
    setNumberCard,
    setTypeCard,
    setDueDate,
    setCodeSecurity,
    setAddressCharge,
    setNameApp,
    setColorPrimaryClient,
    setColorSecondaryClient,
    setColorPrimaryDriver,
    setColorSecondaryDriver,
    setColorTextDriver,
    setColorTextClient,
    setImageLogoClientSmall,
    setImageLogoClientMedium,
    setImageLogoClientBig,
    setUrlImageLogoClient,
    setUrlImageLogoDriver,
    setIsLogoGenerated,
    setValueStart,
    setValueKm,
    setValueMinute,
    setAddressOrigin,
    setAddressDestiny,
    setCancellationFeeValue,
    setCancellationFeeMinutes,
    setDriverPerTripValueFixe, 
    setDriverPerTripPerWeekend,
    setTypePaymentDriver,
    setPermissionAllowCallWaiting,
    setAllowStops,
    setActiveDynamics,
    setChargeExtraValueFixe,
    setChargeExtraValuePerTrip,
    setReceivedValuesPerMoney,
    setReceivedValuesPerPix,
    setReceivedValuesCreditCard,
    setAccountReceivedValues,
    setResetValue,
}

interface State{
    cpfCnpjClient: string | null;
    nicknameClient: string | null;
    phoneClient: string | null;
    fullNameClient: string | null;
    emailClient: string | null;
    genderClient: string | null;
    cepClient: string | null;
    numberAddressClient: number | null;
    districtClient: string | null;
    stateClient: string | null;
    addressClient: string | null;
    complementClient: string | null;
    cityClient: string | null;
    stateId: string;
    cityId: string;
    cityName: string;
    numberCard: string | null;
    typeCard: string | undefined;
    dueDateCard: Date | null;
    codeSecurityCard: string | null;
    addresCharge: string | null;
    nameApp: string;
    colorPrimaryClient: string;
    colorSecondaryClient: string;
    colorPrimaryDriver: string;
    colorSecondaryDriver: string;
    colorTextDriver: string;
    colorTextClient: string;
    imageLogoClientSmall: string;
    imageLogoClientMedium: string;
    imageLogoClientBig: string;
    urlImageLogoClient: string;
    urlImageLogoDriver: string;
    isLogoGenerated: 'logoGenerated' | 'logoReady' | null;
    valueStartSimulator: string | null;
    valueKmSimulator: string | null;
    valueMinuteSimulator: string | null;
    addressOriginSimulator: string | null;
    addressDestinySimulator: string | null;
    cancellationFeeValue: string | null;
    cancellationFeeMinutes: string | null;
    driverPerTripValueFixe: string | null;
    driverPerTriPerWeekend: string | null;
    typePaymentDriver: string | null;
    permissionAllowCallWaiting: boolean;
    allowStops: boolean;
    activeDynamics: boolean;
    chargeExtraValueFixe: string | null;
    chargeExtraValuePerTrip: string | null;
    receivedValuesPerMoney: boolean;
    receivedValuesPerPix: boolean;
    receivedValuesCreditCard: boolean;
    accountReceivedValues: string | null;
}


export const initialData = {
    cpfCnpjClient: null,
    nicknameClient: null,
    phoneClient: null,
    fullNameClient: null,
    emailClient: null,
    genderClient: null,
    cepClient: null,
    numberAddressClient: null,
    districtClient: null,
    stateClient: null,
    addressClient: null,
    complementClient: null,
    cityClient: null,
    stateId: '',
    cityId: '',
    cityName: '',
    numberCard: null,
    typeCard: undefined,
    dueDateCard: null,
    codeSecurityCard: null,
    addresCharge: null,
    nameApp: '',
    colorPrimaryClient: '#A71814',
    colorSecondaryClient: '#0A2B4E',
    colorPrimaryDriver: '#A71814',
    colorSecondaryDriver: '#0A2B4E',
    colorTextDriver: '#000000',
    colorTextClient: '#000000',
    imageLogoClientSmall: '',
    imageLogoClientMedium: '',
    imageLogoClientBig: '',
    urlImageLogoClient: '',
    urlImageLogoDriver: '',
    isLogoGenerated: null,
    valueStartSimulator: null,
    valueKmSimulator: null,
    valueMinuteSimulator: null,
    addressOriginSimulator: null,
    addressDestinySimulator: null,
    cancellationFeeValue: null,
    cancellationFeeMinutes: null,
    driverPerTripValueFixe: null,
    driverPerTriPerWeekend: null,
    typePaymentDriver: '',
    permissionAllowCallWaiting: false,
    allowStops: false,
    activeDynamics: false,
    chargeExtraValueFixe: null,
    chargeExtraValuePerTrip: null,
    receivedValuesPerMoney: false,
    receivedValuesPerPix: false,
    receivedValuesCreditCard: false,
    accountReceivedValues: null,
}

export const FormsSpeedContext = createContext<FormsSpeedContextProps | undefined>(undefined);


const formStateReducer = (state: State, action: Action) => {
    switch(action.type){
        case FormActions.setCpfCnpjClient:
            return {...state, cpfCnpjClient: action.payload};
        case FormActions.setNicknameClient:
            return {...state, nicknameClient: action.payload};
        case FormActions.setCepClient:
            return {...state, cepClient: action.payload};
        case FormActions.setPhoneClient:
            return {...state, phoneClient: action.payload};
        case FormActions.setFullNameClient:
            return {...state, fullNameClient: action.payload};
        case FormActions.setEmailClient:
            return {...state, emailClient: action.payload};
        case FormActions.setGenderClient:
            return {...state, genderClient: action.payload};
        case FormActions.setNumberAddressClient:
            return {...state, numberAddressClient: action.payload};
        case FormActions.setDistrictClient:
            return {...state, districtClient: action.payload};
        case FormActions.setStateClient:
            return {...state, stateClient: action.payload};
        case FormActions.setAddressClient:
            return {...state, addressClient: action.payload};
        case FormActions.setComplementClient:
            return {...state, complementClient: action.payload};
        case FormActions.setCityClient:
            return {...state, cityClient: action.payload};
        case FormActions.setStateId:
            return {...state, stateId: action.payload};
        case FormActions.setCityId:
            return {...state, cityId: action.payload};
        case FormActions.setCityName:
            return {...state, cityName: action.payload};
        case FormActions.setNumberCard:
            return {...state, numberCard: action.payload};
        case FormActions.setTypeCard:
            return {...state, typeCard: action.payload};
        case FormActions.setDueDate:
            return {...state, dueDateCard: action.payload};
        case FormActions.setCodeSecurity:
            return {...state, codeSecurityCard: action.payload};
        case FormActions.setAddressCharge:
            return {...state, addresCharge: action.payload};
        case FormActions.setNameApp:
            return {...state, nameApp: action.payload};
        case FormActions.setColorPrimaryClient:
            return {...state, colorPrimaryClient: action.payload};
        case FormActions.setColorSecondaryClient:
            return {...state, colorSecondaryClient: action.payload};
        case FormActions.setColorPrimaryDriver:
            return {...state, colorPrimaryDriver: action.payload};
        case FormActions.setColorSecondaryDriver:
            return {...state, colorSecondaryDriver: action.payload};
        case FormActions.setColorTextDriver:
            return {...state, colorTextDriver: action.payload};
        case FormActions.setColorTextClient:
            return {...state, colorTextClient: action.payload};
        case FormActions.setImageLogoClientSmall:
            return {...state, imageLogoClientSmall: action.payload};
        case FormActions.setImageLogoClientMedium:
            return {...state, imageLogoClientMedium: action.payload};
        case FormActions.setImageLogoClientBig:
            return {...state, imageLogoClientBig: action.payload};
        case FormActions.setUrlImageLogoClient:
            return {...state, urlImageLogoClient: action.payload};
        case FormActions.setUrlImageLogoDriver:
            return {...state, urlImageLogoDriver: action.payload};
        case FormActions.setIsLogoGenerated: 
            return {...state, isLogoGenerated: action.payload};
        case FormActions.setValueStart:
            return {...state, valueStartSimulator: action.payload};
        case FormActions.setValueKm:
            return {...state, valueKmSimulator: action.payload};
        case FormActions.setValueMinute:
            return {...state, valueMinuteSimulator: action.payload};
        case FormActions.setAddressOrigin:
            return {...state, addressOriginSimulator: action.payload};
        case FormActions.setAddressDestiny:
            return {...state, addressDestinySimulator: action.payload};
        case FormActions.setCancellationFeeValue:
            return {...state, cancellationFeeValue: action.payload};
        case FormActions.setCancellationFeeMinutes:
            return {...state, cancellationFeeMinutes: action.payload};
        case FormActions.setDriverPerTripValueFixe:
            return {...state, driverPerTripValueFixe: action.payload}; 
        case FormActions.setDriverPerTripPerWeekend:
            return {...state, driverPerTriPerWeekend: action.payload};
        case FormActions.setTypePaymentDriver:
            return {...state, typePaymentDriver: action.payload};  
        case FormActions.setPermissionAllowCallWaiting:
            return {...state, permissionAllowCallWaiting: action.payload};  
        case FormActions.setAllowStops:
            return {...state, allowStops: action.payload};
        case FormActions.setActiveDynamics:
            return {...state, activeDynamics: action.payload};
        case FormActions.setChargeExtraValueFixe:
            return {...state, chargeExtraValueFixe: action.payload};
        case FormActions.setChargeExtraValuePerTrip:
            return {...state, chargeExtraValuePerTrip: action.payload};
        case FormActions.setReceivedValuesPerMoney:
            return {...state, receivedValuesPerMoney: action.payload};
        case FormActions.setReceivedValuesPerPix:
            return {...state, receivedValuesPerPix: action.payload};
        case FormActions.setReceivedValuesCreditCard:
            return {...state, receivedValuesCreditCard: action.payload};
        case FormActions.setAccountReceivedValues:
            return {...state, accountReceivedValues: action.payload};
        case FormActions.setResetValue:
            return action.payload;
        default:
            return state;
    }
}

//PROVIDER SPEEDMOB

export function FormsSpeedContextProvider({children}: FormsSpeedContextProviderProps){
    const [state, dispatch] = useReducer(formStateReducer, initialData);

    const value = {state, dispatch};

    return(
        <FormsSpeedContext.Provider value={value}>
            {children}
        </FormsSpeedContext.Provider>
    )
}

//USE HOOK SPEEDMOB

export function useSpeedMobForm(){
    const context = useContext(FormsSpeedContext);

    if(context === undefined){
        throw new Error('useSpeedMobForm precisa ser usado dentro de provider!');
    }

    return context;
}