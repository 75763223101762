import { useNavigate } from "react-router-dom";

import { Container } from "./styles"

export default function FormInitial() {

    const navigate = useNavigate();

    const handleNextStep = () => {
        navigate('../selecao-cidade');
    }

    return(
        <Container>
            <div className="content-merchant">
                <p className="text-merchant">
                    Acabe com as tarifas excessivas e veja como ter seu próprio aplicativo de 
                    mobilidade <span>semelhante ao Uber.</span>
                </p>

                <p className="subtext-merchant">
                    Assista ao vídeo e descubra como é simples e rápido de configurar, sem necessidade
                    de conhecimento técnico. Deixe que o nosso time lhe ajude a ser patrão e criar sua marca.
                </p>

                <div className="content-button-next">
                    <button className="btn-next" onClick={handleNextStep}>Quero ter meu próprio aplicativo</button>
                </div>
            </div>
            <div className="content-video">
                <a target="_blank" rel="noreferrer" href="https://www.youtube.com/">
                    <img src={require('../../../../../assets/images/Grupo 544.png')} alt="link-video" />
                </a>
            </div>
        </Container>
    )
}