import styled from "styled-components";

interface ContainerProps{
    width: number;
    height: number;
}

export const Container = styled.div<ContainerProps>`
    min-width: ${props => props.width}px;
    min-height: ${props => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.color.colorButtonsSecondary};
    color: ${props => props.theme.color.backgroundPrimary};
    border-radius: 360px;
`