import FileSaver from "file-saver";
import { useRef, useEffect, useState } from "react";
import { Container } from "./styles";

import { ModelsDesignProps, TypeFontSloganProps } from "../Modal";
import Menu from "../Menu";

interface CanvasProps {
  urlImage: string;
  typeFont: "fill" | "stroke";
  typeLogo: ModelsDesignProps;
  nameLogo: string;
  nameSlogan: string | undefined;
  typeFontSlogan: TypeFontSloganProps;
  linkFontName: string;
  nameFontLink: string;
  colorLogoText: string;
  colorDesignLogo: string;
  backgroundTransparent: "transparent" | "normal" | null;
  viewModeSimulator: "driver" | "client";
  typeModule: "mobilitypro" | "speedmob";
}

export default function Canvas({
  urlImage,
  typeLogo,
  nameLogo,
  nameSlogan,
  typeFontSlogan,
  linkFontName,
  nameFontLink,
  colorLogoText,
  typeFont,
  colorDesignLogo,
  backgroundTransparent,
  viewModeSimulator,
  typeModule,
}: CanvasProps) {
  const [base64Image, setBase64Image] = useState<string>("");

  const refCanvas = useRef<HTMLCanvasElement | null>(null);

  const handleDownloadImage = (event: any) => {
    const link = event.currentTarget;
    link.download = `${nameLogo.toLocaleLowerCase()}-logo.png`;
    link.href = refCanvas.current?.toDataURL();

    const contentBriefing = `Fontes usadas: ${typeFontSlogan}, ${nameFontLink}${
      typeLogo === "design5" ? ", Caveat" : ""
    }${typeLogo === "design6" ? ", Bad Script" : ""}${
      typeLogo === "design7" ? ", Zeyada" : ""
    }
Url do ícone: ${urlImage},                          
Cores usadas: ${colorLogoText}, #ffffffff, #000000
`;

    const fileName = "briefingLogo.txt";
    const blob = new Blob([contentBriefing], {
      type: "text/plain;charset=utf-8",
    });

    FileSaver.saveAs(blob, fileName);
  };

  useEffect(() => {
    const font = new FontFace(nameFontLink, `url(${linkFontName})`, {
      style: "normal",
      weight: "400",
    });

    font
      .load()
      .then((font) => {
        document.fonts.add(font);
        const canvas = refCanvas.current;

        if (!canvas) {
          return;
        }

        const context = canvas.getContext("2d");

        const image: any = new Image();
        image.crossOrigin = "Anonymous";

        if (!context) {
          return;
        }

        if (backgroundTransparent === "transparent") {
          context.fillStyle = "rgba(0, 0, 0, 0)";
          context.rect(0, 0, 280, 250);
          context.fill();
        } else {
          context.fillStyle = "#FFFFFF";
          context.rect(0, 0, 280, 250);
          context.fill();
        }

        const drawText = (
          name: string,
          type: "stroke" | "fill",
          fontStyle: "normal" | "oblique" | "italic",
          size: number,
          coordinatesX: number,
          coordinatesY: number,
          colorText: string,
          nameFont: string
        ) => {
          context.textAlign = "center";
          context.font = `${fontStyle} ${size}px ${nameFont}`;

          if (type === "stroke") {
            context.strokeStyle = colorText;
            context.strokeText(name, coordinatesX, coordinatesY);
          } else {
            context.fillStyle = colorText;
            context.fillText(name, coordinatesX, coordinatesY);
          }
        };

        const drawTextSlogan = (
          name: string,
          type: "stroke" | "fill",
          fontStyle: "normal" | "oblique" | "italic",
          font: string,
          size: number,
          coordinatesX: number,
          coordinatesY: number
        ) => {
          context.textAlign = "center";
          context.fillStyle = "#000000";
          context.font = `${fontStyle} ${size}px ${font}`;

          if (type === "stroke") {
            context.strokeText(name, coordinatesX, coordinatesY);
          } else {
            context.fillText(name, coordinatesX, coordinatesY);
          }
        };

        if (typeLogo === "design1") {
          image.src = urlImage;
          image.onload = () => {
            context.drawImage(image, image.width / 2.35, 50, 60, 60);
            drawText(
              nameLogo,
              typeFont,
              "normal",
              nameLogo.length >= 12 ? 30 : 35,
              140,
              165,
              colorLogoText,
              nameFontLink
            );
            if (nameSlogan) {
              drawTextSlogan(
                nameSlogan,
                "fill",
                "normal",
                typeFontSlogan,
                nameSlogan.length >= 20 ? 18 : 16,
                140,
                200
              );
            }
            const imageUrlWithImage = canvas.toDataURL("image/png");
            setBase64Image(imageUrlWithImage);
          };
        }

        if (typeLogo === "design2") {
          image.src = urlImage;
          image.onload = () => {
            context.drawImage(image, image.width / 2.35, 80, 60, 60);
            drawText(
              nameLogo,
              typeFont,
              "normal",
              nameLogo.length >= 12 ? 30 : 35,
              140,
              70,
              colorLogoText,
              nameFontLink
            );
            if (nameSlogan) {
              drawTextSlogan(
                nameSlogan,
                "fill",
                "normal",
                typeFontSlogan,
                nameSlogan.length >= 20 ? 18 : 16,
                140,
                170
              );
            }
            const imageUrlWithImage = canvas.toDataURL("image/png");
            setBase64Image(imageUrlWithImage);
          };
        }

        if (typeLogo === "design3") {
          image.src = urlImage;
          image.onload = () => {
            context.drawImage(image, image.width / 2.35, 130, 60, 60);
            drawText(
              nameLogo,
              typeFont,
              "normal",
              nameLogo.length >= 12 ? 30 : 35,
              140,
              80,
              colorLogoText,
              nameFontLink
            );
            if (nameSlogan) {
              drawTextSlogan(
                nameSlogan,
                "fill",
                "normal",
                typeFontSlogan,
                nameSlogan.length >= 20 ? 18 : 16,
                140,
                110
              );
            }
            const imageUrlWithImage = canvas.toDataURL("image/png");
            setBase64Image(imageUrlWithImage);
          };
        }

        if (typeLogo === "design4") {
          image.src = urlImage;
          image.onload = () => {
            context.drawImage(image, image.width / 1.3, 95, 60, 60);
            drawText(
              nameLogo,
              typeFont,
              "normal",
              nameLogo.length >= 12 ? 25 : 30,
              100,
              125,
              colorLogoText,
              nameFontLink
            );
            if (nameSlogan) {
              drawTextSlogan(
                nameSlogan,
                "fill",
                "normal",
                typeFontSlogan,
                nameSlogan.length >= 20 ? 14 : 16,
                100,
                155
              );
            }
            const imageUrlWithImage = canvas.toDataURL("image/png");
            setBase64Image(imageUrlWithImage);
          };
        }

        if (typeLogo === "design5") {
          context.beginPath();
          context.arc(140, 80, 50, 0, 2 * Math.PI);
          context.fillStyle = colorDesignLogo;
          context.fill();

          context.beginPath();

          drawText(
            nameLogo.substring(0, 1),
            "fill",
            "normal",
            50,
            138,
            95,
            "#f1f1f1f1",
            nameFontLink
          );

          context.beginPath();

          drawText(
            nameLogo,
            "fill",
            "normal",
            nameLogo.length >= 12 ? 30 : 35,
            140,
            170,
            colorLogoText,
            nameFontLink
          );
          if (nameSlogan) {
            drawTextSlogan(
              nameSlogan,
              "fill",
              "normal",
              typeFontSlogan,
              nameSlogan.length >= 20 ? 18 : 16,
              140,
              200
            );
          }
          const imageUrl = canvas.toDataURL();
          setBase64Image(imageUrl);
        }

        if (typeLogo === "design6") {
          context.beginPath();

          context.moveTo(70, 80);
          context.lineTo(20, 80);
          context.lineTo(20, 160);
          context.lineTo(70, 160);
          context.lineTo(70, 80);

          context.strokeStyle = colorDesignLogo;
          context.lineWidth = 3;
          context.stroke();

          context.beginPath();

          context.moveTo(250, 80);
          context.lineTo(75, 80);
          context.lineTo(75, 160);
          context.lineTo(250, 160);
          context.lineTo(250, 80);

          context.fillStyle = colorDesignLogo;
          context.fill();

          context.beginPath();

          drawText(
            nameLogo.substring(0, 1),
            "fill",
            "normal",
            40,
            45,
            135,
            colorDesignLogo,
            nameFontLink
          );

          context.beginPath();

          drawText(
            nameLogo,
            "fill",
            "normal",
            nameLogo.length >= 12 ? 20 : 25,
            165,
            130,
            "#f1f1f1f1",
            nameFontLink
          );
          if (nameSlogan) {
            drawTextSlogan(
              nameSlogan,
              "fill",
              "normal",
              typeFontSlogan,
              nameSlogan.length >= 20 ? 18 : 20,
              135,
              190
            );
          }
          const imageUrl = canvas.toDataURL();
          setBase64Image(imageUrl);
        }

        if (typeLogo === "design7") {
          context.beginPath();

          context.moveTo(140, 10);
          context.lineTo(85, 72);
          context.lineTo(140, 135);
          context.lineTo(190, 72);
          context.lineTo(140, 10);

          context.strokeStyle = "#000000";
          context.lineWidth = 3;
          context.stroke();

          context.beginPath();

          drawText(
            nameLogo.substring(0, 1),
            "fill",
            "normal",
            65,
            135,
            95,
            colorDesignLogo,
            nameFontLink
          );

          context.beginPath();

          drawText(
            nameLogo,
            "fill",
            "normal",
            nameLogo.length >= 12 ? 30 : 35,
            140,
            185,
            colorLogoText,
            nameFontLink
          );
          if (nameSlogan) {
            drawTextSlogan(
              nameSlogan,
              "fill",
              "normal",
              typeFontSlogan,
              nameSlogan.length >= 20 ? 18 : 16,
              140,
              220
            );
          }

          const imageUrl = canvas.toDataURL();
          setBase64Image(imageUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    backgroundTransparent,
    colorDesignLogo,
    colorLogoText,
    linkFontName,
    nameFontLink,
    nameLogo,
    nameSlogan,
    typeFont,
    typeFontSlogan,
    typeLogo,
    urlImage,
  ]);

  return (
    <Container>
      <Menu
        nameLogo={nameLogo}
        typeModule={typeModule}
        base64Image={base64Image && base64Image}
        viewModeSimulator={viewModeSimulator}
        onClick={handleDownloadImage}
      />

      <canvas
        width={280}
        height={250}
        style={{ border: "4px solid #000000" }}
        ref={refCanvas}
      />
    </Container>
  );
}
