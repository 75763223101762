import { CheckCircle } from "phosphor-react";

import { Container } from "./styles";

import LinkMidiaSocial from "../../../../../components/LinkMidiaSocial";

import {
  UseFormsMobilityPro,
  FormActions,
  initialData,
} from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import pathIconFacebook from "../../../../../assets/images/icon_facebook.png";
import pathIconLinkedin from "../../../../../assets/images/icon_linkedin.png";
import pathIconInstagram from "../../../../../assets/images/icon_instagram.png";
import { useEffect } from "react";

export default function Finalize() {
  const { dispatch, setUrlLogoCustomer, setUrlLogoDriver } =
    UseFormsMobilityPro();
  const { setListConfigChecked } = useListConfigAppContext();

  // useEffect(() => {
  //   if (!state.phone && !state.whatsapp && !state.email) {
  //     setListConfigChecked([]);
  //     navigate("/mobilitypro/informacoes");
  //     return;
  //   }

  //   if (!listConfigChecked.includes("final")) {
  //     setListConfigChecked([...listConfigChecked, "final"]);
  //   }
  // }, [
  //   state.phone,
  //   state.whatsapp,
  //   state.email,
  //   setListConfigChecked,
  //   listConfigChecked,
  //   navigate,
  // ]);

  useEffect(() => {
    dispatch({
      type: FormActions.setResetValue,
      payload: initialData,
    });

    setUrlLogoCustomer("");
    setUrlLogoDriver("");

    localStorage.removeItem("logoIsGenerated");
    localStorage.removeItem("forms_datas_mobilitypro");
    localStorage.removeItem("url_logo_forms_customer_mobilitypro");
    localStorage.removeItem("url_logo_forms_driver_mobilitypro");
    localStorage.removeItem("uuid_url");

    setListConfigChecked([]);
  }, [dispatch, setUrlLogoCustomer, setUrlLogoDriver, setListConfigChecked]);

  return (
    <Container>
      <div className="content-finalize-app">
        <div className="content-title">
          <CheckCircle size={100} weight="fill" />
          <h1>Finalizado! Configurado com sucesso!</h1>
        </div>
        <div className="content-description">
          <p>
            O seu aplicativo foi configurado e está sendo processado. Em até 10
            minutos, você irá receber uma notificação com todos os detalhes do
            seu aplicativo no seu e-mail. Confira a caixa de spam ou aba de
            promoções.
          </p>
        </div>
        <div className="content-footer">
          <div className="midias-socials">
            <div>
              <LinkMidiaSocial
                icon={pathIconFacebook}
                name="facebook"
                link="https://www.facebook.com/fabrica704/"
              />
            </div>
            <div>
              <LinkMidiaSocial
                icon={pathIconLinkedin}
                name="linkedin"
                link="https://br.linkedin.com/company/704apps"
              />
            </div>
            <div>
              <LinkMidiaSocial
                icon={pathIconInstagram}
                name="instagram"
                link="https://www.instagram.com/fabrica704/"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
