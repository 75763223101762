import { Container } from "./styles";

import Modal from "@mui/material/Modal";
import { X } from "phosphor-react";

import { UseFormsMobilityPro } from "../../../../../contexts/FormsMobilityPro";

interface PreviewDatasProps {
  visible: boolean;
  setVisible: (data: boolean) => void;
}

export const PreviewDatas = ({ setVisible, visible }: PreviewDatasProps) => {
  const { state } = UseFormsMobilityPro();

  return (
    <Modal open={visible} onClose={() => setVisible(false)}>
      <Container>
        <button className="btn-close-modal" onClick={() => setVisible(false)}>
          <X size={20} color="#0000009d" />
        </button>
        <h1 className="title-modal">Dados a serem enviados</h1>
        <div className="content-items">
          <p>
            <span>Nome do projeto:</span> {state.name}
          </p>
          <p>
            <span>Telefone:</span> {state.phone}
          </p>
          <p>
            <span>WhatsApp:</span> {state.whatsapp}
          </p>
          <div className="content-informations-apps">
            <div>
              <h1>Motorista</h1>
              <p>
                <span>Nome do aplicativo Motorista:</span> {state.driver_app}
              </p>
              <p>
                <span>Cor primária (Aplicativo Motorista):</span>{" "}
                <input
                  type="color"
                  value={state.driver_app_main_color}
                  disabled
                />{" "}
              </p>
              <p>
                <span>Cor secundária (Aplicativo Motorista):</span>{" "}
                <input
                  type="color"
                  value={state.driver_app_secondary_color}
                  disabled
                />{" "}
              </p>
              <p>
                <span>Cor texto (Aplicativo Motorista):</span>{" "}
                <input
                  type="color"
                  value={state.driver_app_text_color}
                  disabled
                />{" "}
              </p>
            </div>
            <div>
              <h1>Cliente</h1>
              <p>
                <span>Nome do aplicativo passageiro:</span> {state.customer_app}
              </p>
              <p>
                <span>Cor primária (Aplicativo Passageiro):</span>{" "}
                <input
                  type="color"
                  value={state.customer_app_main_color}
                  disabled
                />{" "}
              </p>
              <p>
                <span>Cor secundária (Aplicativo Passageiro):</span>{" "}
                <input
                  type="color"
                  value={state.customer_app_secondary_color}
                  disabled
                />{" "}
              </p>
              <p>
                <span>Cor texto (Aplicativo Passageiro):</span>{" "}
                <input
                  type="color"
                  value={state.customer_app_text_color}
                  disabled
                />{" "}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
