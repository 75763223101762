import { Container } from "./styles";

interface LinkMidiaSocialProps{
    link: string;
    icon: string;
    name: string;
}

export default function LinkMidiaSocial({ link, icon, name }: LinkMidiaSocialProps){
    return(
        <Container href={link} target="_blank" rel="noreferrer" title={`link-${name}`}>
            <img src={icon} alt={`icon-${name}`} />
        </Container>
    )
}