import styled from "styled-components";

export const Container = styled.div`
  width: 215px;
  height: 385px;
  position: absolute;
  top: 14%;
  left: 30px;
  padding: 1rem;

  .content-login {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .content-login .content-logo {
    display: flex;
    justify-content: center;
  }

  .content-login .content-logo img {
    width: 160px;
    height: 140px;
  }

  .content-login .content-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      align-items: center;
      gap: 0.3rem;

      button {
        background: none;
        position: absolute;
        border: none;
        right: 1rem;
        cursor: pointer;
      }
    }

    input {
      width: 100%;
      background: none;
      border: none;
      outline: none;
    }
  }

  .content-login .content-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .text-register {
      font-size: 9px;

      button {
        font-size: 9px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    > span {
      font-size: 13px;
      cursor: pointer;
      display: block;
    }
  }

  .content-isdriver-forgetpassword {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-col-1 {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    > div {
      input {
      }
      label {
        font-size: 7px;
      }

      span {
        display: block;
        font-size: 7px;
        cursor: pointer;
      }
    }
  }

  .content-login .content-buttons button {
    border: none;
    border-radius: 5px;
    padding: 0.5rem 0.3rem;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    background: none;
  }
`;
