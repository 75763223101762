import styled from "styled-components";

export const Container = styled.div`
    margin: 2rem 0;

    .content{
        text-align: center;
        max-width: 650px;
        background-color: ${props => props.theme.color.backgroundPrimary};
        margin: 0rem 0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);

        > h1{
            font-size: 25px;
            margin-bottom: 2rem;
        }
    }

    .content-button-next{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem 0;
        gap: 20px;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }
    }

    button {
        background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
        padding: 8px 40px;
        color: var(--color-white-font);
        cursor: pointer;
        border: none;
        border-radius: 5px;
        font-size: 20px;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonSecondaryHover};
        }
    }

    button:nth-child(2){
        background: none;
        border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        color: ${props => props.theme.color.colorButtonsSecondary};
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-color: ${props => props.theme.color.backgroundOpposite};
    }
        
    .css-1g7dve4-MuiFormLabel-root-MuiInputLabel-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }
        
    .css-12ja8fw-MuiFormLabel-root-MuiInputLabel-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-1a1qzbn-MuiInputBase-root-MuiOutlinedInput-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-yws31n{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-npp7dq{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-igs3ac{
        border-color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-iqrq84{
        color: ${props => props.theme.color.backgroundOpposite};
    }
`