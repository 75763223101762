import { CheckCircle } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import { Container } from "./styles";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import LinkMidiaSocial from "../../../../../components/LinkMidiaSocial";

import { useSpeedMobForm, FormActions, initialData } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import pathIconFacebook from '../../../../../assets/images/icon_facebook.png';
import pathIconLinkedin from '../../../../../assets/images/icon_linkedin.png';
import pathIconInstagram from '../../../../../assets/images/icon_instagram.png';
import { useEffect } from "react";

export default function FinalizeApp() {

    const navigate = useNavigate();

    const { dispatch } = useSpeedMobForm();
    const { setListConfigChecked, listConfigChecked } = useListConfigAppContext();

    const handleResetApplication = () => {

        dispatch({
            type: FormActions.setResetValue,
            payload: initialData,
        })

        setListConfigChecked([]);

        navigate("../../apresentacao");
    }

    useEffect(() => {
        /*eslint-disable*/
        if(!listConfigChecked.includes('final')){
            setListConfigChecked([...listConfigChecked, 'final']);
        }
    }, []);
    
    return(
        <ThemeConfigApp moduleTheme="speedmob" isCenter="normal" currentList="final">
            <Container>
                <div className="content-finalize-app">
                    <div className="content-title">
                        <CheckCircle size={100} weight="fill" />
                        <h1>Finalizado! <br /> Configurado com sucesso!</h1>
                    </div>
                    <div className="content-description">
                        <p>
                            O seu aplicativo foi configurado e está sendo processado. Em até 10 minutos, 
                            você irá receber uma notificação com todos os detalhes do seu aplicativo no 
                            seu e-mail. Confira a caixa de spam ou aba de promoções.
                        </p>
                    </div>
                    <div className="content-footer">
                        <div>
                            <button onClick={handleResetApplication}>VOLTAR PARA O INÍCIO</button>
                        </div>
                        <div className="midias-socials">
                            <div>
                                <LinkMidiaSocial
                                    icon={pathIconFacebook}
                                    name="facebook"
                                    link="https://facebook.com"
                                />
                            </div>
                            <div>
                                <LinkMidiaSocial
                                    icon={pathIconLinkedin}
                                    name="linkedin"
                                    link="https://linkedin.com"
                                />
                            </div>
                            <div>
                                <LinkMidiaSocial
                                    icon={pathIconInstagram}
                                    name="instagram"
                                    link="https://instagram.com"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    )
}