import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Folder } from "phosphor-react";

import {
  FormActions,
  UseFormsMobilityPro,
} from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import { ChangeEvent, useEffect, useState, useRef } from "react";
import { ResizeLogo } from "../../../../../Utils/Hooks/ResizeLogo";

import { Container } from "./styles";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginDriver from "../../../../../components/AppsRendered/Driver/Login";
import LoginClient from "../../../../../components/AppsRendered/Client/Login";
import LoginCredencies from "../../../../../components/AppsRendered/Driver/LoginCredencies";
import RegisterDriver from "../../../../../components/AppsRendered/Driver/Register";
import RegisterClient from "../../../../../components/AppsRendered/Client/Register";
import Modal from "../../../../../components/Modal";

export default function FormLogo() {
  const {
    state,
    dispatch,
    setUrlLogoCustomer,
    setUrlLogoDriver,
    urlLogoCustomer,
    urlLogoDriver,
  } = UseFormsMobilityPro();
  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const refInputSelectLogo = useRef<any>();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [viewSimulation, setViewSimulation] = useState<"driver" | "client">(
    "driver"
  );
  const [stepDriver, setStepDriver] = useState<
    "login" | "loginCredencies" | "register"
  >("login");
  const [stepClient, setStepClient] = useState<"login" | "register">("login");
  const [stepRenderedDriver, setStepRenderedDriver] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [stepRenderedClient, setStepRenderedClient] = useState<
    React.ReactNode | undefined
  >(undefined);

  const handleChangeTypeSimulation = (
    event: React.MouseEvent<HTMLElement>,
    viewSimulation: "driver" | "client"
  ) => {
    if (viewSimulation !== null) {
      setViewSimulation(viewSimulation);
    }
  };

  const handleChangeLogoChoosed = (data: ChangeEvent<HTMLInputElement>) => {
    //const typeImagesRendered = [50, 150, 250];

    // if (data.target.files) {
    //     const files = data.target.files[0];

    //     typeImagesRendered.map((valuePx) => {
    //         return (
    //             ResizeLogo(files, valuePx, valuePx, 'PNG', 'blob')
    //                 .then((result) => {
    //                     if (valuePx === 50) {
    //                         console.log('value 50px', result);
    //                         dispatch({
    //                             type: FormActions.setImageLogoClientSmall,
    //                             payload: result
    //                         });
    //                     }
    //                     else if (valuePx === 150) {
    //                         console.log('value 150px', result);
    //                         dispatch({
    //                             type: FormActions.setImageLogoClientMedium,
    //                             payload: result
    //                         });
    //                     } else if (valuePx === 250) {
    //                         console.log('value 250px', result);
    //                         dispatch({
    //                             type: FormActions.setImageLogoClientBig,
    //                             payload: result
    //                         });
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                     swal({
    //                         title: 'Erro ao carregar imagem',
    //                         icon: 'error'
    //                     })
    //                 })
    //         )
    //     })
    // }

    if (data.target.files) {
      const files = data.target.files[0];
      ResizeLogo(files, 512, 512, "PNG", "base64")
        .then((result) => {
          if (viewSimulation === "client") {
            setUrlLogoCustomer(result as any);
            dispatch({
              type: FormActions.setCustomerAppLogo,
              payload: result,
            });
            const cloneState = state;
            cloneState.customer_app_logo = result;
            localStorage.setItem(
              "forms_datas_mobilitypro",
              JSON.stringify(cloneState)
            );
            localStorage.setItem(
              "url_logo_forms_customer_mobilitypro",
              JSON.stringify(result)
            );
          } else {
            setUrlLogoDriver(result as any);
            dispatch({
              type: FormActions.setDriverAppLogo,
              payload: result,
            });
            const cloneState = state;
            cloneState.driver_app_logo = JSON.stringify(result);
            localStorage.setItem(
              "forms_datas_mobilitypro",
              JSON.stringify(cloneState)
            );
            localStorage.setItem(
              "url_logo_forms_driver_mobilitypro",
              JSON.stringify(result)
            );
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Erro ao carregar imagem",
            icon: "error",
          });
        });
    }
  };

  const handleBackStep = () => {
    navigate(`/mobilitypro/cores-aplicativo-passageiro/${id}`);
  };

  const handleNextStep = () => {
    if (!state.customer_app_logo && !state.driver_app_logo) {
      swal({
        title: "Escolha uma logo para prosseguir com o cadastro!",
        icon: "info",
      });
      return;
    } else if (!state.customer_app_logo) {
      swal({
        title: "Escolha a logo do app cliente para prosseguir",
        icon: "info",
      });
      return;
    } else if (!state.driver_app_logo) {
      swal({
        title: "Escolha a logo do app motorista para prosseguir",
        icon: "info",
      });
      return;
    } else {
      setListConfigChecked([...listConfigChecked, "logo"]);
      navigate(`../cidades/${id}`);
    }
  };

  useEffect(() => {
    if (
      !state.customer_app &&
      !state.customer_app_main_color &&
      !state.customer_app_secondary_color &&
      !state.customer_app_text_color
    ) {
      setListConfigChecked([]);
      navigate(`/mobilitypro/informacoes/${id}`);
      return;
    }

    if (!listConfigChecked.includes("logo")) {
      setListConfigChecked([...listConfigChecked, "logo"]);
    }
  }, [
    state.customer_app,
    state.customer_app_main_color,
    state.customer_app_secondary_color,
    state.customer_app_text_color,
    navigate,
    setListConfigChecked,
    listConfigChecked,
    id,
  ]);

  useEffect(() => {
    if (stepDriver === "login") {
      setStepRenderedDriver(
        <LoginDriver
          pathLogoSelected={urlLogoDriver}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          textColor={state.driver_app_text_color}
          setStepApplication={setStepDriver}
        />
      );
    } else if (stepDriver === "loginCredencies") {
      setStepRenderedDriver(
        <LoginCredencies
          pathLogoSelected={urlLogoDriver}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          textColor={state.driver_app_text_color}
          setStepApplication={setStepDriver}
        />
      );
    } else if (stepDriver === "register") {
      setStepRenderedDriver(
        <RegisterDriver
          pathLogoSelected={urlLogoDriver}
          primaryColor={state.driver_app_main_color}
          secondaryColor={state.driver_app_secondary_color}
          textColor={state.driver_app_text_color}
          setStepApplication={setStepDriver}
        />
      );
    }
  }, [
    stepDriver,
    urlLogoDriver,
    state.driver_app_main_color,
    state.driver_app_secondary_color,
    state.driver_app_text_color,
  ]);

  useEffect(() => {
    if (stepClient === "login") {
      setStepRenderedClient(
        <LoginClient
          pathLogoSelected={urlLogoCustomer}
          primaryColor={state.customer_app_main_color}
          secondaryColor={state.customer_app_secondary_color}
          textColor={state.customer_app_text_color}
          setStepApplication={setStepClient}
        />
      );
    } else if (stepClient === "register") {
      setStepRenderedClient(
        <RegisterClient
          pathLogoSelected={urlLogoCustomer}
          primaryColor={state.customer_app_main_color}
          secondaryColor={state.customer_app_secondary_color}
          textColor={state.customer_app_text_color}
          setStepApplication={setStepClient}
        />
      );
    }
  }, [
    stepClient,
    urlLogoCustomer,
    state.customer_app_main_color,
    state.customer_app_secondary_color,
    state.customer_app_text_color,
  ]);

  useEffect(() => {
    if (refInputSelectLogo.current) {
      refInputSelectLogo.current.value = "";
    }
  }, [viewSimulation]);

  return (
    <ThemeConfigApp
      moduleTheme="mobilitypro"
      isCenter="normal"
      currentList="logo"
    >
      <Container>
        <Modal
          typeModule="mobilitypro"
          viewModeSimulator={viewSimulation}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <div className="content-logo-app">
          <div className="content-logo-page">
            <h1>Escolha a logo do seu aplicativo:</h1>

            <div className="content-select-logo">
              {localStorage.getItem("logoIsGenerated") == null && (
                <>
                  <div
                    className="select-logo-user"
                    onClick={() => {
                      //localStorage.setItem('logoIsGenerated', 'logoReady');
                    }}
                  >
                    <label htmlFor="select-logo-user">
                      <span>Carregue sua marca aqui</span>
                      <Folder size={32} weight="fill" />
                    </label>
                    <input
                      id="select-logo-user"
                      name="logoUser"
                      type="file"
                      ref={refInputSelectLogo}
                      onChange={handleChangeLogoChoosed}
                    />
                  </div>
                  <div
                    className="content-logo-generated"
                    onClick={() => {
                      setOpenModal(true);
                      //localStorage.setItem('logoIsGenerated', 'logoGenerated');
                    }}
                  >
                    <label htmlFor="select-logo-generated">
                      Gerar uma marca para mim
                    </label>
                    <span>
                      Esta opção cria por inteligência artificial uma marca
                      baseado nas suas informações. <br />
                      Cria sua logo por aqui e agilize a criação do seu app, se
                      precisar pode alterar futuramente no seu painel (sujeito a
                      cobranças pelo serviço).
                    </span>
                  </div>
                </>
              )}

              {localStorage.getItem("logoIsGenerated") === "logoReady" && (
                <div
                  className="select-logo-user"
                  // onClick={() =>
                  //     localStorage.setItem('logoIsGenerated', 'logoReady')
                  // }
                >
                  <label htmlFor="select-logo-user">
                    <span>Carregue sua marca aqui</span>
                    <Folder size={32} weight="fill" />
                  </label>
                  <input
                    id="select-logo-user"
                    name="logoUser"
                    type="file"
                    ref={refInputSelectLogo}
                    onChange={handleChangeLogoChoosed}
                  />
                </div>
              )}

              {localStorage.getItem("logoIsGenerated") === "logoGenerated" && (
                <div
                  className="content-logo-generated"
                  onClick={() => {
                    setOpenModal(true);
                    //localStorage.setItem('logoIsGenerated', 'logoGenerated');
                  }}
                >
                  <label htmlFor="select-logo-generated">
                    Gerar uma marca para mim
                  </label>
                  <span>
                    Esta opção cria por inteligência artificial uma marca
                    baseado nas suas informações. <br />
                    Este serviço será cobrado na sua próxima assinatura o valor
                    de R$ 30,00.
                  </span>
                </div>
              )}
            </div>

            <div className="content-button-next">
              <button className="btn-back" onClick={handleBackStep}></button>
              <button className="btn-next" onClick={handleNextStep}></button>
            </div>
          </div>

          <div className="content-application">
            <div className="content-group-button">
              <ToggleButtonGroup
                color="primary"
                aria-label="show-type-app"
                value={viewSimulation}
                exclusive
                onChange={handleChangeTypeSimulation}
              >
                <ToggleButton value="client" aria-label="type-client">
                  Cliente
                </ToggleButton>
                <ToggleButton value="driver" aria-label="type-driver">
                  Motorista
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <SimulatorApp>
              {viewSimulation === "client"
                ? stepRenderedClient
                : stepRenderedDriver}
            </SimulatorApp>
          </div>
          <div className="content-button-next-mobile">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
          </div>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
