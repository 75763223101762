import styled from "styled-components";

export const Container = styled.div`

    .content{
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content > div {
        width: 600px;
    }

    .content .icon-card{
        color: ${props => props.theme.color.colorButtonsSecondary};
    }

    .content .title-page{
        color: ${props => props.theme.color.colorButtonsSecondary};
    }

    .content .content-texts{
        margin: 2rem 0;

        > p{
            font-size: 18px;
            margin: 1rem 0;
        }
    }

    .content .content-list{
        font-size: 18px;
    }

    .content-button-next{
        margin: 2rem 0;
    }

    .content-button-next button{
        background-color: ${props => props.theme.color.colorButtonsSecondary};
        padding: 8px 25px;
        color: var(--color-white-font);
        cursor: pointer;
        border: none;
        border-radius: 5px;
        font-size: 16px;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonSecondaryHover};
        }
    }

    @media(max-width: 425px){
        .content .icon-card{
            text-align: center;
        }

        .content .title-page{
            text-align: center;
            font-size: 14px;
        }

        .content .content-texts{
            > p{
                text-align: center;
                font-size: 16px
            }
        }

        .content-button-next button{
            font-size: 14px;
        }
    }
`