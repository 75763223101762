import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    .content-colors{
        width: 500px;
        align-self: center;

        > h1{
            text-align: center;
        }
    }

    .content-colors .content-input-colors{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        > div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
            background-color: ${props => props.theme.color.colorTextOpposite};
            padding: 1rem;
        }

        > div label{
            font-size: 20px;
            font-weight: bold;
        }

        > div input{
            width: 30px;
            height: 33px;
            border-radius: 360px;
            cursor: pointer;
            border: none;
            background: none;

            ::-webkit-color-swatch {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                border: none;
            }
        }
    }

    .content-button-next{
        display: flex;
        justify-content: center;
        gap: 1rem;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .content-button-next-mobile{
        display: none;
        justify-content: center;
        flex-direction: column-reverse;
        gap: 1rem;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            width: 100%;
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    @media(max-width: 768px){
        .content-button-next{
            display: none;
        }

        .content-button-next-mobile{
            display: flex;
            margin: 1rem 0;
        }
    }

    @media(max-width: 600px){
        .content-colors{
            width: 100%;
            align-self: center;

            > h1{
                font-size: 22px;
            }
        }

        .content-button-next{
            position: absolute;
            bottom: -70rem;
            left: 4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            flex-direction: column-reverse;
        }
    }
`