export const CardFlagIdentifier = (numberCard: string) => {

    const numberCardFormated = numberCard.replaceAll(' ','');
    
    /*eslint-disable*/
    const mastercardRegex = /^5[1-5][0-9]{14}$/;
    const eloRegex = /^((((636368)|(438935)|(636369)|(504175)|(451416)|(636297)|(506699))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const americanExpressRegex = /^3[47]\d{13}$/;
    const hipercardRegex = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
    const aleloRegex = /^(5067|6364|6365|6367)\d{12}$/;
    const dinersClubRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const jcbRegex = /^(?:2131|1800|35\d{3})\d{11}$/;
    const discoverRegex = /^6(?:011|5[0-9]{2})\d{12}$/;

    let typeBrand = 'Unknow';
    let pathLogo: any;

    if(mastercardRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-mastercard.png');
        typeBrand = 'mastercard';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(eloRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-elo.png');
        typeBrand = 'elo';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(visaRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-visa.png');
        typeBrand = 'visa';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(americanExpressRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-american-express.png');
        typeBrand = 'american-express';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(hipercardRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-hipercard.png');
        typeBrand = 'hipercard';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(aleloRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-alelo.png');
        typeBrand = 'alelo';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(dinersClubRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-diners-club.png');
        typeBrand = 'diners-club';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(jcbRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-jcb.png');
        typeBrand = 'jcb';
        return {
            typeBrand,
            pathLogo
        }
    }

    if(discoverRegex.test(numberCardFormated)){
        pathLogo = require('../../assets/images/brand-card-discover.png');
        typeBrand = 'discover';
        return {
            typeBrand,
            pathLogo
        }
    }

    return {typeBrand, pathLogo};
}