import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";

import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useEffect } from "react";
import { Container } from "./styles"
import ThemeConfigApp from "../../../../../components/ThemeConfigApp";

import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import Mask from "../../../../../Utils/masks";

interface IFormInputs {
    nameApp: string;
}

const schema = yup.object({
    primaryColorClient: yup.string(),
    primaryColorDriver: yup.string(),
    secondaryColorClient: yup.string(),
    secondaryColorDriver: yup.string(),
    nameApp: yup.string().required('Esse campo é obrigatório'),
})

export default function FormNameApp() {

    const { control, handleSubmit, setValue } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

    const { state, dispatch } = useSpeedMobForm();

    const navigate = useNavigate();

    const handleBackStep = () => {
        navigate('/speedmob/passo-a-passo-app-config');
    }

    const handleNextStep = (data: IFormInputs) => {
        if (data) {
            dispatch({
                type: FormActions.setNameApp,
                payload: data.nameApp
            });

            const cloneState = state;
            cloneState.nameApp = data.nameApp;
            localStorage.setItem("forms_datas_speedmob", JSON.stringify(cloneState));

            navigate('../cores-aplicativo-motorista');
        }
    }

    useEffect(() => {
        if (state.nameApp !== '') {
            setValue('nameApp', state.nameApp);
        }

        const isCreditCard = state.addresCharge === null && state.numberCard === null && state.typeCard === undefined && state.dueDateCard === null;

        if (isCreditCard) {
            navigate('/speedmob/apresentacao');
        }
    }, [state.nameApp, state.addresCharge, state.numberCard, state.typeCard, state.dueDateCard, setValue, navigate]);
    
    useEffect(() => {
      if(!listConfigChecked.includes('name-app')){
        setListConfigChecked([...listConfigChecked, 'name-app']);
      }
    },[setListConfigChecked, listConfigChecked]);

    return (
        <ThemeConfigApp moduleTheme="speedmob" isCenter="center" currentList="name-app">
            <Container>
                <div className="content-form-name-app">
                    <div className="content-form-config-app">
                        <h1>Digite o nome do seu aplicativo:</h1>
                        <form className="content-select-colors" onSubmit={handleSubmit(handleNextStep)}>
                            <Controller
                                control={control}
                                name="nameApp"
                                render={({
                                    field: { onChange, onBlur, value = '', name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        error={error ? true : false}
                                        helperText={error && error.message}
                                        label="Nome do app"
                                        onBlur={onBlur}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            event.target.value = Mask.setOnlyLetters(value.toLocaleLowerCase());
                                            setValue('nameApp', Mask.setOnlyLetters(value.toLocaleLowerCase()));
                                        }}
                                        value={value}
                                        inputRef={ref}
                                        name={name}
                                    />
                                )}
                            />

                            <div className="content-button-next">
                                <button className="btn-back" onClick={handleBackStep}></button>
                                <button className="btn-next" type="submit" ></button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    )
}