import styled from "styled-components";

export const Container = styled.div`
    margin: 2rem 0;
    padding: 0 2rem; 

    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 2rem;

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .content-merchant-select-city{
        flex: 0.7;
        background-color: ${props => props.theme.color.backgroundPrimary};
        padding: 2rem;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
    }
    
    .message-welcome{
        font-size: 25px;
        line-height: 2rem;
        font-weight: bold;
        margin: 2rem 0;
    }

    .content-list{
        margin: 2rem 0;
        width: 90%;

        ul{
            list-style: none;
        }

        ul li{
            font-size: 18px;
            margin: 0.5rem 0;
        }

        ul li:before{
            content: "✓";
            display: inline-block;
            margin-right: 10px;
            width: 10px;
            font-weight: bold;
            color: ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    .content-select-city{
        width: 400px;
        background-color: ${props => props.theme.color.backgroundPrimary};
        margin: 0rem 0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);

        > span {
            display: block;
            margin: 1rem 0;
            font-size: 24px;
            font-weight: bold;
        }
    }

    .content-selects{
        width: 100%;
    }

    .content-button-next{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem 0;
        gap: 20px;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }
    }

    button {
        background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
        padding: 8px 40px;
        color: var(--color-white-font);
        cursor: pointer;
        border: none;
        border-radius: 5px;
        font-size: 20px;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonSecondaryHover};
        }
    }

    button:nth-child(2){
        background: none;
        border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        color: ${props => props.theme.color.colorButtonsSecondary};
    }

    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-1g7dve4-MuiFormLabel-root-MuiInputLabel-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    .css-ihz2l2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }
    
    .css-12ja8fw-MuiFormLabel-root-MuiInputLabel-root{
        color: ${props => props.theme.color.backgroundOpposite};
    }

    @media(max-width: 1100px){
        flex-direction: column;
        gap: 1rem;

        .message-welcome{
            text-align: center;
        }

        .content-list{
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    @media(max-width: 768px){
        .message-welcome{
            font-size: 25px;
            line-height: 35px;
        }

        .content-list{
            ul li{
                font-size: 15px;
            }
        }
    }

    @media(max-width: 520px){
        padding: 0 1rem; 

        .message-welcome{
            font-size: 18px;
            line-height: 25px;
        }

        .content-list{
            ul li{
                font-size: 13px;
            }
        }
        

        .content-select-city{
            span {
                font-size: 20px
            }

            label{
                select{
                    display: block;
                    margin: 0.5rem 0;
                    padding: 0.5rem;
                    font-size: 20px;
                }
            }
        }
    }

`