import styled from "styled-components";

export const Container = styled.div`
    .content-select-icons{
        width: 100%;
        height: 300px;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
    }

    .content-icons-selected{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 2rem 0;
        gap: 15px;
    }

    .content-icons-selected button{
        padding: 1rem;
        border: 1px dashed #000000;
        border-radius: 5px;
        cursor: pointer;
        background: none;

        img{
            width: 50px;
        }

        &:hover{
            transition: all ease 0.8s;
            border: 1px dashed blue;
        }
    }
`