import { Container } from "./styles";

interface CardFontProps{
    nameCard: string;
    nameFont: string;
    onClick: () => void;
    isSelected: boolean;
    fontsChoosed: string[];
}

export default function CardFont({ nameCard, nameFont, onClick, isSelected, fontsChoosed }: CardFontProps){
    return(
        <Container 
            disabled={fontsChoosed.length >= 3 && !isSelected}
            style={{ border: isSelected ? '1px solid blue' : '' }} 
            name={nameCard} onClick={onClick}
        >
            <h2 style={{ fontFamily: nameFont }}>{nameCard}</h2>
        </Container>
    )
}