/*eslint-disable*/
export const ValidatorCnpj = (cnpj: string) => {
    if (!cnpj) return false //verifica se o CNPJ foi fornecido. Se não foi fornecido ou é nulo, a função retorna false;
        const isString = typeof cnpj === 'string' //Verifica se o tipo do CNPJ é uma string.;
        const validTypes =
            isString || Number.isInteger(cnpj) || Array.isArray(cnpj); // verifica se o tipo do CNPJ é uma string, um número inteiro ou uma matriz (array)

        if (!validTypes) return false; //verifica se o tipo do CNPJ não é válido. Se não for um dos tipos permitidos, a função retorna false

        if (isString) {
            //Se o tipo do CNPJ for uma string, o código entra no bloco if (isString). Ele verifica se o comprimento da string é maior que 18 caracteres. Se for maior, a função retorna false
            if (cnpj.length > 18) return false;

            //Em seguida, são feitas duas verificações usando expressões regulares para validar 
            //o formato da string do CNPJ. A expressão /^\d{14}$/.test(cnpj) verifica se a 
            //string contém apenas dígitos numéricos e tem um comprimento de 14 caracteres.
            //A expressão /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj) verifica se a string está 
            //no formato "XX.XXX.XXX/XXXX-XX", onde X é um dígito numérico. Se a string do CNPJ não 
            //atender a nenhuma das duas validações, a função retorna false.

            const digitsOnly = /^\d{14}$/.test(cnpj);
            const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

            if (digitsOnly || validFormat) true;
            else return false;
        }

        //A próxima parte do código é executada se o CNPJ não for uma string.
        //A linha const match = cnpj.toString().match(/\d/g); converte o CNPJ para uma string 
        //(caso seja um número) e em seguida usa a função match com a expressão regular /\d/g para 
        //extrair apenas os dígitos numéricos do CNPJ. Esses dígitos são armazenados na variável 
        //numbers como um array de números.
        
        const match = cnpj.toString().match(/\d/g);
        const numbers = Array.isArray(match) ? match.map(Number) : [];

        //A linha if (numbers.length !== 14) return false; verifica se o array numbers não contém 
        //14 elementos. Se não contiver, a função retorna false, indicando que o CNPJ é inválido.

        if (numbers.length !== 14) return false;

        //A função calc(x: number) é definida. Essa função calcula um dígito verificador do CNPJ 
        //com base nos números fornecidos. Ela usa uma lógica matemática para realizar os cálculos 
        //necessários.

        const calc = (x: number) => {
            const slice = numbers.slice(0, x);
            let factor = x - 7;
            let sum = 0;

            for (let i = x; i >= 1; i--) {
                const n = slice[x - i];
                sum += n * factor--;
                if (factor < 2) factor = 9;
            }

            const result = 11 - (sum % 11);

            return result > 9 ? 0 : result;
        };

        const digits = numbers.slice(12);

        const digit0 = calc(12);
        if (digit0 !== digits[0]) return false;

        //As duas últimas linhas do código calculam os dígitos verificadores do CNPJ e os 
        //comparam com os dígitos fornecidos. Se houver uma diferença, a função retorna false, 
        //indicando que o CNPJ é inválido. Caso contrário, retorna true, indicando que o CNPJ é válido.

        const digit1 = calc(13);
        return digit1 === digits[1];
}