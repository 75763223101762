import styled from "styled-components";

export const Container = styled.div`
    width: 700px;
    overflow: auto;

    h1{
        color: #000000 !important;
        text-align: center;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }
    a{
        color: #000000 !important;
        text-decoration: none !important;
    }

    .content-button-steps{
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .content-logo-generated{
        margin: 2rem 0;
        width: 100%;
        height: 450px;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .content-config-logos-generated{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    @media(max-width: 768px){
        width: 500px;
    }

    @media(max-width: 525px){
        width: 95%;

        h1{
            font-size: 20px;
        }
    }
`