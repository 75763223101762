import styled from "styled-components";

export const Container = styled.div`

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
            color: ${props => props.theme.color.colorTextPrimary};
    }

    label {
        color: ${props => props.theme.color.colorTextPrimary};
    }

    input {
        color: ${props => props.theme.color.colorTextPrimary};
    }


    > h1 {
        color: ${props => props.theme.color.colorTextPrimary};
        font-size: 33px;
    }

    .content-settings-rules{
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 25px;
    }

    .content-column1{
        width: 350px;
    }
    
   .content-text-header{
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    .content-column2{
        width: 350px;
    }

    .content-column1 > div{
        margin: 1rem 0;
    }

    .content-input{
        display: flex;
        justify-content: center;
        align-items: center;

        div{
            margin: 0;
        }
    }

    .content-input div{
        margin: 0.5rem 0;
    }
    
    .content-column2 > div {
        margin: 1.5rem;
    }

    .content-button-next{
        display: flex;
        justify-content: center;
        margin: 2rem 0;
        gap: 1rem;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    @media(max-width: 1300px){
        .content-column1{
            width: 100%;
        }

        .content-column2{
            width: 100%;
        }

        .content-settings-rules{
            padding: 0 0.5rem;
        }
    }

    @media(max-width: 1000px){
        .content-settings-rules{
            flex-direction: column;
            margin: 2rem 0;
        }

        .content-list{
            padding-left: 2rem;
        }
    }

    @media(max-width: 500px){
        > h1 {
            font-size: 22px;
            text-align: center;
        }

        .content-list{
            padding: 0rem;
        }

        .content-settings-rules{
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .content-column1{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        
        .content-column2{
            width: 100%;
        }

        .content-column2 > div {
            margin: 0;
        }
        
        .content-inputs-radio{
            width: 100%;
        }

        .content-button-next{
            flex-direction: column-reverse;
        }
    }
`