import styled from "styled-components";

export const Container = styled.div`
  width: 215px;
  height: 385px;
  position: absolute;
  top: 14%;
  left: 31px;

  .content-register .header {
    width: 100%;
    height: 35px;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
  }

  .content-register .header .btn-back button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .content-register .header .logo img {
    width: 40px;
    height: 40px;
  }

  .content-register .select-category {
    padding: 0.7rem;
  }

  .content-register .select-category span {
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  .content-register .content-categories {
    height: 316px;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px !important;
    }

    ::-webkit-scrollbar-track {
      background: #eeeeee;
    }

    ::-webkit-scrollbar-thumb {
      background: #bdbdbd;
    }
  }

  .content-register .content-categories > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.49rem;
    border: 1px solid #00000031;
    background-color: #ffffff;
  }

  .content-register .content-categories h1 {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }

  .content-register .content-categories img {
    width: 40px;
  }

  .content-register .content-categories svg {
    cursor: pointer;
  }
`;
