import styled from "styled-components";

export const Container = styled.div`
  margin: 2rem 0;
  padding: 0 1rem;

  animation-name: smooth;
  animation-duration: 1s;

  @keyframes smooth {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .message-welcome {
    font-size: 16px;
    line-height: 1.4rem;
    margin: 2rem 0;
    text-align: center;
  }

  .content-select-city {
    max-width: 650px;
    background-color: ${(props) => props.theme.color.backgroundPrimary};
    margin: 0rem 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  }

  .btn-add-remove-city {
    display: flex;
    gap: 1rem;
  }

  .content-select-city .content-selects {
    width: 500px;
    height: 300px;
    overflow-y: auto;
    padding: 1rem;

    .content-input-citie-and-estate {
      border-bottom: 1px solid #afafaf9d;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .inputs {
        flex: auto;
      }

      .btn-remove-inputs {
        button {
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  .content-button-next {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0;
    gap: 20px;

    .btn-next {
      ::after {
        content: ">";
      }

      &:hover {
        transition: all ease 0.3s;
        ::after {
          content: "AVANÇAR";
        }
      }
    }

    .btn-back {
      ::after {
        content: "<";
      }

      &:hover {
        transition: all ease 0.3s;
        ::after {
          content: "VOLTAR";
        }
      }
    }
  }

  .content-button-next {
    button {
      background: linear-gradient(
        90deg,
        ${(props) => props.theme.color.colorTextPrimary} 0%,
        ${(props) => props.theme.color.colorButtonSecondaryHover} 0%,
        ${(props) => props.theme.color.colorButtonsSecondary} 25%
      );
      padding: 8px 40px;
      color: var(--color-white-font);
      cursor: pointer;
      border: none;
      border-radius: 5px;
      font-size: 20px;

      &:hover {
        transition: all ease 0.3s;
        background-color: ${(props) =>
          props.theme.color.colorButtonSecondaryHover};
      }
    }

    button:nth-child(2) {
      background: none;
      border: 1px solid ${(props) => props.theme.color.colorButtonsSecondary};
      color: ${(props) => props.theme.color.colorButtonsSecondary};
    }
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-1g7dve4-MuiFormLabel-root-MuiInputLabel-root {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-ihz2l2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-12ja8fw-MuiFormLabel-root-MuiInputLabel-root {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-1636szt {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-iqrq84 {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-yws31n {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-hkssev {
    color: ${(props) => props.theme.color.backgroundOpposite};
  }

  .css-igs3ac {
    border: 1px solid ${(props) => props.theme.color.backgroundOpposite};
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 1rem;
    margin: 0;

    .message-welcome {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .content-select-city {
      width: 100%;
    }
  }

  @media (max-width: 520px) {
    padding: 0rem;
    .content-select-city .content-selects {
      width: 100%;
    }
  }
`;
