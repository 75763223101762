import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Container } from "./styles";
import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { UseFormsMobilityPro } from "../../../../../contexts/FormsMobilityPro";

import axios from "axios";

import { useState, useEffect } from "react";

import Select from "../../Components/Select";

export interface StatesProps {
    id: number;
    name: string;
    sigla: string;
}

export interface CitiesProps {
    id: number;
    name: string;
}

export default function FormSelectCity() {
    const { state, dispatch } = useSpeedMobForm();
    const { setLoading } = UseFormsMobilityPro();

    const [states, setStates] = useState<StatesProps[]>([]);
    const [cities, setCities] = useState<CitiesProps[]>([]);

    const navigate = useNavigate();

    const handleBackStep = () => {
        navigate('/speedmob/apresentacao');
    }

    const handleNextStep = () => {
        if(state.cityName === ''){
            swal({
                title: 'Escolha uma cidade para prosseguir com o próximo passo!',
                icon: 'info'
            })
            return;
        }

        dispatch({
            type: FormActions.setCityName,
            payload: state.cityName
        })
        navigate('../plano-por-cidade');
    }

    useEffect(() => {
        const getListEstates = async () => {
            setLoading(true);
            try {
                const responseEstates = await axios.get(`https://formapi.speedmob.com.br/City/ListStates`);
                setStates(responseEstates.data.data);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }

        getListEstates();
    }, [setLoading]);

    useEffect(() => {
        if (state.stateId) {
            const getListCities = async () => {
                setLoading(true);
                try {
                    const responseCities = await axios.get(`https://formapi.speedmob.com.br/City/listCities?estateId=${state.stateId}`)
                    setCities(responseCities.data.data);
                } catch (error) {
                    console.log(error);
                }
                setLoading(false);
            }

            getListCities();
        }

    }, [state.stateId, setLoading]);

    return(
        <Container>
            <div className="content-merchant-select-city">
                <p className="message-welcome">
                    Bem vindo! Crie seu app em menos de 10 minutos, não dependa de nenhuma empresa, tenha acesso instantâneo a links para download para motoristas e passageiros.
                </p>
                <div className="content-list">
                    <ul>
                        <li>Planos a partir de R$ 8,30 por dia, com motoristas ilimitados.</li>
                        <li>Recebe seu app com sua marca, cores e regras em até 30 minutos.</li>
                        <li>Exclusividade de layout na sua cidade por até 365 dias.</li>
                        <li>Apoio nas suas redes sociais com modelos semiprontos para postagens.</li>
                        <li>Gerente de contas para te ajudar a configurar seu app e te deixar mais livre.</li>
                        <li>Monitor no seu Smartfone, não necessita de computador.</li>
                    </ul>
                </div>
            </div>

            <div className="content-select-city">
                <span>Faça sua simulação agora.</span>
                
                <div className="content-selects">
                    <Select
                        value={state.stateId}
                        id="select-state"
                        label="Escolha um estado que tem interesse"
                        types="estates"
                        datas={states}
                    />

                    {cities?.length > 0 &&
                        <Select
                            value={state.cityId}
                            id="select-city"
                            label="Escolha uma cidade que tem interesse"
                            types="cities"
                            datas={cities}
                        />
                    }
                </div>
                <div className="content-button-next">
                    <button className="btn-next" onClick={handleNextStep}></button>
                    <button className="btn-back" onClick={handleBackStep}></button>
                </div>
            </div>
        </Container>
    )
}