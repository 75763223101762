const dark = {
    title: 'dark',

    color: {
        backgroundPrimary: '#202124',
        backgroundOpposite: '#FFFFFF',
        colorTextPrimary: '#F1F1F1F1',
        colorTextOpposite: '#1C1C1C',
        colorTitle: '#0059B2',
        colorTextSecondary: '#B2BAC2',
        colorButtonsPrimary: '#3d469e',
        colorButtonsSecondary: '#C46302',
        colorButtonSecondaryHover: '#C46302',
        backgroundApplication: '#000000'
    }
}

export default dark;