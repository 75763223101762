import { Container } from "./styles"
import ListConfigAppSpeedmob from "../../Pages/Modules/Speedmob/Components/ListConfigApp"
import ListConfigAppMobilityPro from "../../Pages/Modules/MobilityPro/Components/ListConfigApp";

interface ThemeConfigAppProps {
    moduleTheme: 'speedmob' | 'mobilitypro';
    children: React.ReactNode;
    isCenter: 'center' | 'normal';
    currentList: any;
}

export default function ThemeConfigApp({ children, isCenter, currentList, moduleTheme }: ThemeConfigAppProps) {

    return (
        <Container isCenter={isCenter}>
            <div className="content-list">
                {moduleTheme === 'speedmob' && <ListConfigAppSpeedmob currentList={currentList} />}
                {moduleTheme === 'mobilitypro' && <ListConfigAppMobilityPro currentList={currentList} />}
            </div>
            <div className="content-page">
                {children}
            </div>
        </Container>
    )
}