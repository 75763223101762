import { Container } from "./styles";

import CardFont from "../../../CardFont";

interface StepSelectFontsProps {
    listFontsChoosed: string[];
    setListFontsChoosed: React.Dispatch<React.SetStateAction<string[]>>
}

export default function StepSelectFonts({ listFontsChoosed, setListFontsChoosed }:StepSelectFontsProps){

    const handleChooseFont = (font: string) => {

        if (!listFontsChoosed.includes(font)) {
            setListFontsChoosed(currentData => [...currentData, font]);
        }
      
        if(listFontsChoosed.includes(font)){
            const removeListFonts = listFontsChoosed.filter((data) => data !== font);
            setListFontsChoosed(removeListFonts);
        }
    }

    return(
        <Container>
            <h1>SELECIONE 3 FONTES</h1>

            <div className="content-cards">
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Kalam') }
                    onClick={() => handleChooseFont('Kalam')} 
                    nameCard="Kalam" 
                    nameFont="Kalam" 
                />
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Neucha') }
                    onClick={() => handleChooseFont('Neucha')} 
                    nameCard="Neucha" 
                    nameFont="Neucha" 
                />
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Julee') }
                    onClick={() => handleChooseFont('Julee')} 
                    nameCard="Julee" 
                    nameFont="Julee" 
                />
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Lobster') }
                    onClick={() => handleChooseFont('Lobster')} 
                    nameCard="Lobster" 
                    nameFont="Lobster" 
                />
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Oswald') }
                    onClick={() => handleChooseFont('Oswald')} 
                    nameCard="Oswald" 
                    nameFont="Oswald" 
                />
                <CardFont
                    fontsChoosed={listFontsChoosed}
                    isSelected={ listFontsChoosed.includes('Itim') }
                    onClick={() => handleChooseFont('Itim')} 
                    nameCard="Itim" 
                    nameFont="Itim" 
                />
            </div>
        </Container>
    )
}