import styled from "styled-components";

export const Container = styled.div`
    margin: 2rem 0;
    padding: 0 2rem; 

    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    gap: 2rem;

    animation-name: smooth;
    animation-duration: 1s;

    @keyframes smooth {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .content-merchant-city{
        align-self: flex-start;
        flex: 1;
        background-color: ${props => props.theme.color.backgroundPrimary};
        padding: 2rem;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
    }

    .title-city{
        font-size: 30px;
        font-weight: bold;
        display: block;
        color: ${props => props.theme.color.colorButtonsSecondary};
    }

    .text-page{
        display: block;
        font-size: 25px;
        color: ${props => props.theme.color.colorTextPrimary};
        margin: 1rem 0;
        width: 80%;
    }

    .content-merchant-plan{
        flex: 0.4;
        background-color: ${props => props.theme.color.backgroundPrimary};
        margin-top: 0rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
    }

    .text-plan{
        font-size: 20px;
        font-weight: bold;
        color: ${props => props.theme.color.colorTextPrimary};
        margin: 0.5rem 0;
    }

    .title-plan-city{
        font-size: 20px;
        color: ${props => props.theme.color.colorTextPrimary};
        margin-bottom: 1rem;
    }

    .content-prices-plan{
        .plans{
            margin-bottom: 1rem;
            border-bottom: 1px solid ${props => props.theme.color.colorButtonsSecondary};

            .title{
                font-size: 20px;
                font-weight: bold;
                display: block;
                text-align: center;
                margin-bottom: 0.5rem;
            }
    
            .old-price{
                font-size: 20px;
                font-weight: bold;
                display: block;
                text-align: center;
                margin-bottom: 0.5rem;
                text-decoration: line-through;
            }
    
            .new-price{
                font-size: 30px;
                font-weight: bold;
                color: ${props => props.theme.color.colorButtonsSecondary};
                display: block;
                text-align: center;
                margin-bottom: 0.5rem;
            }
        }
    }

    .content-button-next{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 2rem 0;
        gap: 20px;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }
    }

    button {
        background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
        padding: 8px 40px;
        color: var(--color-white-font);
        cursor: pointer;
        border: none;
        border-radius: 10px;
        font-size: 20px;

        &:hover{
            transition: all ease 0.3s;
            background-color: ${props => props.theme.color.colorButtonSecondaryHover};
        }
    }

    button:nth-child(2){
        background: none;
        color: ${props => props.theme.color.colorButtonsSecondary};
        border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
    }

    @media(max-width: 1100px){
        .text-page{
            width: 100%;
        }

        .content-merchant-plan{
            width: 100%;
        }
    }

    @media(max-width: 990px){
        flex-direction: column;
    }

    @media(max-width: 520px){
        gap: 1rem;
        padding: 0 1rem; 

        .title-city{
            font-size: 25px;
            text-align: center;
        }

        .text-page{
            font-size: 16px;
            text-align: center;
        }

        .content-prices-plan{
            .plans{
                .title{
                    font-size: 18px;
                }
        
                .old-price{
                    font-size: 18px;
                }
        
                .new-price{
                    font-size: 25px;
                }
            }
        }
    }
`