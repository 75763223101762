import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Folder } from "phosphor-react";

import { FormActions, useSpeedMobForm } from "../../../../../contexts/FormsSpeedmob";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

import { ChangeEvent, useEffect, useState } from "react";
import { ResizeLogo } from "../../../../../Utils/Hooks/ResizeLogo";

import { Container } from "./styles";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginDriver from "../../../../../components/AppsRendered/Driver/Login";
import LoginClient from "../../../../../components/AppsRendered/Client/Login";
import LoginCredencies from "../../../../../components/AppsRendered/Driver/LoginCredencies";
import RegisterDriver from "../../../../../components/AppsRendered/Driver/Register";
import RegisterClient from "../../../../../components/AppsRendered/Client/Register";
import Modal from "../../../../../components/Modal";

export default function FormLogo() {
    const { state, dispatch } = useSpeedMobForm();
    const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [viewSimulation, setViewSimulation] = useState<'driver' | 'client'>('driver');
    const [stepDriver, setStepDriver] = useState<'login' | 'loginCredencies' | 'register'>('login');
    const [stepClient, setStepClient] = useState<'login' | 'register'>('login');
    const [stepRenderedDriver, setStepRenderedDriver] = useState<React.ReactNode | undefined>(undefined);
    const [stepRenderedClient, setStepRenderedClient] = useState<React.ReactNode | undefined>(undefined);

    const handleChangeTypeSimulation = (event: React.MouseEvent<HTMLElement>, viewSimulation: 'driver' | 'client') => {
        if (viewSimulation !== null) {
            setViewSimulation(viewSimulation);
        }
    }

    const handleChangeLogoChoosed = (data: ChangeEvent<HTMLInputElement>) => {
        const typeImagesRendered = [50, 150, 250];

        if (data.target.files) {
            const files = data.target.files[0];

            typeImagesRendered.map((valuePx) => {
                return (
                    ResizeLogo(files, valuePx, valuePx, 'PNG', 'blob')
                        .then((result) => {
                            if (valuePx === 50) {
                                console.log('value 50px', result);
                                dispatch({
                                    type: FormActions.setImageLogoClientSmall,
                                    payload: result
                                });
                            } else if (valuePx === 150) {
                                console.log('value 150px', result);
                                dispatch({
                                    type: FormActions.setImageLogoClientMedium,
                                    payload: result
                                });
                            } else if (valuePx === 250) {
                                console.log('value 250px', result);
                                dispatch({
                                    type: FormActions.setImageLogoClientBig,
                                    payload: result
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            swal({
                                title: 'Erro ao carregar imagem',
                                icon: 'error'
                            })
                        })
                )
            })
        }

        if (data.target.files) {
            const files = data.target.files[0];
            ResizeLogo(files, 250, 250, 'PNG', 'base64')
                .then((result) => {
                    if (viewSimulation === 'client') {
                        dispatch({
                            type: FormActions.setUrlImageLogoClient,
                            payload: result,
                        })
                    } else {
                        dispatch({
                            type: FormActions.setUrlImageLogoDriver,
                            payload: result,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    swal({
                        title: 'Erro ao carregar imagem',
                        icon: 'error'
                    })
                })
        }
    }

    const handleBackStep = () => {
        navigate('/speedmob/configuracoes-do-app/cores-aplicativo-passageiro');
    }

    const handleNextStep = () => {
        if (!state.urlImageLogoClient && !state.urlImageLogoDriver) {
            swal({
                title: 'Escolha uma logo para prosseguir com o cadastro!',
                icon: 'info'
            });
            return;
        } else if (!state.urlImageLogoClient) {
            swal({
                title: 'Escolha a logo do app cliente para prosseguir',
                icon: 'info'
            });
            return;
        } else if (!state.urlImageLogoDriver) {
            swal({
                title: 'Escolha a logo do app motorista para prosseguir',
                icon: 'info'
            });
            return;
        }
        else {
            setListConfigChecked([...listConfigChecked, 'logo']);
            navigate('../taxas-do-app');
        }
    }

    useEffect(() => {
        const isNameApp = state.nameApp === '';

        if(isNameApp){
            navigate('/speedmob/apresentacao');
        }

    }, [state.nameApp, navigate]);
    
    useEffect(() => {
      if(!listConfigChecked.includes('logo')){
        setListConfigChecked([...listConfigChecked, 'logo']);
      }
    }, [listConfigChecked, setListConfigChecked]);

    useEffect(() => {
        if (stepDriver === 'login') {
            setStepRenderedDriver(
                <LoginDriver
                    pathLogoSelected={state.urlImageLogoDriver}
                    primaryColor={state.colorPrimaryDriver}
                    secondaryColor={state.colorSecondaryDriver}
                    textColor={state.colorTextDriver}
                    setStepApplication={setStepDriver}
                />
            )
        } else if (stepDriver === 'loginCredencies') {
            setStepRenderedDriver(
                <LoginCredencies
                    pathLogoSelected={state.urlImageLogoDriver}
                    primaryColor={state.colorPrimaryDriver}
                    secondaryColor={state.colorSecondaryDriver}
                    textColor={state.colorTextDriver}
                    setStepApplication={setStepDriver}
                />
            )
        } else if (stepDriver === 'register') {
            setStepRenderedDriver(
                <RegisterDriver
                    pathLogoSelected={state.urlImageLogoDriver}
                    primaryColor={state.colorPrimaryDriver}
                    secondaryColor={state.colorSecondaryDriver}
                    textColor={state.colorTextDriver}
                    setStepApplication={setStepDriver}
                />
            )
        }
    }, [stepDriver, state.urlImageLogoDriver, state.colorPrimaryDriver, state.colorSecondaryDriver, state.colorTextDriver]);

    useEffect(() => {
        if (stepClient === 'login') {
            setStepRenderedClient(
                <LoginClient
                    pathLogoSelected={state.urlImageLogoClient}
                    primaryColor={state.colorPrimaryClient}
                    secondaryColor={state.colorSecondaryClient}
                    textColor={state.colorTextClient}
                    setStepApplication={setStepClient}
                />
            )
        } else if (stepClient === 'register') {
            setStepRenderedClient(
                <RegisterClient
                    pathLogoSelected={state.urlImageLogoClient}
                    primaryColor={state.colorPrimaryClient}
                    secondaryColor={state.colorSecondaryClient}
                    textColor={state.colorTextClient}
                    setStepApplication={setStepClient}
                />
            )
        }

    }, [stepClient, state.urlImageLogoClient, state.colorPrimaryClient, state.colorSecondaryClient, state.colorTextClient]);

    return (
        <ThemeConfigApp moduleTheme="speedmob" isCenter="normal" currentList="logo">
            <Container>
                <Modal
                    typeModule="speedmob"
                    viewModeSimulator={viewSimulation}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                <div className="content-logo-app">
                    <div className="content-logo-page">
                        <h1>Escolha a logo do seu aplicativo:</h1>

                        <div className="content-select-logo">
                            {state.isLogoGenerated === null &&
                                <>
                                    <div   
                                        className="select-logo-user"
                                        onClick={() => 
                                            dispatch({
                                                type: FormActions.setIsLogoGenerated,
                                                payload: 'logoReady',
                                            })
                                        }
                                    >
                                        <label htmlFor="select-logo-user">
                                            <span>Carregue sua marca aqui</span>
                                            <Folder size={32} weight="fill" />
                                        </label>
                                        <input id="select-logo-user" name="logoUser" type="file" onChange={handleChangeLogoChoosed} />
                                    </div>
                                    <div
                                        className="content-logo-generated"
                                        onClick={() => {
                                            setOpenModal(true);
                                            dispatch({
                                                type: FormActions.setIsLogoGenerated,
                                                payload: 'logoGenerated',
                                            });
                                        }}
                                    >
                                        <label htmlFor="select-logo-generated">Gerar uma marca para mim</label>
                                        <span>
                                            Esta opção cria por inteligência artificial uma marca baseado nas suas informações. <br />
                                            Cria sua logo por aqui e agilize a criação do seu app, se precisar pode alterar futuramente no seu painel (sujeito a cobranças pelo serviço).
                                        </span>
                                    </div>
                                </>
                            }

                            {state.isLogoGenerated === 'logoReady' &&
                                <div 
                                    className="select-logo-user"
                                    onClick={() => 
                                        dispatch({
                                            type: FormActions.setIsLogoGenerated,
                                            payload: 'logoReady',
                                        })
                                    }
                                >
                                    <label htmlFor="select-logo-user">
                                        <span>Carregue sua marca aqui</span>
                                        <Folder size={32} weight="fill" />
                                    </label>
                                    <input id="select-logo-user" name="logoUser" type="file" onChange={handleChangeLogoChoosed} />
                                </div>
                            }

                            {state.isLogoGenerated === 'logoGenerated' &&
                                <div
                                    className="content-logo-generated"
                                    onClick={() => {
                                        setOpenModal(true);
                                        dispatch({
                                            type: FormActions.setIsLogoGenerated,
                                            payload: 'logoGenerated',
                                        });
                                    }}
                                >
                                    <label htmlFor="select-logo-generated">Gerar uma marca para mim</label>
                                    <span>
                                        Esta opção cria por inteligência artificial uma marca baseado nas suas informações. <br />
                                        Este serviço será cobrado na sua  próxima assinatura o valor de R$ 30,00.
                                    </span>
                                </div>
                            }
                        </div>

                        <div className="content-button-next">
                            <button className="btn-back" onClick={handleBackStep}></button>
                            <button className="btn-next" onClick={handleNextStep}></button>
                        </div>
                    </div>

                    <div className="content-application">
                        <div className="content-group-button">
                            <ToggleButtonGroup
                                color="primary"
                                aria-label="show-type-app"
                                value={viewSimulation}
                                exclusive
                                onChange={handleChangeTypeSimulation}
                            >
                                <ToggleButton value='client' aria-label="type-client">Cliente</ToggleButton>
                                <ToggleButton value='driver' aria-label="type-driver">Motorista</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <SimulatorApp>
                            {viewSimulation === 'client' ?
                                stepRenderedClient
                                :
                                stepRenderedDriver
                            }
                        </SimulatorApp>
                    </div>
                    <div className="content-button-next-mobile">
                        <button className="btn-back" onClick={handleBackStep}></button>
                        <button className="btn-next" onClick={handleNextStep} ></button>
                    </div>
                </div>
            </Container>
        </ThemeConfigApp>
    )
}