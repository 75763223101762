import { TextField, InputLabel, FormControl, Select, MenuItem, SelectChangeEvent, Box } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaRegAddressCard, FaMobileAlt } from 'react-icons/fa';
import { AiFillCreditCard, AiOutlineQrcode, AiOutlineCheck } from 'react-icons/ai';
import {FormControlLabel, Checkbox} from "@mui/material";
import { ptBR } from '@mui/x-date-pickers/locales';

import React, { useState, useEffect } from "react";
import { Container } from "./styles";

import { useSpeedMobForm, FormActions } from "../../../../../contexts/FormsSpeedmob";

import FormUser from "../FormUser";
import CardPlan from "../../../../../components/CardPlan";
import ValueDiscount from "../../../../../components/ValueDiscount";
import IconList from "../../../../../components/IconList";
import pathExampleCodeSecurity from '../../../../../assets/images/icon-credit-card.png';
import Mask from "../../../../../Utils/masks";
import { CardFlagIdentifier } from "../../../../../Utils/Hooks/CardFlagIdentifier";

interface IFormInputs {
    numberCard: string;
    codeSecurity: string;
    addressBilling: string;
}

interface listPlansProps {
    namePlan: string;
    discount: number;
    qntCity: number;
    pricePlan: number;
    percentagePix: number;
    pricePortion: number;
    qntDays: number;
    qntPortion: number;
    valueRates: number;
    firstSignatureOldPrice: number;
    firstSignatureNewPrice: number;
}

const schema = yup.object({
    numberCard: yup.string().min(17, 'Mínimo 14 caracteres').max(19, 'Máximo 16 caracteres').required('Este campo é obrigatório'),
    codeSecurity: yup.string().max(3, 'O máximo é 3 caracteres').required('Este campo é obrigatório'),
    addressBilling: yup.string().required('Este campo é obrigatório')
});

const listPlans: listPlansProps[] = [
    {
        namePlan: 'lite',
        discount: 50,
        qntCity: 1,
        pricePlan: 250,
        percentagePix: 1,
        pricePortion: 29.99,
        qntDays: 90,
        qntPortion: 12,
        valueRates: 4.99,
        firstSignatureOldPrice: 450,
        firstSignatureNewPrice: 250,
    },
    {
        namePlan: 'pro',
        discount: 50,
        qntCity: 1,
        pricePlan: 300,
        percentagePix: 1,
        pricePortion: 35.50,
        qntDays: 50,
        qntPortion: 20,
        valueRates: 6.99,
        firstSignatureOldPrice: 500,
        firstSignatureNewPrice: 300,
    },
    {
        namePlan: 'pro',
        discount: 70,
        qntCity: 2,
        pricePlan: 450,
        percentagePix: 1,
        pricePortion: 40.00,
        qntDays: 100,
        qntPortion: 25,
        valueRates: 8.99,
        firstSignatureOldPrice: 680,
        firstSignatureNewPrice: 450,
    },
]

export default function FormSelectPlan() {

    const { control, formState: { errors }, handleSubmit, setValue } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const [isPlanSelected, setIsPlanSelected] = useState<listPlansProps | undefined>(undefined);
    const [dueDate, setDueDate] = useState<Dayjs | null | Date>(null);
    const [typeCard, setTypeCard] = useState<string | undefined>('');

    const [pathBrandCard, setPathBrandCard] = useState<string>('');

    const [showDatasPersonal, setShowDatasPersonal] = useState<boolean>(true);
    const [showCreditCard, setShowCreditCard] = useState<boolean>(true);

    const [termsChecked, setTermsChecked] = useState<any>();

    const { state, dispatch } = useSpeedMobForm();

    const navigate = useNavigate();

    const indentifierBrandCard = (numberCard: string) => {
        const result = CardFlagIdentifier(numberCard);
        setPathBrandCard(result.pathLogo);
    }

    const handleTermsCheck = () => {
        if(termsChecked){
            setTermsChecked(false);
        }else{
            setTermsChecked(true);
        }
    }

    const handleChangeOptionTypeCard = (event: SelectChangeEvent) => {
        setTypeCard(event.target.value);
    }

    const handleChangeDate = (newValue: Dayjs | null) => {
        setDueDate(newValue);
    }

    const handleNextStepCreditCard = (data: IFormInputs) => {
        console.log(errors);
        
        if (!dueDate)  {
            swal({
                title: 'Digite a data de nascimento',
                icon: 'info'
            })

            return;
        };

        if(data){
            dispatch({
                type: FormActions.setTypeCard,
                payload: typeCard,
            })

            dispatch({
                type: FormActions.setDueDate,
                payload: dueDate,
            })

            dispatch({
                type: FormActions.setNumberCard,
                payload: data.numberCard,
            })
    
            dispatch({
                type: FormActions.setCodeSecurity,
                payload: data.codeSecurity
            })
    
            dispatch({
                type: FormActions.setAddressCharge,
                payload: data.addressBilling
            })
    
            navigate('../passo-a-passo-app-config');
        }
    }

    const handleNextStepPix = () => {
        navigate('../passo-a-passo-app-config');
    }   

    useEffect(() => {
        if (state.cityName === '') {
          navigate("../apresentacao");
        }
    }, [state.cityName, navigate]);

    useEffect(() => {
        if(state.numberCard){
            setValue('numberCard', state.numberCard as any);
        }
        if(state.codeSecurityCard){
            setValue('codeSecurity', state.codeSecurityCard);
        }
        if(state.addresCharge){
            setValue('addressBilling', state.addresCharge);
        }
        if(state.dueDateCard){
            setDueDate(state.dueDateCard);
        }
        if(state.typeCard){
            setTypeCard(state.typeCard);
        }
    }, [state.numberCard, state.codeSecurityCard, state.addresCharge, state.dueDateCard, state.typeCard, setValue]);

    return (
        <Container 
            termsAccepted={termsChecked}
            showDataPersonal={showDatasPersonal}
            showCreditCard={showCreditCard}    
        >
            {!isPlanSelected &&
                <>
                    <p className="text-merchant">Analise os planos abaixo, veja a melhor forma de pagar, se antecipar terá mais descontos e ficará com mais lucros!</p>

                    <h2 className="title-plans">Veja nossos planos</h2>

                    <div className="content-types-plans">
                        <div className="content-discount">
                            <ValueDiscount
                                type="reverse"
                                value={15}
                                text="Mensal"
                            />

                            <ValueDiscount
                                type="reverse"
                                value={30}
                                text="Semestral"
                            />

                            <ValueDiscount
                                type="normal"
                                value={50}
                                text="Anual"
                            />
                        </div>
                    </div>

                    <div className="content-cards-plan">
                        {listPlans.map((value, index) => {
                            return(
                                <React.Fragment key={index}>
                                    <CardPlan
                                        namePlan={value.namePlan}
                                        discount={value.discount}
                                        qntCity={value.qntCity}
                                        pricePlan={value.pricePlan}
                                        percentagePix={value.percentagePix}
                                        pricePortion={value.pricePortion}
                                        qntDays={value.qntDays}
                                        qntPortion={value.qntPortion}
                                        valueRates={value.valueRates}
                                        firstSignatureOldPrice={value.firstSignatureOldPrice}
                                        firstSignatureNewPrice={value.firstSignatureNewPrice}
                                        planSelected={value}
                                        setPlanSelected={setIsPlanSelected}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </>
            }

            {isPlanSelected &&
                <>
                    <div className="content-types-forms">
                        <div
                            className="type-datas-personal" 
                            onClick={() => setShowDatasPersonal(true)}
                        >
                            <div>
                                <FaRegAddressCard size={45} />
                            </div>
                            <div>
                                <h2>Dados Pessoais</h2>
                                <p>Informe os seus dados</p>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="type-datas-payment">
                            <div>
                                <AiFillCreditCard size={45} />
                            </div>
                            <div>
                                <h2>Dados do Pagamento</h2>
                                <p>Informe os dados do pagamento</p>
                            </div>
                        </div>
                    </div>

                    <div className="content-details-payments">
                        <div className="content-plan-selected">
                            <CardPlan
                                  namePlan={isPlanSelected.namePlan}
                                  discount={isPlanSelected.discount}
                                  qntCity={isPlanSelected.qntCity}
                                  pricePlan={isPlanSelected.pricePlan}
                                  percentagePix={isPlanSelected.percentagePix}
                                  pricePortion={isPlanSelected.pricePortion}
                                  qntDays={isPlanSelected.qntDays}
                                  qntPortion={isPlanSelected.qntPortion}
                                  valueRates={isPlanSelected.valueRates}
                                  firstSignatureOldPrice={isPlanSelected.firstSignatureOldPrice}
                                  firstSignatureNewPrice={isPlanSelected.firstSignatureNewPrice}
                                  setPlanSelected={setIsPlanSelected}
                            />
                        </div>

                        {showDatasPersonal && (
                            <FormUser
                                setShowDatasPersonal={setShowDatasPersonal}
                            />
                        )}

                        {!showDatasPersonal && (
                            <>
                                <form className="details-payments" onSubmit={handleSubmit(handleNextStepCreditCard)}>
                                    <div className="content-select-form-payments">
                                        <div className="form-payment-card" onClick={() => setShowCreditCard(true)}>
                                            <h3>Cartão</h3>
                                        </div>
                                        <div className="form-payment-pix" onClick={() => setShowCreditCard(false)}>
                                            <h3>Pix</h3>
                                        </div>
                                    </div>
                                    <div className="content-details-types-payments">
                                        {showCreditCard && (
                                            <div className="content-details-card">
                                                <div className="content-form">
                                                    <div>
                                                        <Controller
                                                            render={({
                                                                field: { onChange, value = '', name, ref },
                                                                fieldState: { error },
                                                            }) => (
                                                                <TextField
                                                                    error={error ? true : false}
                                                                    helperText={error && error.message}
                                                                    fullWidth
                                                                    type='text'
                                                                    id="number-card"
                                                                    label="Número do cartão"
                                                                    variant="outlined"
                                                                    onBlur={(event) => {
                                                                        const { value } = event.target;
                                                                        event.target.value = Mask.setMaskCreditCard(value);
                                                                        setValue('numberCard', Mask.setMaskCreditCard(value));
                                                                        indentifierBrandCard(value); 
                                                                    }}
                                                                    name={name}
                                                                    ref={ref}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                            name="numberCard"
                                                            control={control}
                                                            rules={{ required: false }}
                                                        />
                                                        {pathBrandCard && (
                                                            <div className="brand-card">
                                                                <img src={pathBrandCard} alt="logo-card-brand" />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="select-type-card">Selecione o tipo de cartão</InputLabel>
                                                            <Select
                                                                defaultValue = 'credito'
                                                                labelId="select-type-card"
                                                                id="select-type-card"
                                                                value={typeCard}
                                                                label="Selecione o tipo de cartão"
                                                                onChange={handleChangeOptionTypeCard}
                                                            >
                                                                <MenuItem value="credito">Crédito</MenuItem>
                                                                <MenuItem value="debito">Débito</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div className="content-two-inputs">
                                                        <div style={{ flex: 1 }}>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange, onBlur, value = '', name, ref },
                                                                    fieldState: { error },
                                                                }) => (
                                                                    <TextField
                                                                        error={error ? true : false}
                                                                        helperText={error && error.message}
                                                                        fullWidth
                                                                        type='text'
                                                                        id="addressBilling"
                                                                        label="Seu endereço de cobrança"
                                                                        variant="outlined"
                                                                        onBlur={onBlur}
                                                                        name={name}
                                                                        ref={ref}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                    />
                                                                )}
                                                                name="addressBilling"
                                                                control={control}
                                                                rules={{ required: false }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <Box sx={{ width: 300}}>
                                                                <LocalizationProvider 
                                                                    dateAdapter={AdapterDayjs}
                                                                    localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                                                >
                                                                    <DatePicker
                                                                        views={['month', 'year']}
                                                                        label="Data de vencimento"
                                                                        value={dueDate}
                                                                        onChange={handleChangeDate}
                                                                        inputFormat="MM/YYYY"
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Box>
                                                        </div>
                                                    </div>

                                                    <div className="content-code-security">
                                                        <Controller
                                                            render={({
                                                                field: { onBlur, value = '', name, ref },
                                                                fieldState: { error },
                                                            }) => (
                                                                <TextField
                                                                    error={error ? true : false}
                                                                    helperText={error && error.message}
                                                                    fullWidth
                                                                    type='text'
                                                                    id="codeSecurity"
                                                                    label="Código de segurança"
                                                                    variant="outlined"
                                                                    onBlur={onBlur}
                                                                    name={name}
                                                                    ref={ref}
                                                                    value={value}
                                                                    onChange={(event) => {
                                                                        const { value } = event.target;
                                                                        event.target.value = Mask.setOnlyNumber(value);
                                                                        setValue('codeSecurity', Mask.setOnlyNumber(value));
                                                                    }}
                                                                    inputProps={{ maxLength: 3 }}
                                                                />
                                                            )}
                                                            name="codeSecurity"
                                                            control={control}
                                                            rules={{ required: false }}
                                                        />

                                                        <img src={pathExampleCodeSecurity} alt="example-code-security" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {!showCreditCard && (
                                            <div className="content-pix">
                                                <div className="content-details-pix">
                                                    <img src="https://www.qrstuff.com/images/default_qrcode.png" alt="qrcode-example" />
                                                </div>
                                                <div className="content-description-pix">
                                                    <p>
                                                        Pague com o pix em qualquer dia e a qualquer hora! O pagamento é instantâneo, prático
                                                        e pode ser feito em segundos. É muito rápido e seguro. :)
                                                    </p>

                                                    <div className="content-list-pix">
                                                        <div>
                                                            <IconList width={30} height={30}>
                                                                <FaMobileAlt size={15} />
                                                            </IconList>
                                                            <p>Abra o app do seu banco ou instituição financeira e entre no <span>ambiente pix</span></p>
                                                        </div>

                                                        <div>
                                                            <IconList width={30} height={30}>
                                                                <AiOutlineQrcode size={15} />
                                                            </IconList>
                                                            <p>Escolha a opção <span>pagar com QR CODE</span> e escaneie o código ao lado</p>
                                                        </div>

                                                        <div>
                                                            <IconList width={30} height={30}>
                                                                <AiOutlineCheck size={15} />
                                                            </IconList>
                                                            <p>Confirme as informações e <span>finalize o pagamento</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div>
                                            <p>
                                                Os seus dados pessoais serão utilizados para processar a 
                                                sua compra, apoiar a sua experiência em todo este site e 
                                                para outros fins descritos na nossa política de privacidade.
                                            </p>
                                            <FormControlLabel
                                                value={termsChecked}
                                                label="Li e concordo com o(s) termos e condições deste site."
                                                control={ <Checkbox />}
                                                onChange={handleTermsCheck}
                                            />
                                        </div>
                                    </div>

                                    <div className="content-button-next">
                                        {!showCreditCard && (
                                            <>
                                                <button className="back-button" onClick={() => setShowDatasPersonal(true)}>
                                                </button>     
                                                <button className="next-button" disabled={!termsChecked} type="button" onClick={handleNextStepPix}>

                                                </button>
                                            </>
                                        )}
                                        {showCreditCard && (
                                            <>
                                                <button className="back-button" onClick={() => setShowDatasPersonal(true)}>
                                                </button>
                                                <button className="next-button" disabled={!termsChecked} type="submit">
                                          
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </>
            }
        </Container>
    )
}