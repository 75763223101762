import styled from "styled-components";

export const Container = styled.div`
    margin-left: 2.5rem;

    .content-taxas{
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
    }

    .content-settings > h1{
        font-size: 33px;
        color: ${props => props.theme.color.colorTextPrimary};
    }

    .content-settings .header-settings{
        display: flex;
        margin-bottom: 2rem;
    }

    .content-settings-taxas > span {
        font-weight: bold;
        color: ${props => props.theme.color.colorTextPrimary};
        display: block;
        font-size: 20px;
        margin: 1.5rem 0;
    }

    .content-inputs-tarifa{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
            color: ${props => props.theme.color.colorTextPrimary};
        }

        label {
            color: ${props => props.theme.color.colorTextPrimary};
        }
        input{
            color: ${props => props.theme.color.colorTextPrimary};
        }
    }

    .content-inputs-address{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
            color: ${props => props.theme.color.colorTextPrimary};
        }

        label {
            color: ${props => props.theme.color.colorTextPrimary};
        }
        input{
            color: ${props => props.theme.color.colorTextPrimary};
        }
    }

    .content-price-simulate{
        margin: 1rem 0;
        
        gap: 1rem;

        display: flex;
        align-items: center;

        h1 {
            color: ${props => props.theme.color.colorButtonsSecondary};
            font-weight: bold;
            font-size: 40px;
        }

        span{
            font-size: 20px;
            color: ${props => props.theme.color.colorTextSecondary};
        }
    }

    .content-price-simulate .content-button-calculate{
        button{
            padding: 0.6rem 2rem;
            margin: 1rem 0;
            font-size: 18px;
            border: none;
            border-radius: 5px;
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
        }
    }

    .content-button-next{
        width: 100%;
        display: flex;
        margin: 2rem 0;
        gap: 1rem;

        .btn-next{
            ::after{
                content: '>';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'AVANÇAR';
                }
            }
        }

        .btn-back{
            ::after{
                content: '<';
            }

            &:hover{
                transition: all ease 0.3s;
                ::after{
                    content: 'VOLTAR';
                }
            }
        }

        button{
            background: linear-gradient(90deg, ${props => props.theme.color.colorTextPrimary} 0%, ${props => props.theme.color.colorButtonSecondaryHover} 0%, ${props => props.theme.color.colorButtonsSecondary} 25%);
            padding: 0.3rem 5rem;
            color: ${props => props.theme.color.backgroundPrimary};
            cursor: pointer;
            border: none;
            border-radius: 5px;
            font-size: 20px;
    
            &:hover{
                transition: all ease 0.3s;
                background-color: ${props => props.theme.color.colorButtonSecondaryHover};
            }
        }

        button:nth-child(1){
            background: none;
            color: ${props => props.theme.color.colorButtonsSecondary};
            border: 1px solid ${props => props.theme.color.colorButtonsSecondary};
        }
    }

    @media(max-width: 912px){
        margin-left: 0;

        .content-button-next{
            justify-content: center;
        }

        .content-price-simulate{
            justify-content: center;
        }
    }

    @media(max-width: 768px){
        .content-settings{
            text-align: center;
        }
    }

    @media(max-width: 475px){
        .content-inputs-tarifa{
            > div{
                width: 100%;
                margin: 0 1rem;
            }
        }

        .content-inputs-address{
            > div{
                display: flex;
                justify-content: center;
                width: 100%;
                margin:  0 1rem;

                > div{
                    width: 100%;
                }
            }
        }

        .content-button-next{
            justify-content: center;
            flex-direction: column-reverse;
        }

        .content-price-simulate{
            justify-content: center;
            flex-direction: column;
        }
    }
`