import swal from "sweetalert";
import { debounce } from "lodash";
import { TextField } from "@mui/material";

import { Container } from "./styles";
import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import ThemeConfigApp from "../../../../../components/ThemeConfigApp";
import SimulatorApp from "../../../../../components/SimulatorApp";
import LoginDriver from "../../../../../components/AppsRendered/Driver/Login";
import LoginCredencies from "../../../../../components/AppsRendered/Driver/LoginCredencies";
import RegisterDriver from "../../../../../components/AppsRendered/Driver/Register";

import {
  UseFormsMobilityPro,
  FormActions,
} from "../../../../../contexts/FormsMobilityPro";
import { useListConfigAppContext } from "../../../../../contexts/ListConfigApp";

export default function FormColorDriver() {
  const { state, dispatch } = UseFormsMobilityPro();

  const { listConfigChecked, setListConfigChecked } = useListConfigAppContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const [colorPrimaryDriver, setColorPrimaryDriver] =
    useState<string>("#A71814");
  const [colorSecondaryDriver, setColorSecondaryDriver] =
    useState<string>("#0A2B4E");
  const [colorTextDriver, setColorTextDriver] = useState<string>("#000000");

  const [stepDriver, setStepDriver] = useState<
    "login" | "loginCredencies" | "register"
  >("login");
  const [stepRenderedDriver, setStepRenderedDriver] = useState<
    React.ReactNode | undefined
  >(undefined);

  const handleChangeColorsInputs = debounce(
    (typeInput: "primary" | "secondary" | "text", color: string) => {
      if (typeInput === "primary") {
        setColorPrimaryDriver(color);
      }

      if (typeInput === "secondary") {
        setColorSecondaryDriver(color);
      }

      if (typeInput === "text") {
        setColorTextDriver(color);
      }
    },
    500
  );

  const handleCheckColorsSimilars = (
    colorPrimary: string,
    colorSecondary: string
  ) => {
    const colorPrimaryFormated = colorPrimary.substring(1, 7).toUpperCase();
    const colorSecondaryFormated = colorSecondary.substring(1, 7).toUpperCase();

    // get red/green/blue int values of hex1
    let r1 = parseInt(colorPrimaryFormated.substring(0, 2), 16);
    let g1 = parseInt(colorPrimaryFormated.substring(2, 4), 16);
    let b1 = parseInt(colorPrimaryFormated.substring(4, 6), 16);
    // get red/green/blue int values of hex2
    let r2 = parseInt(colorSecondaryFormated.substring(0, 2), 16);
    let g2 = parseInt(colorSecondaryFormated.substring(2, 4), 16);
    let b2 = parseInt(colorSecondaryFormated.substring(4, 6), 16);
    // calculate differences between reds, greens and blues
    let r = 255 - Math.abs(r1 - r2);
    let g = 255 - Math.abs(g1 - g2);
    let b = 255 - Math.abs(b1 - b2);
    // limit differences between 0 and 1
    r /= 255;
    g /= 255;
    b /= 255;
    // 0 means opposite colors, 1 means same colors
    const result = (r + g + b) / 3;

    return result.toFixed(2);
  };

  const handleBackStep = () => {
    navigate(`/mobilitypro/informacoes/${id}`);
  };

  const handleNextStep = () => {
    if (state.driver_app === "") {
      swal({
        title: "Escolha o nome do seu aplicativo",
        icon: "warning",
      });

      return;
    }

    /* DRIVER CHECK COLORS */
    const isColorSimilarPrimaryAndSecondaryDriver = handleCheckColorsSimilars(
      colorPrimaryDriver,
      colorSecondaryDriver
    );

    if (Number(isColorSimilarPrimaryAndSecondaryDriver) >= 0.87) {
      swal({
        title: "Cor primária não pode ser muito semelhante a cor secundária!",
        icon: "warning",
      });

      return;
    }

    const isColorSimilarPrimaryAndTextDriver = handleCheckColorsSimilars(
      colorPrimaryDriver,
      colorTextDriver
    );
    if (Number(isColorSimilarPrimaryAndTextDriver) >= 0.87) {
      swal({
        title: "Cor primária não pode ser muito semelhante a cor de texto!",
        icon: "warning",
      });

      return;
    }

    if (
      Number(isColorSimilarPrimaryAndSecondaryDriver) >= 0.87 &&
      Number(isColorSimilarPrimaryAndTextDriver) >= 0.87
    ) {
      swal({
        title: "Cores não podem ser muito semelhantes!",
        icon: "warning",
      });

      return;
    }

    dispatch({
      type: FormActions.setDriverAppMainColor,
      payload: colorPrimaryDriver,
    });

    dispatch({
      type: FormActions.setDriverAppSecondaryColor,
      payload: colorSecondaryDriver,
    });

    dispatch({
      type: FormActions.setDriverAppTextColor,
      payload: colorTextDriver,
    });

    const cloneState = state;
    cloneState.driver_app_main_color = colorPrimaryDriver;
    cloneState.driver_app_secondary_color = colorSecondaryDriver;
    cloneState.driver_app_text_color = colorTextDriver;

    localStorage.setItem("forms_datas_mobilitypro", JSON.stringify(cloneState));

    setListConfigChecked([...listConfigChecked, "color-app-driver"]);
    navigate(`../cores-aplicativo-passageiro/${id}`);
  };

  useEffect(() => {
    if (stepDriver === "login") {
      setStepRenderedDriver(
        <LoginDriver
          setStepApplication={setStepDriver}
          textColor={colorTextDriver}
          primaryColor={colorPrimaryDriver}
          secondaryColor={colorSecondaryDriver}
        />
      );
    } else if (stepDriver === "loginCredencies") {
      setStepRenderedDriver(
        <LoginCredencies
          setStepApplication={setStepDriver}
          textColor={colorTextDriver}
          primaryColor={colorPrimaryDriver}
          secondaryColor={colorSecondaryDriver}
        />
      );
    } else if (stepDriver === "register") {
      setStepRenderedDriver(
        <RegisterDriver
          setStepApplication={setStepDriver}
          textColor={colorTextDriver}
          primaryColor={colorPrimaryDriver}
          secondaryColor={colorSecondaryDriver}
        />
      );
    }
  }, [stepDriver, colorTextDriver, colorSecondaryDriver, colorPrimaryDriver]);

  useEffect(() => {
    if (state.driver_app_main_color) {
      setColorPrimaryDriver(state.driver_app_main_color);
    }

    if (state.driver_app_secondary_color) {
      setColorSecondaryDriver(state.driver_app_secondary_color);
    }

    if (state.driver_app_text_color) {
      setColorTextDriver(state.driver_app_text_color);
    }
  }, [
    state.name,
    state.driver_app_main_color,
    state.driver_app_secondary_color,
    state.driver_app_text_color,
    state.driver_app,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (!state.name) {
      navigate(`../informacoes/${id}`);
      return;
    }

    dispatch({
      type: FormActions.setDriverApp,
      payload: `${
        JSON.parse(localStorage.getItem("forms_datas_mobilitypro") as string)
          .name
      } - Motorista`,
    });

    if (!listConfigChecked.includes("color-app-driver")) {
      setListConfigChecked([...listConfigChecked, "color-app-driver"]);
    }
  }, [
    id,
    state.name,
    state.driver_app,
    listConfigChecked,
    setListConfigChecked,
    navigate,
    dispatch,
  ]);

  return (
    <ThemeConfigApp
      moduleTheme="mobilitypro"
      isCenter="center"
      currentList="color-app-driver"
    >
      <Container>
        <div className="content-colors">
          <h1>Escolha as cores do seu aplicativo para motorista:</h1>

          <div className="content-input-colors">
            <div>
              <label htmlFor="primary-color-driver">Cor primária</label>
              <input
                id="primary-color-driver"
                type="color"
                value={colorPrimaryDriver}
                onChange={(event) => {
                  handleChangeColorsInputs("primary", event.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="secondary-color-driver">Cor secundária</label>
              <input
                id="secondary-color-driver"
                type="color"
                defaultValue="#0A2B4E"
                value={colorSecondaryDriver}
                onChange={(event) => {
                  handleChangeColorsInputs("secondary", event.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="color-text-driver"> Cor texto </label>
              <input
                id="color-text-driver"
                type="color"
                value={colorTextDriver}
                onChange={(event) => {
                  handleChangeColorsInputs("text", event.target.value);
                }}
              />
            </div>
          </div>
          <div>
            <TextField
              sx={{ marginBottom: 3, marginTop: 3 }}
              label="Nome de aplicativo"
              fullWidth
              value={state.driver_app}
              onChange={(event) => {
                dispatch({
                  type: FormActions.setDriverApp,
                  payload: event.target.value,
                });
              }}
            />
          </div>
          <div className="content-button-next">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
          </div>
        </div>
        <div>
          <div className="content-app">
            <SimulatorApp>{stepRenderedDriver}</SimulatorApp>
          </div>

          <div className="content-button-next-mobile">
            <button className="btn-back" onClick={handleBackStep}></button>
            <button className="btn-next" onClick={handleNextStep}></button>
          </div>
        </div>
      </Container>
    </ThemeConfigApp>
  );
}
