import { Popover, Tooltip, IconButton } from '@mui/material';

import { Container } from "./styles";
import { ReactElement, useState } from 'react';

interface IconToltipsProps{
    content?: any;
    icon: ReactElement;
    typeHelp: 'popover' | 'tooltip';
    urlVideo?: string;
}

export default function IconHelp({ icon, content, typeHelp, urlVideo }: IconToltipsProps){

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;  

    return(
        <Container>
            {typeHelp === 'tooltip' && (
                <Tooltip title={content}>
                    {icon}
                </Tooltip>
            )}
            {typeHelp === 'popover' && (
                <>
                    <IconButton 
                        onClick={handleClick}
                    >
                        {icon}
                    </IconButton>                
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                    >
                        <iframe width={350} height={200} title="video-apresentation" src={urlVideo}></iframe>
                    </Popover>
                </>
            )}
        </Container>
    )
}